const INITIAL_STATE = {
    defaultCoin: "LTC",
  };
  
  const setCoinReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "SET_DEFAULT_COIN":
        return {
          ...state,
          defaultCoin: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  export default setCoinReducer;
  