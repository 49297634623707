import React from "react";
import "./privacy.css";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import SEO from "../../components/SEO/seo";

const Privacy = () => {
  return (
    <>
      <SEO
        title="Privacy Policy | Lily Africa"
        description="Explore our Privacy Policy to understand how we safeguard your information."
      />
      <div className="privacy-container">
        <div className="privacy-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>privacy policy</Breadcrumb.Item>
          </Breadcrumb>

          <div className="msg-bar-left">privacy policy</div>

          <p className="privacy-title">Lily Finance Limited. Privacy Notice</p>

          <p className="privacy-text">
            Lily Finance Limited. (also referred to as “Lily Finance,” “we,”
            “us,” or “our”) takes steps to protect your privacy. In this Privacy
            Notice (“Notice”), we describe the types of personal information we
            may collect from you in connection with your use of our websites
            including, but not limited to, https://Lily.Africa, the Lily Finance
            Wallet, our online bitcoin trading platform, mobile application,
            social media pages, or other online properties (collectively, the
            “Website”), or when you use any of the products, services, content,
            features, technologies, or functions we offer (collectively, the
            “Services”). This Notice is designed to help you obtain information
            about our privacy practices and to help you understand your privacy
            choices when you use our Website and Services. Please note that our
            Service offerings may vary by region. For all purposes, the English
            language version of this privacy notice shall be the original,
            governing instrument. In the event of any conflict between the
            English language version of this privacy notice and any subsequent
            translation into any other language, the English language version
            shall govern and control.
          </p>

          <p className="privacy-sub-title">Personal information we collect</p>

          <p className="privacy-text">
            We collect information that relates to you (“Personal Data”) in
            connection with your use of the Website, our Services. or otherwise
            in the context of our relationship with you. The types of Personal
            Data that we may obtain from you may include:
          </p>

          <p className="privacy-sub-title">Biographical Data, including:</p>

          <ul>
            <li className="privacy-list">Name</li>
            <li className="privacy-list">Email Address</li>
            <li className="privacy-list">Phone Number</li>
            <li className="privacy-list">Country</li>
            <li className="privacy-list">Full Address</li>
            <li className="privacy-list">Date of Birth</li>
          </ul>

          <p className="privacy-sub-title">
            Lily Finance Account Details, including:
          </p>

          <ul>
            <li className="privacy-list">Username</li>
            <li className="privacy-list">
              User Profile Information in the “Bio” section
            </li>
            <li className="privacy-list">Profile Picture</li>
            <li className="privacy-list">Joined Date</li>
            <li className="privacy-list">Default Currency</li>
            <li className="privacy-list">Time Zone</li>
            <li className="privacy-list">Default Language</li>
          </ul>

          <p className="privacy-sub-title">
            Lily Finance Account Activity, including:
          </p>

          <ul>
            <li className="privacy-list">
              Trade Chat Messages (which may contain financial information if
              you provide it to sellers)
            </li>
            <li className="privacy-list">
              User Profile Information in the “Bio” section
            </li>
            <li className="privacy-list">Trade Chat Attachments</li>
            <li className="privacy-list">Trade Activity</li>
            <li className="privacy-list">Transaction History</li>
            <li className="privacy-list">Affiliate Name</li>
            <li className="privacy-list">Affiliate ID</li>
            <li className="privacy-list">Affiliate Link</li>
            <li className="privacy-list">Affiliate Transactions</li>
            <li className="privacy-list">Offers Created</li>
            <li className="privacy-list">Offer Terms</li>
            <li className="privacy-list">Trade Instructions</li>
            <li className="privacy-list">Account Notifications</li>
            <li className="privacy-list">Account Status</li>
          </ul>

          <p className="privacy-sub-title">
            Data relating to your Digital asset wallet, including:
          </p>

          <ul>
            <li className="privacy-list">Private Keys</li>
            <li className="privacy-list">Public Keys</li>
            <li className="privacy-list">Wallet Balance</li>
            <li className="privacy-list">Transactions received</li>
            <li className="privacy-list">Transactions sent</li>
          </ul>

          <p className="privacy-sub-title">
            Data Collected in connection with “Know Your Customer” (KYC)
            Compliance, including:
          </p>

          <ul>
            <li className="privacy-list">Government-issued ID</li>
            <li className="privacy-list">Proof of Address</li>
            <li className="privacy-list">
              Photographs, if you elect to provide them to us
            </li>
            <li className="privacy-list">
              Video, if you elect to provide them to us
            </li>
          </ul>

          <p className="privacy-sub-title">
            Device and Website Usage Data, including:
          </p>

          <ul>
            <li className="privacy-list">IP Addresses</li>
            <li className="privacy-list">
              Cookie ID and/or other device identifiers
            </li>
            <li className="privacy-list">
              • Information relating to your access to the Website, such as
              device characteristics, date & time
            </li>
            <li className="privacy-list">Language preferences</li>

            <li className="privacy-list">
              Information on actions taken while using the Website
            </li>
          </ul>

          <p className="privacy-sub-title">
            Mobile application usage data, including:
          </p>

          <ul>
            <li className="privacy-list">
              Session data: IP address, version of the operating system, brand
              and model of the device, unique identifiers of the device, browser
              used, information about the time the Application was accessed,
              name and parameters of the network connection.
            </li>
            <li className="privacy-list">
              Information about applications installed on the User’s device
              (metadata from applications): application name, application
              identifier and version, device identifier and checksum. Detecting
              malicious apps and protecting users from fraud are the main
              reasons for collecting information about installed apps.
            </li>
            <li className="privacy-list">
              Information on actions taken while using the mobile application
            </li>
            <li className="privacy-list">
              Crash and application errors diagnostics data
            </li>
          </ul>

          <p className="privacy-sub-title">How we use your data</p>

          <p className="privacy-text">
            The business purposes for which we collect, use, retain, and share
            your Personal Data may include:
          </p>

          <ul>
            <li className="privacy-list">
              To provide Services through operating the Website, including to:
              <ul>
                <li className="privacy-list">
                  Register, create, and maintain your account;
                </li>

                <li className="privacy-list">
                  Authenticate your identity and/or your access to an account,
                  or help sellers verify your identity;
                </li>
                <li className="privacy-list">
                  Initiate, facilitate, process, and/or execute transactions;
                </li>
                <li className="privacy-list">
                  Communicate with you regarding your account or any Services
                  you use;
                </li>
                <li className="privacy-list">
                  Perform creditworthiness, KYC, or other similar reviews;
                </li>
                <li className="privacy-list">Evaluate applications; or</li>
                <li className="privacy-list">
                  Compare information for accuracy and verification purposes.
                </li>
              </ul>
            </li>

            <li className="privacy-list">
              To manage risk and protect you, other persons, and the Website and
              Services.
            </li>
            <li className="privacy-list">
              To provide a personalized experience and implement your
              preferences.
            </li>
            <li className="privacy-list">
              To better understand customers and how they use and interact with
              the Website and Services.
            </li>
            <li className="privacy-list">To market to you.</li>
            <li className="privacy-list">
              To provide personalized Services, offers, and promotions on our
              Website and third-party websites.
            </li>
            <li className="privacy-list">
              To provide you with location-specific options, functionalities,
              and offers.
            </li>
            <li className="privacy-list">
              To comply with our policies and obligations, including, but not
              limited to, disclosures and responses in response to any requests
              from law enforcement authorities and/or regulators in accordance
              with any applicable law, rule, regulation, judicial or
              governmental order, regulatory authority of competent
              jurisdiction, discovery request or similar legal process.
            </li>
            <li className="privacy-list">
              To resolve disputes, collect fees, or troubleshoot problems.
            </li>
            <li className="privacy-list">
              To provide customer service to you or otherwise communicate with
              you.
            </li>

            <li className="privacy-list">To manage our business.</li>

            <li className="privacy-list">
              We may also process Personal Data for other purposes based upon
              your consent when required by applicable law.
            </li>
          </ul>

          <p className="privacy-sub-title">
            Sources from which we collect personal data
          </p>

          <p className="privacy-text">
            We collect Personal Data from a number of sources, including
          </p>

          <ul>
            <li className="privacy-list">
              <span className="privacy-list-bold">Directly from you:</span> We
              collect Personal Data directly from you when you use our Website
              or Services, communicate with us, or interact directly with us.
            </li>
            <li className="privacy-list">
              <span className="privacy-list-bold">
                From service providers and/or data processors who assist us in
                providing the Website or the Services: 
              </span>{" "}
              We may engage service providers to assist us in facilitating the
              Website or the Services to you, at our direction and on our
              behalf. These service providers may collect information about you
              and provide it to us.
            </li>

            <li className="privacy-list">
              <span className="privacy-list-bold">
                From other users on the Lily Finance Website or from affiliates
                integrated with the Lily Finance Website or Services:
              </span>{" "}
              Other users may provide us with information about you in
              connection with transactions or chats. Affiliates may also provide
              information to us about you related to your interactions or
              transactions with such affiliates.
            </li>

            <li className="privacy-list">
              From third-parties who may help us verify identity, prevent fraud,
              and protect the security of transactions.
            </li>
            <li className="privacy-list">
              From third-parties who may help us evaluate your creditworthiness
              or financial standing.
            </li>
            <li className="privacy-list">
              From third-parties who may help us analyze Personal Data, improve
              the Website or the Services or your experience on it, market
              products or services, or provide promotions and offers to you.
            </li>
            <li className="privacy-list">
              From social media platforms, if you interact with us through
              social media.
            </li>
          </ul>

          <p className="privacy-sub-title">How we share data</p>

          <p className="privacy-text">
            Under certain circumstances, we may disclose certain Personal Data
            with other persons. The categories of persons with whom we may share
            Personal Data include:
          </p>

          <ul>
            <li className="privacy-list">
              <span className="privacy-list-bold">
                Service providers and/or data processors:
              </span>{" "}
              We We may share Personal Data with third-party service providers
              that perform services and functions at our direction and on our
              behalf. These third-party service providers may, for example,
              provide you with Services, verify your identity, assist in
              processing transactions, send you advertisements for our products
              and Services, or provide customer support.
            </li>
            <li className="privacy-list">
              <span className="privacy-list-bold">
                Other parties to transactions, such as sellers:
              </span>{" "}
              We may share information with the other participants to your
              transactions, including other users from whom you are purchasing
              the digital asset.
            </li>

            <li className="privacy-list">
              Financial institutions and other companies involved in helping you
              make payments in connection with transactions
            </li>
            <li className="privacy-list">
              Affiliates that receive referrals from our Website
            </li>
            <li className="privacy-list">
              Other third-parties for our business purposes or as permitted or
              required by law, including:
              <ul>
                <li className="privacy-list">
                  To comply with any legal, regulatory or contractual
                  obligation, or with any legal or regulatory process (such as a
                  valid court order or subpoena);
                </li>

                <li className="privacy-list">
                  To establish, exercise, or defend legal claims;
                </li>
                <li className="privacy-list">
                  In response to a request by a government agency, such as law
                  enforcement authorities or a judicial order;
                </li>
                <li className="privacy-list">
                  To enforce our Website Terms of Service or our internal
                  policies;
                </li>

                <li className="privacy-list">
                  To prevent physical harm or financial loss, in connection with
                  an investigation of suspected or actual illegal activity, or
                  to otherwise protect our or others’ rights, property, or
                  safety;
                </li>

                <li className="privacy-list">
                  To facilitate a purchase or sale of all or part of Lily
                  Finance’s business. For example, by sharing data with a
                  company we plan to merge with or be acquired by; or
                </li>

                <li className="privacy-list">
                  To support our audit, compliance, and corporate governance
                  functions.
                </li>
              </ul>
            </li>
          </ul>

          <p className="privacy-sub-title">International transfers of data</p>

          <p className="privacy-text">
            Please note that we may transfer Personal Data we collect about you
            to countries other than the country in which the information was
            originally collected. Those countries may not have the same data
            protection laws as the country in which you initially provided the
            information. When we transfer your Personal Data to other countries,
            we take steps designed to ensure that the transfer is in accordance
            with applicable law.
          </p>

          <p className="privacy-text">Cookies and online advertising</p>

          <ul>
            <li className="privacy-list">
              A cookie is a small text file that a website saves on your
              computer or mobile device when you visit the website.
            </li>
            <li className="privacy-list">
              Our Website uses cookies and tracking technologies to operate, and
              to target advertising that may be of interest to you. For further
              information, please refer to our Cookie Policy.
            </li>
            <li className="privacy-list">
              Lily Finance may partner with third-party ad networks to either
              display advertising on the Lily Finance Website or on third-party
              websites. These websites and third-party ad networks are not
              controlled by Lily Finance. Ad network partners use data
              technologies to collect information about your online activities
              to provide you targeted advertising based upon your interests. If
              you wish not to have this information used for the purpose of
              serving you targeted ads, you may be able to opt-out by visiting:
              <ul>
                <li className="privacy-list">
                  <a href="http://optout.aboutads.info/">
                    http://optout.aboutads.info/
                  </a>
                </li>

                <li className="privacy-list">
                  <a href="http://optout.networkadvertising.org/">
                    http://optout.networkadvertising.org/
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <p className="privacy-text">
            Please note this does not opt you out from being served advertising;
            you will continue to receive generic ads that are not based on your
            specific interests. You can control the use of cookies at the
            individual browser level. If you reject cookies, you may still use
            our Website, but your ability to use some features or areas of our
            Website may be limited.
          </p>

          <p className="privacy-sub-title">Data retention</p>

          <p className="privacy-text">
            We retain Personal Data for the period necessary for the purposes
            for which it was collected, or for such periods as required by
            applicable law. This may involve retaining Personal Data for periods
            following a transaction. We make efforts to delete your Personal
            Data once it is no longer required for any of the business purposes
            described above.
          </p>

          <p className="privacy-sub-title">Data security</p>

          <p className="privacy-text">
            Lily Finance has implemented safeguards designed to protect your
            Personal Data, including measures designed to prevent Personal Data
            against loss, misuse, and unauthorized access and disclosure. Still,
            Lily Finance cannot ensure or warrant the security or
            confidentiality of information you transmit to us or receive from us
            by Internet or wireless connection. Transferring data through the
            Internet always carries some risk, even if Lily Finance makes
            efforts to protect data once it has received it.
          </p>

          <p className="privacy-sub-title">Children under 18 years of age</p>

          <p className="privacy-text">
            Lily Finance’s Website is not intended for children under the age of
            18. We do not knowingly collect data from children under the age of
            18 without verified parental consent. If we learn that we have
            collected information, including Personal Data, from an individual
            under 18 years of age without parental consent, we will delete that
            information immediately.
          </p>

          <p className="privacy-sub-title">
            Modifications to the privacy notice
          </p>

          <p className="privacy-text">
            Lily Finance reserves the right to change this Notice from time to
            time. We will notify you of modifications to this Notice by posting
            a revised version of this Notice here, by email, or by means of a
            prominent notice on the Lily Finance Website home page. We recommend
            that you periodically check the Website for any changes.
          </p>

          <p className="privacy-sub-title">Contact us</p>

          <p className="privacy-text">
            If you have any questions about this Notice, or wish to make an
            inquiry with us regarding Personal Data or privacy, please contact
            us at:{" "}
            <span>
              {" "}
              <a href="mailto:privacy@Lily.Africa">privacy@Lily.Africa</a>{" "}
            </span>
          </p>

          <p className="privacy-sub-title">EEA Addendum</p>

          <p className="privacy-text">
            The following disclosures apply to, and are intended exclusively
            for, individuals who reside within the European Economic Area (EEA).
          </p>

          <p className="privacy-sub-title">Data Controller</p>

          <p className="privacy-text">
            The controller for your Personal Data is Lily Finance Limited.
          </p>

          <p className="privacy-sub-title">
            Legal Bases for Processing Personal Data
          </p>

          <ul>
            <li className="privacy-list">
              To the extent we use Personal Data to perform contractual
              obligations or requests made by you in connection with a contract,
              Article 6(1)(b) of the General Data Protection Regulation (“GDPR”)
              is the legal basis for our data processing.
            </li>
            <li className="privacy-list">
              To the extent we use Personal Data to comply with a legal
              obligation under EU or Member State law, Article 6(1)(c) of the
              GDPR is the legal basis for our data processing.
            </li>
            <li className="privacy-list">
              To the extent we use Personal Data to protect the vital interests
              of individuals, Article 6(1)(d) of the GDPR is the legal basis for
              our data processing.
            </li>
            <li className="privacy-list">
              To the extent we use Personal Data in pursuit of our legitimate
              business interests, Article 6(1)(f) of the GDPR is the legal basis
              for our data processing. A list of our legitimate business
              interests is in the above section titled “How We Use Your Data”.
            </li>
          </ul>

          <p className="privacy-sub-title">European Data Protection Rights</p>
          <p className="privacy-text">
            European law provides you with certain rights with respect to your
            Personal Data, including:European law provides you with certain
            rights with respect to your Personal Data, including:
          </p>

          <ul>
            <li className="privacy-list">
              The right to request access to and rectification of your Personal
              Data.
            </li>
            <li className="privacy-list">
              The right to request that Lily Finance delete certain Personal
              Data relating to you.
            </li>
            <li className="privacy-list">
              The right to data portability, which includes the right to request
              that certain Personal Data you have provided to us be transferred
              from us to another data controller.
            </li>
            <li className="privacy-list">
              The right to withdraw any consent you have provided to Lily
              Finance to collect, use, or share your data at any time. Please
              note that withdrawing consent does not affect the lawfulness of
              Lily Finance processing your Personal Data before your withdrawal.
            </li>

            <li className="privacy-list">
              The right to object to Lily Finance’s processing of your Personal
              Data, based on grounds specific to your particular situation.
            </li>

            <li className="privacy-list">
              he right to request that Lily Finance restrict the processing of
              your Personal Data, if certain statutory conditions for
              restriction are met.
            </li>

            <li className="privacy-list">
              The right to lodge a complaint with a European supervisory
              authority.
            </li>
          </ul>

          <p className="privacy-text">
            Please note that applicable law may provide exceptions to any of
            these rights, permit Lily Finance to decline your request, or permit
            Lily Finance to extend the period in which it can act on your
            request. Lily Finance may also contact you to verify your identity,
            as permitted by law, prior to acting on your request. To exercise
            any of these rights, please contact us as set forth in the above
            section titled “Contact Us”.
          </p>

          <p className="privacy-sub-title">International Transfers</p>

          <p className="privacy-text">
            We may transfer Personal Data relating to EEA residents to countries
            that have not been found by the European Commission to provide
            adequate protection, including the United States. For any such
            transfers, Lily Finance implements safeguards designed to ensure
            that your Personal Data receives an adequate level of protection. If
            you are located in the EEA, Lily Finance will only transfer your
            Personal Data if: the country to which the Personal Data will be
            transferred has been granted a European Commission adequacy
            decision; the recipient of the Personal Data is located in the
            United States and has certified to the US-EU Privacy Shield
            Framework; Lily Finance has put in place appropriate safeguards in
            respect of the transfer, for example by entering into EU Standard
            Contractual Clauses with the recipient, or; an applicable statutory
            exception to the GDPR general transfer prohibition applies. To
            obtain a copy of the mechanisms that Lily Finance has executed to
            support its transfers of personal data outside the EEA, contact us
            as set forth in the above “Contact Us” section.
          </p>

          <p className="privacy-sub-title">California Addendum</p>

          <p className="privacy-text">
            The following disclosures apply to, and are intended exclusively
            for, residents of the State of California.
          </p>

          <p className="privacy-sub-title">Your California Privacy Rights</p>

          <p className="privacy-text">
            To the extent that we disclose certain personally identifiable
            information about you to third parties who use it for their direct
            marketing purposes, you have a right to request further information
            about the recipients of your information. To exercise this right,
            please contact us as described in the above section titled “Contact
            Us.”
          </p>

          <p className="privacy-sub-title">Do Not Track Disclosure</p>

          <p className="privacy-text">
            Our Website is not designed to respond to “Do Not Track” signals or
            requests.
          </p>

          <p className="privacy-text">
            “LILY FINANCE” is a registered trademark of Lily Finance Limited.
            Copyright © 2024 Lily Finance Limited. All Rights Reserved. Lily
            Finance Inc. has no relation to MoneyGram, Western Union, Payoneer,
            WorldRemit, Paxum, PayPal, Amazon, OkPay, Payza, Walmart, Reloadit,
            Perfect Money, WebMoney, Google Wallet, BlueBird, Serve, Square
            Cash, NetSpend, Chase QuickPay, Skrill, Vanilla, MyVanilla,
            OneVanilla, Neteller, Venmo, Apple, ChimpChange or any other payment
            method. We make no claims about being supported by or supporting
            these services. Their respective wordmarks and trademarks belong to
            them alone. Official mailing address: info@lily.africa
          </p>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Privacy;
