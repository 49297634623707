import React, { useState, useEffect } from "react";
import { Form, Button, message, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setUserData } from "../../redux/user/user.actions";
import CountDown from "../../components/Countdown/countdown";
// import { setUserData } from './../../redux/user/user.actions';

import "./style-Auth.scss";
import { instance, bearerInstanceWithToken } from "./../../utils/API";

export default function VerifyPhone({ props }) {
  const location = useLocation().state;
  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmOtp, setConfirmOtp] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [requestPin, setRequestPin] = useState("");
  const [showCountDown, setShowCountDown] = useState(false);
  // useEffect(() => {
  //   if (!registerInfo?.userInfo?.email) {
  //     history.push('register');
  //   }
  //   //eslint-disable-next-line
  // }, []);

  const userState = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (location?.hideData === true) {
      return false;
    } else if (!userState?.userData) {
      history.push("/register");
    } else if (
      userState?.userData?.is_phone_no_verification_skipped === "1" ||
      userState?.userData?.is_phone_no_verified === "1"
    ) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  const [input, setInput] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [walletButtonLoading, setWalletButtonLoading] = useState(false);
  const registerInfo = useSelector((state) => state.register);

  const handleInputChange = (value) => {
    setInput(value);
  };


  const setupWallet = () => {
    setWalletButtonLoading(true);

    const data = new FormData();
    data.append("user_name", userState?.userData?.user_name);
    data.append("wallet_setup", 1);

    bearerInstanceWithToken(userState?.userData?.token)
      .post("/register", data)
      .then(function (response) {
        setWalletButtonLoading(false);
        history.push("/");
      })
      .catch(function (error) {
        // console.log(error);
        setWalletButtonLoading(false);
      });
  };

  const onFinishSendOtp = (checkCountDown) => {
    setButtonLoading(true);

    const data = new FormData();
    // data.append(
    //   "user_name",
    //   registerInfo?.userInfo?.user_name || userState?.userData?.user_name
    // );
    // data.append("user_phone", `+${input}`);
    // data.append("verification_code", "");
    // data.append('is_phone_no_verified', 1);
    data.append("send_phone_verification_code", 1);
    data.append("user_phone", `+${input}`);

    bearerInstanceWithToken(userState?.userData?.token)
      .post(`/register`, data)
      .then(function (response) {
        if (response?.data?.status) {
          setButtonLoading(false);
          message.success(response?.data?.message);

          // const userData = {
          //   ...userState.userData,
          //   is_phone_no_verified: '1',
          // };
          // console.log(userData);
          // dispatch(setUserData(userData));
          // history.push("/");
          setShowCountDown(true);
          setConfirmOtp(true);
          setRequestPin(response.data);
        } else {
          message.error(response?.data?.message);
          setButtonLoading(false);
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        }
        setButtonLoading(false);
      });
  };

  const onFinishConfirmOtp = () => {
    if (otpInput.length !== 6) {
      message.error("incomoplete otp");
    } else {
      setButtonLoading(true);

      const data = new FormData();
      data.append(
        "user_name",
        registerInfo?.userInfo?.user_name ||
          userState?.userData?.user_name ||
          location?.user?.user_name
      );
      data.append("request_id", requestPin?.request_id);
      data.append("verification_code", otpInput);
      data.append("user_phone", `+${input}`);
      data.append("verify_phone_verification_code", 1);
      // data.append('is_phone_no_verified', 1);

      bearerInstanceWithToken(userState?.userData?.token)
        .post(`/register`, data)
        .then(function (response) {
          if (response?.data?.status) {
            setButtonLoading(false);
            message.success("phone number verified successfully");

            const userData = {
              ...userState.userData,
              is_phone_no_verified: "1",
              signUpComplete: true,
            };
            // console.log(userData);
            dispatch(setUserData(userData));
            setupWallet();
            // setConfirmOtp(true);
          } else {
            message.error(response?.data?.message);
            setButtonLoading(false);
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message);
          }
          setButtonLoading(false);
        });
    }
  };

  const skipPhoneVerification = () => {
    setButtonLoading(true);

    const data = new FormData();
    data.append("user_name", registerInfo?.userInfo?.user_name);
    data.append("skip_phone_no_verification", 1);

    bearerInstanceWithToken(userState?.userData?.token)
      .post("/register", data)
      .then(function (response) {
        if (response?.data?.status) {
          setButtonLoading(false);
          message.success(response?.data?.message);
          history.push("/");
        } else {
          message.error(response?.data?.message);
          setButtonLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
        setButtonLoading(false);
      });
  };

  const resendOtp = () => {
    setButtonLoading(true);

    const data = new FormData();
    data.append("request_id", requestPin?.request_id);
    data.append("resend_phone_verification_code", 1);

    bearerInstanceWithToken(userState?.userData?.token)
      .post("/register", data)
      .then(function (response) {
        if (response?.data?.status) {
          setButtonLoading(false);
          // message.success(response?.data?.short);
          // onFinishSendOtp(true);
        } else {
          message.error(response?.data?.message);
          setButtonLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
        setButtonLoading(false);
      });
  };

  const handleAfterCountDown = () => {
    setShowCountDown(false);
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="form">
          <div className="title">add your phone number</div>
          <div className="desc">
            add and verify a phone number to complete the setup of your new lily
            account. you must verify the phone number before you continue.
          </div>
          <div className="desc custom">enter phone number:</div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={() => onFinishSendOtp(true)}
          >
            <Form.Item
              style={{ marginBottom: "25px" }}
              name="email"
              rules={[{ required: true, message: "phone number required!" }]}
            >
              <PhoneInput
                country={"ng"}
                onChange={(phone) => {
                  handleInputChange(phone);
                }}
                disabled={confirmOtp}
              />
            </Form.Item>

            {confirmOtp && (
              <>
                <div className="desc custom">enter otp sent to +{input}:</div>
                <Input
                  placeholder=""
                  // ref={input5}
                  type="text"
                  value={otpInput}
                  onChange={(event) => {
                    setOtpInput(event.target.value);
                  }}
                  style={{ width: "50%" }}
                />
              </>
            )}

            {/* <div
              style={{ marginTop: '-10px' }}
              className="referral"
              onClick={() => {
                skipPhoneVerification();
              }}
            >
              skip
            </div> */}

            {confirmOtp ? (
              <div style={{ marginTop: "10px" }} className="referral">
                {showCountDown === false ? (
                  <span
                    onClick={() => {
                      onFinishSendOtp();
                    }}
                  >
                    resend code
                  </span>
                ) : (
                  <CountDown onTimerComplete={handleAfterCountDown} />
                )}
              </div>
            ) : null}

            {!confirmOtp && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={buttonLoading}
                >
                  Continue
                </Button>
              </Form.Item>
            )}

            {confirmOtp && (
              <Button
                type="primary"
                // htmlType="submit"
                className="login-form-button"
                loading={buttonLoading || walletButtonLoading}
                onClick={onFinishConfirmOtp}
              >
               {walletButtonLoading ? "setting up wallet" : "confirm otp"}
              </Button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
