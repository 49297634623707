import React, { useEffect, useState } from "react";
import "./dispute.scss";
import { Select, Breadcrumb, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { DisputeCardItem } from "../../../components/ProfileDiscussionItem/ProfileDiscussionItem";
import { bearerInstance } from "../../../utils/API";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { ReactComponent as EmptyImage } from "../../../assets/empty.svg";

const Disputes = () => {
  const { Option } = Select;

  const [loading, setLoading] = useState(false);
  const [disputes, setDisputes] = useState([]);
  const [disputesFilter, setDisputesFilter] = useState([]);
  const [filterValue, setFilterValue] = useState(null);

  const getAllDisputes = () => {
    setLoading(true);

    bearerInstance
      .get("/fetch_all_disputes")
      .then((res) => {
        // console.log(res.data?.disputes);
        setDisputes(res.data?.disputes);
        // console.log(res.data.discussion_data);
        setDisputesFilter(res.data?.disputes);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllDisputes();
  }, []);

  const onFilterChange = (value) => {
    const filt = [...disputesFilter];

    if (value === "resolved") {
      setFilterValue(value);
      setDisputes(filt.filter((cur) => cur.status === "resolved"));
    } else if (value === "ongoing") {
      setFilterValue(value);
      setDisputes(filt.filter((cur) => cur.status === "progress"));
    } else {
      setFilterValue(null);
      setDisputes(filt);
    }
  };

  // function compareTimestamps(a, b) {
  //   const timeDiffA = Math.abs(a - targetTimestamp);
  //   const timeDiffB = Math.abs(b - targetTimestamp);

  //   return timeDiffA - timeDiffB;
  // }

  const handleSortData = () => {
    let arr = disputes.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA; // Sort in descending order (latest first)
    });

    return arr;
  };

  return (
    <div className="parent">
      <div className="wrapper">
        {loading && <Loader />}

        {!loading && (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">
                  <HomeOutlined />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>disputes</Breadcrumb.Item>
            </Breadcrumb>
            <div className="msg-bar">
              <div className="msg-bar-left title-bar">
                disputes <span className="count">{disputes.length || 0}</span>
              </div>
              <div className="msg-bar-right">
                <div>
                  <Select
                    style={{ flex: "0 0 160px" }}
                    value={filterValue}
                    suffixIcon={<DownOutlined />}
                    placeholder="filter by..."
                    optionFilterProp="children"
                    onChange={onFilterChange}
                  >
                    <Option value="">all</Option>
                    <Option value="resolved">assigned</Option>
                    <Option value="ongoing">unassigned</Option>
                  </Select>
                </div>
              </div>
            </div>

            <>
              {disputes.length === 0 ? (
                <div className="no-result">
                  <div className="svg-container">
                    <EmptyImage />
                  </div>

                  <div className="no-result-text" style={{ fontSize: "16px" }}>
                    no disputes here
                  </div>
                </div>
              ) : (
                <>
                  {disputes &&
                    handleSortData()?.map((item) => {
                      return <DisputeCardItem data={item} fullDetails={item} />;
                    })}
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default Disputes;
