import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({ title, description, name, type, keyword }) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword}></meta>
      {/* End standard metadata tags */}

      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="og:keywords" content={keyword}></meta>
      {/* End Facebook tags */}
      {/* Twitter tags */}

      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:keywords" content={keyword}></meta>
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}

      {/* Google Analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-9J2DCR4S0X"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.REACT_GOOGLE_ANALTYTICS_CODE}');
        `}
      </script>
    </Helmet>
  );
}
