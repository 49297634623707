export const formatTradeSource = (source) => {
  if (
    (source && source?.toLowerCase() === "bank fund") ||
    source?.toLowerCase() === "bank account"
  ) {
    return "bank account";
  } else if (source === "moneygram") {
    return "moneygram";
  } else if (source === "giftcard") {
    return "giftcard";
  } else {
    return source + " " + "wallet";
  }
};
