import React, { useState } from "react";
import { Button, Dropdown, Menu, Avatar, Badge } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LeftOutlined, BellOutlined } from "@ant-design/icons";
import { IoClose } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/logo.png";
import "./Navbar.scss";

export default function Navbar() {
  const [topNav, setTopNav] = useState(true);
  const userState = useSelector((state) => state.user);
  const notificationCount = useSelector(
    (state) => state.user.notificationCount
  );
  // const notificationCount = 3;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="navbar-container">
      {topNav && (
        <>
          {location.pathname === "/" && (
            <div className="nav-top-bar">
              <div className="nav-hide" />
              <Link to="/academy">
                <p className="nav-top-text">
                  new here? <span>understand Lily & learn how to trade</span>{" "}
                  with our learning resources.
                  <IoIosArrowForward className="nav-top-arrow" />
                </p>{" "}
              </Link>

              <IoClose
                color="#fff"
                size={25}
                onClick={() => setTopNav(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
        </>
      )}
      <div className="navbar-wrapper">
        <div className="row-one">
          <div className="left">
            <div
              className="nav"
              onClick={() => {
                history.goBack();
              }}
            >
              {location.pathname === "/admin-dashboard" ? (
                <>
                  <div className="app-name">
                    <Link to="/" className="nav-link">
                      <img src={logo} alt="lili logo" className="logo" />
                      lily.africa
                    </Link>
                    <div className="bottom">convert money effortlessly</div>
                  </div>
                </>
              ) : (
                <div>
                  {location.pathname !== "/" && (
                    <span>
                      <LeftOutlined style={{ marginRight: "0px" }} /> back
                    </span>
                  )}
                </div>
              )}

              {location.pathname === "/" && (
                <div className="app-name">
                  <Link to="/" className="nav-link">
                    <img src={logo} className="logo" alt="lili logo" />{" "}
                    lily.africa
                  </Link>
                  <div className="bottom">convert money effortlessly</div>
                </div>
              )}
            </div>
          </div>

          <div className="right">
            {userState?.userData?.token && location.pathname !== "/admin" ? (
              <>
                <div
                  style={{
                    marginRight: "15px",
                  }}
                  className="notifications name"
                >
                  <Link to="/notifications">
                    {notificationCount ? (
                      <Badge
                        style={{
                          backgroundColor: "#ed1450",
                          transform: `translateX(${40}%) translateY(${-40}%)`,
                          fontSize: 11,
                        }}
                        count={notificationCount}
                        title={
                          notificationCount > 1
                            ? notificationCount + " notifications"
                            : notificationCount + " notification"
                        }
                      >
                        <BellOutlined
                          style={{
                            fontSize: "20px",
                            color: "#ed1450",
                          }}
                        />
                      </Badge>
                    ) : (
                      <BellOutlined
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </Link>
                </div>

                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1" disabled={location.pathname === "/"}>
                        <Link to="/">home</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="3"
                        disabled={location.pathname === "/chat"}
                      >
                        <Link to="/chat">trades</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="8"
                        disabled={location.pathname === "/profile"}
                      >
                        <Link to="/profile">profile</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="2"
                        disabled={location.pathname === "/wallet"}
                      >
                        <Link to="/wallet">wallet</Link>
                      </Menu.Item>

                      <Menu.Item key="4">settings</Menu.Item>

                      <Menu.Item
                        key="6"
                        disabled={location.pathname === "/contact"}
                      >
                        <Link to="/contact">contact</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="7"
                        disabled={location.pathname === "/academy"}
                      >
                        <Link to="/academy">academy</Link>
                      </Menu.Item>

                      <Menu.Divider />
                      <Menu.Item
                        key="5"
                        onClick={() => {
                          dispatch({ type: "DESTROY_SESSION" });
                          localStorage.clear();
                          sessionStorage.clear();
                          setTimeout(() => {
                            window.location.assign("/");
                          }, 500);
                        }}
                        style={{ color: "#ed1450" }}
                      >
                        logout
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <div className="name">
                    {userState?.userData?.user_name}{" "}
                    <Avatar
                      style={{
                        color: "#14a014",
                        backgroundColor: "#a9fca9",
                      }}
                    >
                      {userState?.userData?.user_name?.charAt(0).toUpperCase()}
                    </Avatar>
                  </div>
                </Dropdown>
              </>
            ) : (
              <>
                <Button size="normal" type="link">
                  <Link to="/login">login</Link>
                </Button>
                <Button type="primary" size="normal">
                  <Link to="/register">register</Link>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
