import React from "react";
import "./contact.css";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import SEO from "../../components/SEO/seo";

const Contact = () => {
  const handleOpenLink = (link) => {
    window.open(link, "_blank");
  };

  function openMailApplication(email) {
    window.location.href = `mailto:${email}`;
  }

  return (
    <>
      <SEO
        title="Contact Us | Lily Africa"
        description="We are building a future of people-powered finance. sometimes
        things may break or are not optimal, or you just aren't satisfied.
        regardles, we are only a whisk away, even if you just want to say
        hi."
      />
      <div className="contact-container">
        <div className="contact-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>contact us</Breadcrumb.Item>
          </Breadcrumb>

          <div className="msg-bar-left">contact us</div>

          <div className="contact-content">
            <p className="c-desc">
              we are building a future of people-powered finance. sometimes
              things may break or are not optimal, or you just aren't satisfied.
              regardles, we are only a whisk away, even if you just want to say
              hi.
            </p>

            <div className="contact-list">
              <div className="c-list">
                <h3 className="c-title">email</h3>
                <p
                  className="c-text"
                  onClick={() => openMailApplication("info@lily.africa")}
                >
                  info@lily.africa
                </p>
                <p
                  className="c-text"
                  onClick={() => openMailApplication("care@lily.africa")}
                >
                  care@lily.africa
                </p>
              </div>

              <div className="c-list">
                <h3 className="c-title">join our community</h3>
                <p
                  className="c-text"
                  onClick={() =>
                    handleOpenLink(
                      "https://chat.whatsapp.com/Jwe5yYUfJ4hB9wxxtQbdp6"
                    )
                  }
                >
                  whatsapp
                </p>
              </div>

              <div className="c-list">
                <h3 className="c-title">twitter / X</h3>
                <p
                  className="c-text"
                  onClick={() => handleOpenLink("https://x.com/lilydotafrica")}
                >
                  @lilydotafrica
                </p>
              </div>

              <div className="c-list">
                <h3 className="c-title">instagram</h3>
                <p
                  className="c-text"
                  onClick={() =>
                    handleOpenLink("https://instagram.com/lilydotafrica")
                  }
                >
                  @lilydotafrica
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Contact;
