import React from "react";
import { Alert, Button } from "antd";
import { ExclamationCircleOutlined, MessageOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  formatSourceText,
  formatDenomination,
} from "../../utils/formatSourceText";
import "../Discussion/discussion.scss";
import { removeSubstring } from "../../utils/removeStringFromText";

const Instructons = () => {
  const history = useHistory();

  const location = useLocation();

  const { discussionDetails, checkMerchant, discussionData, merchantDetails } =
    location.state;

  let checkStage =
    discussionDetails?.stage === "1" ? !checkMerchant : checkMerchant;

  let stage = discussionDetails?.stage === "1";

  // function formats numbers
  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <div className="message-wrapper">
      <div className="instructions-box-two">
        <div className="instructions-content-wrap">
          <h2 className="instruction-title">trade instructions</h2>

          <div>
            <Alert
              message="notice"
              description={`${discussionDetails?.in_escrow} ${removeSubstring(discussionDetails?.in_escrow_coin)} is now in the escrow.`}
              type="success"
              style={{
                marginBottom: "20px",
                marginTop: "10px",
              }}
              closable
              showIcon
            />
          </div>

          <>
            {checkStage ? (
              <div>
                <div className="instructions-wrap">
                  <p className="list-text">
                    <b className="bold-text">
                      {`stage ${discussionDetails?.stage}`}
                    </b>
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    {/* {`to buy ${
                      discussionDetails?.in_escrow
                      // stage
                      //   ? `$${currencyFormat(discussionDetails?.source_value)}`
                      //   : `₦${currencyFormat(
                      //       discussionDetails?.destination_value
                      //     )}`
                    } 
                    BTC from ${
                      stage
                        ? discussionData?.merchant_data[0]?.user_name
                        : discussionData?.dealer_data[0]?.user_name
                    } using ${
                      stage
                        ? formatSourceText(discussionDetails?.source)
                        : formatSourceText(discussionDetails?.destination)
                    } as a payment
                  method.`} */}
                    {`you are to send ${
                      stage
                        ? formatDenomination(discussionDetails?.source_currency)
                        : formatDenomination(
                            discussionDetails?.destination_currency
                          )
                    }${
                      stage
                        ? currencyFormat(discussionDetails?.source_value)
                        : currencyFormat(discussionDetails?.destination_value)
                    } to ${
                      stage
                        ? discussionData?.merchant_data[0]?.user_name
                        : discussionData?.dealer_data[0]?.user_name
                    }`}
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p style={{ marginTop: "10px" }} className="list-text">
                    <b>please follow the steps below:</b>
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">{`1. wait for ${
                    stage
                      ? discussionData?.merchant_data[0]?.user_name
                      : discussionData?.dealer_data[0]?.user_name
                  } to provide his ${
                    stage
                      ? formatSourceText(discussionDetails?.source)
                      : formatSourceText(discussionDetails?.destination)
                  } details`}</p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    {`2. send the exact amount of ${
                      stage
                        ? `${formatDenomination(
                            discussionDetails?.source_currency
                          )}${currencyFormat(discussionDetails?.source_value)}`
                        : `${formatDenomination(
                            discussionDetails?.destination_currency
                          )}${currencyFormat(
                            discussionDetails?.destination_value
                          )}`
                    }
                 to his provided ${
                   stage
                     ? formatSourceText(discussionDetails?.source)
                     : formatSourceText(discussionDetails?.destination)
                 }
                  details`}
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    3. once sent, click on "i have paid" button below to confirm
                    you have sent it.
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    4. upload a payment slip if required.
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    {`5. wait for ${
                      stage
                        ? discussionData?.merchant_data[0]?.user_name
                        : discussionData?.dealer_data[0]?.user_name
                    } to confirm that they have received the
                  payment.`}
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="instructions-wrap">
                  <p className="list-text">
                    <b className="bold-text">
                      {`stage ${discussionDetails?.stage}`}
                    </b>
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    {/* {` to sell 
                      ${discussionDetails?.in_escrow}

                 BTC to ${
                   stage
                     ? discussionData?.dealer_data[0]?.user_name
                     : discussionData?.merchant_data[0]?.user_name
                 } using ${formatSourceText(
                      discussionDetails?.source
                    )} as a payment
                  method.`} */}

                    {`you are to receive ${
                      stage
                        ? formatDenomination(discussionDetails?.source_currency)
                        : formatDenomination(
                            discussionDetails?.destination_currency
                          )
                    }${
                      stage
                        ? currencyFormat(discussionDetails?.source_value)
                        : currencyFormat(discussionDetails?.destination_value)
                    } from ${
                      stage
                        ? discussionData?.dealer_data[0]?.user_name
                        : discussionData?.merchant_data[0]?.user_name
                    }`}
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    <b>please follow the steps below:</b>
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">{`1. provide your ${
                    stage
                      ? formatSourceText(discussionDetails?.source)
                      : formatSourceText(discussionDetails?.destination)
                  } details to ${
                    stage
                      ? discussionData?.dealer_data[0]?.user_name
                      : discussionData?.merchant_data[0]?.user_name
                  } and stay active`}</p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">{`2. wait for ${
                    stage
                      ? discussionData?.dealer_data[0]?.user_name
                      : discussionData?.merchant_data[0]?.user_name
                  } to send the ${
                    stage
                      ? formatDenomination(discussionDetails?.source_currency)
                      : formatDenomination(
                          discussionDetails?.destination_currency
                        )
                  }${
                    stage
                      ? currencyFormat(discussionDetails?.source_value)
                      : currencyFormat(discussionDetails?.destination_value)
                  }
               to your ${
                 stage
                   ? formatSourceText(discussionDetails?.source)
                   : formatSourceText(discussionDetails?.destination)
               }`}</p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    3. once you have received the complete amount, click on
                    "seen payment" button below
                  </p>
                </div>

                <div className="instructions-wrap">
                  <p className="list-text">
                    4. only click on "seen payment" when you are sure you have
                    received the full amount. DO NOT CLICK ON IT IF YOU HAVEN'T
                    RECEIVED THE FULL PAYMENT. if you have any issues, you can
                    raise a dispute after 2 hours.
                  </p>
                </div>
              </div>
            )}
          </>
        </div>

        <div className="nav-bottom-btn-box">
          <div className="nav-bottom-btn">
            <div
              style={{ opacity: "1", width: "47%" }}
              onClick={() => history.goBack()}
            >
              <Button
                icon={<MessageOutlined />}
                // color="success"
                style={{
                  fontSize: "14px",
                  backgroundColor: "white",
                  color: "#fb4570",
                  padding: "3px 5px",
                  width: "100%",
                  height: "40px",
                  textAlign: "center",
                }}
              >
                trade chat
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                fontSize: 14,
                opacity: "1",
                width: "46%",
              }}
            >
              <Button
                icon={<ExclamationCircleOutlined />}
                style={{
                  fontSize: "14px",
                  backgroundColor: "#fb4570",
                  color: "white",
                  padding: "3px 5px",
                  width: "100%",
                  height: "40px",
                  textAlign: "center",
                }}
              >
                instructions
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructons;
