import React, { useEffect, useState } from "react";
import "./Home.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip, Breadcrumb, Tag } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { BsCheckCircleFill } from "react-icons/bs";

const AdminHome = () => {
  const urlParams = new URL(window.location.href).searchParams;
  const profileData = useSelector((state) => state.data.profile);
  const history = useHistory();
  let level = urlParams.get("data");
  let adminLevel = JSON.parse(level);

  const navigatePage = () => {
    history.push("/disputes");
  };

  const navigateToMetrics = () => {
    history.push("/admin/metrics");
  };

  const adminLevels = [
    { level: 1, title: "manage mods" },
    { level: 1, title: "pending disputes", action: navigatePage },
    { level: 1, title: "dispute history" },
    { level: 2, title: "view metrics", action: navigateToMetrics },
  ];

  // let level = 1;
  let sortName =
    adminLevel || profileData?.profile_data[0]?.admin_level === 1
      ? "moderator"
      : adminLevel || profileData?.profile_data[0]?.admin_level === 2
      ? "super user"
      : "";

  const sortArray = adminLevels.filter((item, index, arr) => {
    if (adminLevel === 1) {
      return item !== arr[0] && item?.level !== 2;
    } else {
      return item;
    }
  });

  useEffect(() => {
    const handleCheckUser = () => {
      if (profileData?.profile_data[0]?.admin_level === 0) {
        history.push("/");
      }
    };

    handleCheckUser();
  }, []);

  console.log(profileData?.profile_data[0]?.admin_level);

  return (
    <div className="home">
      <div className="home-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>dashboard</Breadcrumb.Item>
        </Breadcrumb>

        <div className="title-box">
          <p className="title-bar">dashboard</p>
          <p className="icon-text">
            level {adminLevel || profileData?.profile_data[0]?.admin_level}
            {adminLevel !== 0 && (
              <Tooltip placement="topRight" title={sortName}>
                <Tag style={{ height: "23px", marginLeft: "8px" }}>
                  {sortName}
                </Tag>
              </Tooltip>
            )}
          </p>
        </div>
        <div className="home-card-box">
          {sortArray.map((item) => {
            return (
              <div className="home-card" onClick={item.action}>
                {item.title}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
