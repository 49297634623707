import React from "react";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch } from "react-redux";

export default function ErrorBoundary() {
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({ type: "DESTROY_SESSION" });
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
    // updateUserStatus(0);
  };

  return (
    <div className="no-result wrong">
      <div className="svg-container">
        <Warning />
      </div>

      <div className="no-result-text">something went wrong</div>
      <div
        className="no-result-text-bottom action"
        onClick={() => {
          window.location.reload();
          logout()
        }}
      >
        click to reload
      </div>
    </div>
  );
}
