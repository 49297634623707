import React, { useEffect, useState } from "react";

const CountDown = ({ onTimerComplete }) => {
  const [secondsLeft, setSecondsLeft] = useState(182);

  useEffect(() => {
    if (secondsLeft > 0) {
      const intervalId = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      onTimerComplete && onTimerComplete();
    }
  }, [secondsLeft, onTimerComplete]);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <div>
      <span style={{ color: "#ed1450" }}>
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    </div>
  );
};

export default CountDown;
