import { Modal, Upload, Alert, Checkbox } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { currencyFormat } from "./formatSourceText";
import { removeSubstring } from "./removeStringFromText";

const { confirm } = Modal;

export function showConfirm(title, content, onOk) {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
    onCancel() {},
  });
}

export const confirmModal = (title, content, ok, checked) => {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    onOk() {
      return ok();
    },
    okButtonProps: {
      disabled: checked,
    },
    onCancel() {},
  });
};

export const successMessage = (msg, onOk, title) => {
  let secondsToGo = 10;

  const modal = Modal.success({
    title: title || "end discussion",
    content: msg,
    onOk: onOk || null,
  });

  setTimeout(() => {
    clearInterval();
    // modal.destroy();
  }, secondsToGo * 1000);
};

export const errorMessage = (error) => {
  let secondsToGo = 10;

  const modal = Modal.error({
    title: "end chat",
    content: `${error}`,
  });

  setTimeout(() => {
    clearInterval();
    // modal.destroy();
  }, secondsToGo * 1000);
};

export const successPaidMessage = (name, onOk) => {
  let secondsToGo = 10;

  const modal = Modal.success({
    title: `payment made to @${name}`,
    content: (
      <>
        <p style={{ marginBottom: "8px" }}>
          you have made the payment to {name}.
        </p>
        <p style={{ marginBottom: "8px" }}>
          let the merchant confirm and release the funds.
        </p>
        <p>
          kindly upload a payment slip which can be a payment confirmation via
          the app or an email notification stating that you have completed the
          transfer.
        </p>
      </>
    ),
    onOk: onOk,
  });

  setTimeout(() => {
    clearInterval();
    // modal.destroy();
  }, secondsToGo * 1000);
};

export const uploadModal = (func, uploadImg, isUploaded) => {
  const modal = Modal.success({
    title: "upload payment receipt",
    content: (
      <div onClick={func}>
        <p>please upload a proof of payment</p>
        <p>image must be less than 2mb</p>
        {isUploaded && (
          <div className="progress">
            <div className="progress-value"></div>
          </div>
        )}
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          disabled={true}
        >
          <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </div>
    ),
    onOk: uploadImg,
  });
};

export const chatIntroModal = (msg, onOk, stage, disabled) => {
  const modal = Modal.info({
    title: `stage ${stage}`,
    content: msg,
    onOk: onOk || null,
    okButtonProps: { disabled: false },
  });
};

export const UploadModal = ({ func, isUploaded, uploadImg, show }) => {
  return (
    <Modal
      visible={show}
      onOk={uploadImg}
      cancelText={<p className="cancel-text">.</p>}
      title={`upload payment receipt`}
    >
      <div onClick={func}>
        <p>please upload a proof of payment</p>
        <p>image must be less than 2mb</p>
        {isUploaded && (
          <div className="progress">
            <div className="progress-value"></div>
          </div>
        )}
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          disabled={true}
        >
          <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </div>
    </Modal>
  );
};

export const InstructonsModal = ({
  discussionDetails,
  discussionData,
  stage,
  show,
  onOk,
  checked,
  handleCheck,
}) => {
  console.log(discussionDetails);
  return (
    <Modal
      visible={show}
      cancelText={<p className="cancel-text">.</p>}
      title={`instructions for stage ${stage ? "1" : "2"}`}
      onOk={onOk}
      okButtonProps={{ disabled: !checked }}
    >
      <div>
        <Alert
          message="notice"
          description={`${discussionDetails?.in_escrow} ${removeSubstring(
            discussionDetails?.in_escrow_coin
          )} is now in the escrow`}
          type="success"
          closable
          showIcon
          style={{ marginBottom: "13px" }}
        />

        <p style={{ marginTop: "15px" }}>
          {`${
            discussionData?.deal_data[0]?.no_third_party
              ? "Third party payments are NOT allowed for this trade. Please endeavor to follow this rule and other rules set aside by the vendor below:"
              : ""
          }`}
        </p>

        <p style={{ marginTop: "15px" }}>
          please check the instructions tab below for the procedures of your
          transaction
        </p>

        <p style={{ marginTop: "15px" }}>
          Do not release funds without confirmation
        </p>

        <Checkbox
          className="message-check"
          checked={checked}
          onChange={handleCheck}
        >
          i understand the instructions.
        </Checkbox>
      </div>
    </Modal>
  );
};

export const InstructonsModalMerchant = ({
  discussionDetails,
  discussionData,
  stage,
  show,
  onOk,
  checked,
  handleCheck,
}) => {
  return (
    <Modal
      visible={show}
      cancelText={<p className="cancel-text">.</p>}
      title={`instructions for stage ${stage ? "1" : "2"}`}
      onOk={onOk}
      okButtonProps={{ disabled: !checked }}
    >
      <div>
        <Alert
          message="notice"
          description={`${discussionDetails?.in_escrow} ${removeSubstring(
            discussionDetails?.in_escrow_coin
          )} is now in the escrow`}
          type="success"
          closable
          showIcon
          style={{ marginBottom: "" }}
        />

        <p style={{ marginTop: "15px" }}>
          {`${
            discussionData?.deal_data[0]?.no_third_party
              ? "Third party payments are NOT allowed for this trade. Please endeavor to follow this rule and other rules set aside by the vendor below:"
              : ""
          }`}
        </p>

        <p style={{ marginTop: "15px" }}>
          please check the instructions tab below for the procedures of your
          transaction
        </p>

        <p style={{ marginTop: "15px" }}>
          Do not release funds without confirmation
        </p>

        <Checkbox
          className="message-check"
          checked={checked}
          onChange={handleCheck}
        >
          i understand the instructions.
        </Checkbox>
      </div>
    </Modal>
  );
};
