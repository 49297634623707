import React, { useState, useEffect } from "react";
import "../../Discussion/discussion.scss";
import { AdminChatHeader } from "../../../components/Chat/ChatHeader";
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import Loader from "../../../components/Loader/Loader";
import axios from "axios";
import { StreamChat } from "stream-chat";
import { useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Button, message, Modal } from "antd";
import { bearerInstanceWithToken } from "../../../utils/API";
import { currencyFormat } from "../../../utils/formatSourceText";
import { removeSubstring } from "../../../utils/removeStringFromText";

const AdminChat = () => {
  const user = useSelector((state) => state?.user?.userData);
  const param = useParams();
  const location = useLocation();
  const history = useHistory();

  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customMessage, setCustomMessage] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [loadingAward, setLoadngAward] = useState(false);
  const [awardedUser, setAwardedUser] = useState({
    name: null,
    id: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const [isChatTerminated, setIsChatTerminated] = useState(false);

  const discussionData = location.state.fullDetails;

  const amount =
    discussionData?.discussion_data[0]?.stage === "1"
      ? discussionData?.discussion_data[0]?.source_value
      : discussionData?.discussion_data[0]?.destination_value;

  const sourceCurrency =
    discussionData?.discussion_data[0]?.stage === "1"
      ? discussionData?.discussion_data[0]?.source_currency
      : discussionData?.discussion_data[0]?.destination_currency;

  const handleCloseDispute = async () => {
    let formData = new FormData();
    formData.append("dispute_id", discussionData?.id);
    try {
      await bearerInstanceWithToken(user?.token).post(
        "/close_dispute",
        formData
      );
    } catch (err) {
      message.error(err.response?.data?.message);
    }
  };

  const kickModOut = async () => {
    if (channel) {
      await channel.sendEvent({
        type: "remove-mod",
      });
    }
  };

  const goBack = async () => {
    kickModOut();
    await handleCloseDispute();
    history.push(`/disputes`);
    channel?.removeMembers([user?.id]);
  };

  const init = async () => {
    const res = await axios.get(
      `https://lily-factory.live/chat/server.php?create-token=${user.id}`
    );
    const { token } = res.data;

    const userData = {
      id: user?.id,
      name: "moderator",
    };

    const chatClient = StreamChat.getInstance("2shvqv4hcrbh");
    await chatClient
      .connectUser(userData, token)
      .then((res) => {
        // console.log(res)
      })
      .catch(() => message.error("chat not connecting please refresh"));

    const chatChannel = chatClient.channel("messaging", param?.id, {
      name: param?.id,
      // members: [user?.id, `${merchantDetails?.profile_data[0]?.user_name}`],
      // color: "green",
    });

    chatChannel.addMembers([user?.id]);

    // console.log(chatChannel.state.members[user?.id].user.name);

    await chatChannel.watch({ presence: true });

    // custom event that fires when a particular event occurs

    chatChannel.on((event) => {
      if (event?.type === "in_chat") {
        // console.log("moderator has entered the chat");
      } else if (event?.type === "mod-out") {
        setIsChatTerminated(true);
      } else if (event?.type === "resume_trade") {
        goBack();
      }
    });

    const message = {
      text: `hi, I'’m ${user?.user_name} and I will be your dispute resolution moderator for this trade. please give me a few minutes let me go through the conversation. also kindly prepare all evidence that will be used to resolve this issue.`,
      user: { user_id: user?.id, id: user?.id }, // Set the user property to the user's ID
      hidden: true,
    };

    if (
      discussionData?.discussion_data[0]?.custom_msg_admin === 0 &&
      !customMessage
    ) {
      chatChannel
        .sendMessage(message)
        .then((res) => {
          let formData = new FormData();
          formData.append("discussion_id", param.id);
          formData.append("update_type", "custom_msg_admin");
          bearerInstanceWithToken(user.token)
            .post(`/update_discussion`, formData)
            .then((res) => {
              setCustomMessage(true);
            });
        })
        .catch((err) => {
          // console.log(err);
        });
    }

    setChannel(chatChannel);
    setClient(chatClient);
  };

  useEffect(() => {
    const handleAssignDispute = async () => {
      if (!discussionData?.assigned_to) {
        let formData = new FormData();
        formData.append("dispute_id", discussionData?.id);
        try {
          await bearerInstanceWithToken(user?.token).post(
            "/new_assignment",
            formData
          );
          message.success("you have been assigned to resolve the dispute");
        } catch (err) {
          // console.log(err);
          message.error(err.response?.data?.message);
        }
      } else return false;
    };

    handleAssignDispute();
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const userLoggin = async () => {
      await channel?.sendEvent({
        type: "in_chat",
        text: "moderator just entered the chat",
      });
    };

    userLoggin();
  }, [channel]);

  //  const elements = document.getElementsByClassName('my-class');

  // useEffect(() => {
  //   if (channel) {
  //     const elements = document.getElementsByClassName(
  //       "str-chat__message-simple-text-inner"
  //     )

  //     for (let i = 0; i < elements.length; i++) {
  //       elements[i].style.setProperty("background-color", "gray");
  //     }
  //   }
  // }, [channel]);

  const escrowValue =
    discussionData?.stage === "1"
      ? `$${currencyFormat(discussionData?.discussion_data[0]?.source_value)}`
      : `₦${currencyFormat(
          discussionData?.discussion_data[0]?.destination_value
        )}`;

  const handleAssignUser = (name, id) => {
    setAwardedUser({
      name: name,
      id: id,
    });
    setOpenModal(true);
  };

  const fireAwardValueEvent = async (msg) => {
    await channel?.sendEvent({
      type: "award_value",
      text: "Escrow value has been awarded",
      data: {
        amount: discussionData?.in_escrow,
        name: awardedUser.name,
        message: msg,
      },
    });
  };

  const handleAwardValue = async () => {
    setLoadngAward(true);

    let formData = await new FormData();
    formData.append("dispute_id", discussionData?.id);
    formData.append("winner", awardedUser.id);

    try {
      let res = await bearerInstanceWithToken(user.token).post(
        "/dispute_award",
        formData
      );
      setLoadngAward(false);
      setOpenModal(false);
      fireAwardValueEvent(res.data.message);
      message.success(
        `you have successfully awareded the escrow value to ${awardedUser.name}`
      );
      setTimeout(() => {
        history.push(`/disputes`);
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      message.error(err.response?.data?.message);
    }
  };

  // const tryEvent = async () => {
  //   await channel?.sendEvent({
  //     type: "testing",
  //     text: "Escrow value has been awarded",
  //     data: { amount: "500", name: " Daniel" },
  //   });
  // };

  console.log(discussionData);

  return (
    <div className="message-wrapper">
      <Modal
        title={`award ${discussionData?.in_escrow} ${removeSubstring(discussionData?.in_escrow_coin)} in escrow to @${awardedUser.name}`}
        visible={openModal}
        onOk={() => handleAwardValue()}
        onCancel={() => setOpenModal(false)}
        cancelText={<p>cancel</p>}
        confirmLoading={loadingAward}
      >
        <p>
          ensure you have carefully gone through the evidences provided by both
          parties and that your decision is based strictly on our guidelines.
        </p>
      </Modal>

      <Modal
        title={`moderator termination`}
        visible={isChatTerminated}
        onOk={goBack}
        cancelText={<p className="cancel-text">.</p>}
      >
        <p>
          user has terminated moderation and decided to proceed with the trade
        </p>
      </Modal>

      {!channel || !client || loading ? (
        <Loader />
      ) : (
        <Chat client={client} theme="messaging light">
          <Channel channel={channel}>
            <Window>
              <>
                <AdminChatHeader
                  stage={discussionData?.stage}
                  amount={amount}
                  sourceCurrency={sourceCurrency}
                  merchant={discussionData?.merchant_user_name}
                  dealer={discussionData?.dealer_user_name}
                  escrowValue={discussionData?.in_escrow}
                  escrowCoin={removeSubstring(discussionData?.in_escrow_coin)}
                />
                <MessageList
                  hideDeletedMessages={true}
                  messageActions={["reply", "quote"]}
                  additionalMessageInputProps={{}}
                />
              </>

              <div className="message-wrapper-box">
                <div className="message-box">
                  <MessageInput
                    grow={true}
                    additionalTextareaProps={{
                      placeholder: "type a message...",
                    }}
                  />
                </div>

                <div className="message-wrapper-action mod-btn-box">
                  <Button
                    type="text"
                    className="mod-btn"
                    onClick={() =>
                      handleAssignUser(
                        discussionData?.merchant_user_name,
                        discussionData?.merchant
                      )
                    }
                  >
                    award @{discussionData?.merchant_user_name}
                  </Button>

                  <Button
                    type="text"
                    className="mod-btn"
                    onClick={() =>
                      handleAssignUser(
                        discussionData?.dealer_user_name,
                        discussionData?.dealer
                      )
                    }
                  >
                    award @{discussionData?.dealer_user_name}
                  </Button>
                </div>
              </div>
            </Window>
          </Channel>
        </Chat>
      )}
    </div>
  );
};

export default AdminChat;
