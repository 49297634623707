import "./Chat.scss";
// import { useChatContext } from 'stream-chat-react';
import { useSelector } from "react-redux";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { Tooltip, Tag } from "antd";
import { FiArrowRight } from "react-icons/fi";
import {
  formatDenomination,
  currencyFormat,
} from "../../utils/formatSourceText";
import { useHistory } from "react-router-dom";
import { formatSourceText } from "../../utils/formatSourceText";
import { removeSubstring } from "../../utils/removeStringFromText";

const ChatHeader = ({
  username,
  likes,
  dislikes,
  status,
  discussionData,
  profileData,
  channel,
  location,
  acceptedChat,
  checkMerchant,
  issueHasBeenRaised,
  score,
}) => {
  // const { channel } = useChatContext();
  const user = useSelector((state) => state?.user?.userData);
  const history = useHistory();

  let obj = Object.values(channel.state.members);

  // function formats numbers
  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <div className="chatheader">
      <div className="info-box">
        {discussionData?.stage === "1" && (
          <div className="chatheader-title">
            <span className="ant-tag ant-tag-success">
              stage {discussionData?.stage}
            </span>

            {!issueHasBeenRaised && discussionData?.status !== "dispute" ? (
              // <p className="source-text">
              //   {discussionData.source === "bank fund"
              //     ? "bank account"
              //     : discussionData.source}{" "}
              //   {/* {formatDenomination(discussionData?.source_currency)} */}
              //   <FiArrowRight style={{ margin: "0px 4px" }} />
              //   {/* bitcoin{" "} */}
              //   <br className="break" />
              //   BTC
              //   {/* {discussionData.destination === "bank fund"
              //     ? "bank account"
              //     : discussionData.destination} */}
              //   {/* {formatDenomination(
              //     discussionData?.source_currency.toLocaleString()
              //   )}{" "} */}
              //   {/* {discussionData?.source_value} */} (
              //   {discussionData?.in_escrow} BTC in escrow)
              // </p>

              <p className="source-text">
                {checkMerchant
                  ? `receive ${formatDenomination(
                      discussionData?.source_currency
                    )}${currencyFormat(discussionData?.source_value)} from ${
                      checkMerchant
                        ? location?.dealer_data[0]?.user_name
                        : location?.merchant_data[0]?.user_name
                    }'s ${formatSourceText(discussionData?.source)}
                     (${discussionData?.in_escrow} ${removeSubstring(
                      discussionData?.in_escrow_coin
                    )} in escrow)`
                  : `send ${formatDenomination(
                      discussionData?.source_currency
                    )}${currencyFormat(discussionData?.source_value)} to ${
                      checkMerchant
                        ? location?.dealer_data[0]?.user_name
                        : location?.merchant_data[0]?.user_name
                    }'s ${formatSourceText(discussionData?.source)} (${
                      discussionData?.in_escrow
                    } ${removeSubstring(
                      discussionData?.in_escrow_coin
                    )} in escrow)`}
              </p>
            ) : (
              "resolving dispute"
            )}
          </div>
        )}

        {discussionData?.stage === "2" && (
          <div className="chatheader-title">
            <span className="ant-tag ant-tag-success">
              stage {discussionData?.stage}
            </span>

            {!issueHasBeenRaised && discussionData?.status !== "dispute" ? (
              // <p className="source-text">
              //   BTC
              //   <FiArrowRight style={{ margin: "0px 4px" }} />
              //   {discussionData?.destination === "bank fund"
              //     ? "bank account"
              //     : discussionData?.destination}{" "}
              //   ({discussionData?.in_escrow} BTC in escrow)
              //   {/* {formatDenomination(discussionData?.destination_currency)} */}
              //   {/* {discussionData?.destination_value.toLocaleString()} */}
              // </p>
              <p className="source-text">
                {checkMerchant
                  ? `send ${formatDenomination(
                      discussionData?.destination_currency
                    )}${currencyFormat(discussionData?.destination_value)} to ${
                      checkMerchant
                        ? location?.dealer_data[0]?.user_name
                        : location?.merchant_data[0]?.user_name
                    }'s ${formatSourceText(discussionData?.destination)}
                   (${discussionData?.in_escrow} ${removeSubstring(
                      discussionData?.in_escrow_coin
                    )} in escrow)`
                  : `receive ${formatDenomination(
                      discussionData?.destination_currency
                    )}${currencyFormat(
                      discussionData?.destination_value
                    )} from ${
                      checkMerchant
                        ? location?.dealer_data[0]?.user_name
                        : location?.merchant_data[0]?.user_name
                    }'s ${formatSourceText(discussionData?.destination)} (${
                      discussionData?.in_escrow
                    } ${removeSubstring(
                      discussionData?.in_escrow_coin
                    )} in escrow)`}
              </p>
            ) : (
              "resolving dispute"
            )}
          </div>
        )}
      </div>

      <div className="chatheader-main">
        <div className="left">
          <div>
            <div
              className="name"
              onClick={() =>
                history.push(
                  user.id === location?.discussion_data[0]?.dealer
                    ? `/profile/${location?.merchant_data[0]?.user_name}`
                    : `/profile/${location?.dealer_data[0]?.user_name}`
                )
              }
            >
              {user.id === location?.discussion_data[0]?.dealer
                ? location?.merchant_data[0]?.user_name
                : location?.dealer_data[0]?.user_name}
              {/* @{obj[1]?.user?.name || username} {} */}

              {score?.trust_score >= 80 ? (
                <Tag
                  color="green"
                  className="chat-tag"
                  style={{
                    marginLeft: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  trusted
                </Tag>
              ) : score?.trust_score < 0 ? (
                <Tag
                  color="red"
                  className="chat-tag"
                  style={{
                    marginLeft: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  be careful
                </Tag>
              ) : (
                ""
              )}
            </div>

            {/* {discussionData?.accepted === 1 || acceptedChat ? ( */}
            <Tooltip
              placement="topRight"
              title={
                obj[1] && obj[1]?.user?.online === true ? "online" : "away"
              }
              style={{ margin: "0px" }}
            >
              <span
                style={{
                  color:
                    obj[1] && obj[1]?.user?.online === true
                      ? "#14a014"
                      : "#dedede",
                  fontSize: "12px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                {obj[1] && obj[1]?.user?.online === true ? "online" : "away"}
              </span>
            </Tooltip>
            {/* ) : (
              ""
            )} */}

            {/* {discussionData?.accepted === 0 || !acceptedChat ? (
              <span
                style={{
                  color: "#dedede",
                  fontSize: "12px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                {!checkMerchant && `waiting for ${location?.merchant_data[0]?.user_name} to accept your deal`}
              </span>
            ) : (
              ""
            )} */}
          </div>

          <div className="like-dislike-chat no-margin-top">
            <span className="like">
              <LikeOutlined /> {score?.positive_reviews_count}
            </span>

            <span className="dislike no-margin-right">
              <DislikeOutlined /> {score?.negative_reviews_count}
            </span>
          </div>
        </div>

        <div className="right">
          {/* <div className="chat-summary">waiting to accept..</div>  */}

          {/* <div className="chat-summary">no issues raised</div> */}
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;

export const AdminChatHeader = ({
  stage,
  amount,
  sourceCurrency,
  dealer,
  merchant,
  escrowValue,
  escrowCoin,
}) => {
  return (
    <div className="chatheader">
      <div className="chatheader-title">
        <span className="ant-tag ant-tag-success">stage {stage}</span>
        {/* {formatDenomination(sourceCurrency)} */}
        {escrowValue} {escrowCoin} in escrow
      </div>

      <div className="chatheader-main">
        <div className="left">
          <div className="names-wrapper">
            <div>
              <div className="name">@{merchant}</div>
              <Tooltip
                placement="topRight"
                // title={
                //   obj[1] && obj[1]?.user?.online === true ? "online" : "away"
                // }
                style={{ margin: "0px" }}
              >
                <span
                  style={{
                    color: true ? "#14a014" : "#dedede",
                    fontSize: "12px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  {/* {obj[1] && obj[1]?.user?.online === true ? "online" : "away"} */}
                  online
                </span>
              </Tooltip>
            </div>

            <span className="with-text">with</span>

            <div>
              <div className="name">@{dealer}</div>

              <Tooltip
                placement="topRight"
                // title={
                //   obj[1] && obj[1]?.user?.online === true ? "online" : "away"
                // }
                style={{ margin: "0px" }}
              >
                <span
                  style={{
                    color: false ? "#14a014" : "#dedede",
                    fontSize: "12px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  {/* {obj[1] && obj[1]?.user?.online === true ? "online" : "away"} */}
                  away
                </span>
              </Tooltip>
            </div>
          </div>

          {/* <div className="like-dislike-chat no-margin-top">
            <span className="like">
              <LikeOutlined /> 200
            </span>

            <span className="dislike no-margin-right">
              <DislikeOutlined /> 30
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
