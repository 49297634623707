export const formatSourceText = (source) => {
  let src = "";

  if (source === "bank fund") {
    src = "bank account";
  }
  //  else if (source === "moneygram") {
  //   src = "moneygram";
  // } else if (source === "giftcard") {
  //   src = "giftcard";
  // }
  else {
    src = source;
  }

  return src;
};

export function currencyFormat(num) {
  return num && num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function currencyFormatNumber(num) {
  return num && num.toFixed(6).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const formatDenomination = (source) => {
  if (source === "ngn") {
    return "₦";
  } else if (source === "usd" || source === "cad") {
    return "$";
  } else if (source === "eur") {
    return "€";
  } else if (source === "gbp") {
    return "£";
  } else {
    return "";
  }
};
