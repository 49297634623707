export const countries = [
  "Any Country",
  "United States",
  "United Kingdom",
  "Nigeria",
];

export const us_states = [
  "Any State",
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const uk_states = [
  "Any State",
  "Avon",
  "Bedfordshire",
  "Berkshire",
  "Buckinghamshire",
  "Cambridgeshire",
  "Cheshire",
  "Cleveland",
  "Cornwall",
  "Cumbria",
  "Derbyshire",
  "Devon",
  "Dorset",
  "Durham",
  "East Sussex",
  "Essex",
  "Gloucestershire",
  "Hampshire",
  "Herefordshire",
  "Hertfordshire",
  "Isle of Wight",
  "Kent",
  "Lancashire",
  "Leicestershire",
  "Lincolnshire",
  "London",
  "Merseyside",
  "Middlesex",
  "Norfolk",
  "Northamptonshire",
  "Northumberland",
  "North Humberside",
  "North Yorkshire",
  "Nottinghamshire",
  "Oxfordshire",
  "Rutland",
  "Shropshire",
  "Somerset",
  "South Humberside",
  "South Yorkshire",
  "Staffordshire",
  "Suffolk",
  "Surrey",
  "Tyne and Wear",
  "Warwickshire",
  "West Midlands",
  "West Sussex",
  "West Yorkshire",
  "Wiltshire",
  "Worcestershire",
];

export const ng_states = [
  "Any State",
  "Abia",
  "Adamawa",
  "Anambra",
  "Akwa Ibom",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Enugu",
  "Edo",
  "Ekiti",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

// banks
export const us_banks = [
  "Any Bank",
  "JPMorgan Chase Bank",
  "Bank of America",
  "Wells Fargo Bank",
  "Citibank",
  "U.S. Bank National Association",
  "PNC Bank",
  "TD Bank",
  "The Bank of New York Mellon",
  "Capital One",
  "Charles Schwab Bank",
  "State Street Bank and Trust Company",
  "Goldman Sachs Bank USA",
  "Fifth Third Bank",
  "HSBC Bank USA",
  "Citizens Bank",
  "Morgan Stanley Bank",
  "Ally Bank",
  "The Northern Trust Company",
  "KeyBank National Association",
  "BMO Harris Bank National Association",
  "Regions Bank",
  "First Republic Bank",
  "Manufacturers and Traders Trust Company",
  "American Express National Bank",
  "MUFG Union Bank",
  "The Huntington National Bank",
  "Morgan Stanley Private Bank",
  "Silicon Valley Bank",
  "Discover Bank",
  "USAA Federal Savings Bank",
  "Capital One",
  "BBVA USA",
  "Santander Bank",
  "Comerica Bank",
  "UBS Bank",
  "Synchrony Bank",
  "First Horizon Bank",
  "Zions Bancorporation",
  "City National Bank",
  "Signature Bank",
  "People United Bank",
  "New York Community Bank",
  "Bank of China",
  "Banco Popular de Puerto Rico",
  "Synovus Bank",
  "CIT Bank",
  "East West Bank",
  "	First-Citizens Bank",
  "TCF National Bank",
  "BOKF",
  "Deutsche Bank",
  "CIBC Bank",
  "Frost Bank",
  "Valley National Bank",
  "Texas Capital Bank",
  "South State Bank",
  "First National Bank",
  "Western Alliance Bank",
  "BankUnited",
  "Pinnacle Bank",
  "Prosperity Bank",
  "Hancock Whitney Bank",
  "BNY Mellon",
  "Associated Bank",
  "UMB Bank",
  "Commerce Bank",
  "Webster Bank",
  "Raymond James Bank",
  "Flagstar Bank, FSB",
  "Sallie Mae Bank",
  "Barclays Bank",
  "Sterling National Bank",
  "MidFirst Bank",
  "Pacific Western Bank",
  "Umpqua Bank",
  "TD Bank",
  "Bank OZK",
  "United Bank",
  "Investors Bank",
  "Fulton Bank",
  "First National Bank",
  "Bank of America California",
  "FirstBank",
  "Arvest Bank",
  "BancorpSouth Bank",
  "Old National Bank",
  "First Hawaiian Bank",
  "Simmons Bank",
  "Wells Fargo National Bank",
  "First Midwest Bank",
  "Bank of Hawaii",
  "Ameris Bank",
  "Pacific Premier Bank",
  "Atlantic Union Bank",
  "Mechanics Bank",
  "Washington Federal Bank",
  "Cathay Bank",
  "FirstBank Puerto Rico",
  "Cadence Bank",
  "City National Bank of Florida",
  "Glacier Bank",
  "Customers Bank",
  "United Community Bank",
  "Independent Bank",
  "First Interstate Bank",
  "Bank of Hope",
  "Columbia State Bank",
  "Trustmark National Bank",
  "WesBanco Bank",
  "Centennial Bank",
  "Apple Bank for Savings",
  "Eastern Bank",
  "First Financial Bank",
  "Bremer Bank",
  "Renasant Bank",
  "Banner Bank",
  "Stifel Bank and Trust",
  "Towne Bank",
  "Citizens Business Bank",
  "Wilmington Savings Fund Society",
  "First Merchants Bank",
  "Northwest Bank",
  "Community Bank",
  "PlainsCapital Bank",
  "Optum Bank, Inc",
  "Axos Bank",
  "Rockland Trust Company",
  "Provident Bank",
  "Berkshire Bank",
  "Great Western Bank",
  "Sandy Spring Bank",
  "Bank of Baroda",
  "State Bank of India",
];

export const uk_banks = [
  "Any Bank",
  "ABC International Bank",
  "The Access Bank UK",
  "ADIB",
  "Ahli United Bank",
  "AIB Group",
  "Al Rayan Bank",
  "Aldermore Bank",
  "Alliance Trust Savings Limited",
  "Allica Bank",
  "Alpha Bank London",
  "ANZ Bank",
  "Atom Bank",
  "Axis Bank",
  "Bank and Clients",
  "Bank Leumi",
  "Bank Mandiri",
  "Bank of America Merrill Lynch",
  "Bank of Baroda",
  "Bank of Beirut",
  "Bank of Ceylon",
  "Bank of China",
  "Bank of Communications (UK) Limited",
  "Bank of Cyprus UK Limited",
  "Bank of India",
  "Bank of Ireland",
  "BLME",
  "The Bank of New York Mellon",
  "Bank of Scotland",
  "Bank of the Philippine Islands",
  "Bank Saderat plc",
  "Bank Sepah International plc",
  "Barclays Bank",
  "BFC Bank Ltd",
  "BIRA Bank Ltd",
  "BMCE Bank",
  "British Arab Commercial Bank",
  "Quintet Private Bank",
  "C. Hoare & Co",
  "CAF Bank Ltd",
  "Cambridge & Counties Bank",
  "Cater Allen Limited",
  "Charity Bank Limited",
  "Charter Court Financial Services",
  "China export const ruction Bank",
  "CIBC World Markets",
  "ClearBank",
  "Close Brothers Limited",
  "Clydesdale Bank",
  "The Co-operative Bank",
  "Coutts & Company",
  "Credit Suisse",
  "Credit Suisse International",
  "Crown Agents Bank",
  "DB UK Bank",
  "Diamond Bank",
  "EFG Private Bank",
  "Duncan Lawrie",
  "Europe Arab Bank",
  "FBN Bank",
  "FCE Bank",
  "FCMB Bank",
  "Gatehouse Bank",
  "GE Capital Bank",
  "Ghana International Bank",
  "Goldman Sachs International Bank",
  "Guaranty Trust Bank",
  "Gulf International Bank",
  "Habib Bank Zurich",
  "Habibsons Bank",
  "Hampden & Co Plc",
  "Hampshire Trust Bank",
  "Havin Bank",
  "HSBC Bank",
  "HSBC Trust Company UK",
  "ICBC",
  "ICBC Standard Bank",
  "ICICI Bank",
  "Investec Bank",
  "J.P. Morgan Europe Limited",
  "J.P. Morgan International Bank",
  "J.P. Morgan Securities",
  "Jordan International Bank",
  "Julian Hodge Bank",
  "Kingdom Bank",
  "SG Kleinwort Hambros Bank",
  "Kookmin Bank International",
  "Lloyds Bank",
  "Macquarie Bank",
  "Melli Bank",
  "Metro Bank",
];

export const ng_banks = [
  "Any Bank",
  "Access Bank",
  "Fidelity Bank",
  "First City Monument Bank",
  "First Bank of Nigeria",
  "Guaranty Trust Bank",
  "Union Bank of Nigeria",
  "United Bank for Africa",
  "Zenith Bank",
  "Citibank Nigeria",
  "Ecobank",
  "Heritage Bank",
  "Keystone Bank",
  "Polaris Bank",
  "Stanbic IBTC Bank",
  "Standard Chartered",
  "Sterling Bank",
  "Titan Trust Bank",
  "Unity Bank",
  "Wema Bank",
  "PiggyVest",
];

export const account_types = [
  "Any Type",
  "Savings",
  "Current",
  "Checking",
  "Money market",
  "Certificates of deposit",
  "Retirement",
  "Fixed deposit",
  "Joint",
  "Domiciliary",
  "Corporate",
  "Student",
];

export const account_age = [
  "Any Age",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

// crypto
export const wallet_types = [
  "Any Wallet Type",
  "Coinbase",
  "Trezor",
  "Exodus",
  "BitGo",
  "CoinPayments",
  "StakedWallet",
  "MyEtherWallet",
  "Trust Wallet",
  "Jaxx",
  "Coinomi",
  "Electrum",
  "Bitcoin Core",
  "Xapo Wallet",
  "Blockchain Wallet",
  "Bread Wallet",
  "Luno",
  "Mycelium Wallet",
  "Counterwallet",
  "Omniwallet",
  "Airbitz",
  "Copay",
  "Daedalus",
  "Lumi Wallet",
  "Binance Wallet",
  "USDX Wallet",
  "Xeth",
  "Armory",
  "Atomic Wallet",
  "Block.io",
  "CoinJar",
  "Crypterium",
  "CryptoStorage",
  "Guarda",
  "Infinito Wallet",
  "Infinity Wallet",
  "Parity",
  "Quppy",
  "Wirex",
  "ArcBit",
];

export const exchanges = [
  "Any Exchange",
  "Coinbase",
  "Binance",
  "UPHOLD",
  "eToro",
  "Poloniex",
  "LocalBitcoins",
  "Bitfinex",
  "CEX.IO",
  "NiceHash",
  "Kraken",
  "BTCC",
  "C-Cex",
  "Kucoin",
  "Bittrex",
  "Cryptopia",
  "BitMEX",
  "HitBTC",
  "Bit2C",
  "BitPay",
  "Bitstamp",
  "Gemini",
  "OkCoin",
  "ChainEX",
  "YoBit",
  "Paxful",
  "CoinAll",
  "CoinDCX",
  "CoinMate",
  "LiveCoin",
  "BuyCoins Africa",
  "Patricia.com.ng",
  "Bitcoin.co.id",
  "bitFlyer",
  "Bithumb",
  "Coinfloor",
  "Coinmama",
  "Crypto.com",
  "LocalCoinSwap",
];

export const wallet_age = [
  "Any Age",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

// giftcards
export const card_types = [
  "Any Card Type",
  "Physical",
  "eGift Card",
  "Closed Loop",
  "Open Loop",
];

export const card_brands = [
  "Any Card Brand",
  "Walmart",
  "iTunes",
  "Amazon",
  "Visa",
  "American Express",
  "Starbucks",
  "Disney",
  "Sephora",
  "eBay",
  "Target",
  "Nordstrom",
  "The Home Depot",
  "Etsy",
  "Adidas",
  "Best Buy",
];

export const curType = (curr) => {
  if (curr === "usd" || curr === "cad") return "$";
  //'&dollar;';
  else if (curr === "ngn") return "₦";
  //'&#8358;';
  else if (curr === "gbp") return "£";
  else if (curr === "eur") return "€";
};

export const giftCardsList = [
  "itunes gift card",
  "amazon gift card",
  "google play gift card",
  "steam gift card",
  "razer gold gift card",
  "sephora gift card",
  "nordstrom gift card",
  "bestbuy gift card",
  "ebay gift card",
  "gamestop gift card",
  "macy gift card",
  "target gift card",
  "visa gift card",
  "mastercard gift card",
  "bluemercury gift card",
  "kohl gift card",
  "the body shop gift card",
  "ulta gift card",
  "adidas gift card",
  "madewell gift card",
  "zappos gift card",
  "blue apron gift card",
  "doordash gift card",
  "ubereats gift card",
  "gamestop gift card",
  "netflix gift card",
  "spotify premium gift card",
  "american express gift card",
  "starbucks gift card",
  "disney gift card",
  "xbox gift card",
  "walmart gift card",
];

export const paymentInstruments = [
  'alipay',
  'm-pesa',
  'wechatpay',
  'tether (usdt)',
  'tron (trx)',
  'bitcoin gold',
  'bitcoin cash',
  'transferWise',
  'payoneer',
  'line pay',
  'webmoney',
  'chippercash',
  'eversend',
  'astroPay direct',
  'afriex',
  'airtel money',
  'chase quickpay',
  'wells fargo surepay',
  'esewa',
  'gpay',
  'sofi money instant transfer',
  'western union'
];

export const instrumentsList = [
  // "all",
  "bank account",
  "paypal",
  "cash",
  "skrill",
  "venmo",
  "bitcoin",
  //   "giftcard",
  "cashapp",
  "zelle",
  "moneygram",
  "perfect money",
  "greendot",
  "ethereum",
  "litecoin",
  "dogecoin",
  "western union",
  "alipay",
  "m-pesa",
  "wechatpay",
  "usdt",
  "tron",
  "bitcoin gold",
  "bitcoin cash",
  "transferwise",
  "payoneer",
  "line pay",
  "webmoney",
  "chippercash",
  "wise",
  "chime",
  "eversend",
  "astropay",
  "afriex",
  "airtel money",
  "chase quickpay",
  "esewa",
  "gpay",
  "sofi money",
  "apple pay",
  "walmart pay",
  "revolut",
  "paytm",
  "chase mobile",
  "mtn mobile money",
  "vodafone",
  "dwolla",
  "samsung pay",
  "orange cash",
  "amazon pay",
  "iTunes giftcard",
  "amazon giftcard",
  "google Play giftcard",
  "steam giftcard",
  "razer Gold giftcard",
  "sephora giftcard",
  "nordstrom giftcard",
  "bestBuy giftcard",
  "eBay giftcard",
  "gamestop giftcard",
  "macy giftcard",
  "target giftcard",
  "visa giftcard",
  "masterCard giftcard",
  "bluemercury giftcard",
  "kohl giftcard",
  "the body shop giftcard",
  "ulta giftcard",
  "adidas giftcard",
  "madewell giftcard",
  "zappos giftcard",
  "blue apron giftcard",
  "doorDash giftcard",
  "uberEats giftcard",
  "gamestop giftcard",
  "netflix giftcard",
  "spotify premium giftcard",
];
