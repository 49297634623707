import React, { useEffect, useState } from "react";
import { Tooltip, Breadcrumb, Tag } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { bearerInstanceWithToken } from "../../../utils/API";
import Loader from "../../../components/Loader/Loader";

const Metrics = () => {
  const [metricsData, steMetricsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.user?.userData);
  const data = [
    { level: 1, title: "active trades", number: metricsData?.active_trades },
    {
      level: 2,
      title: "cancelled trades",
      number: metricsData?.canceled_trades,
    },
    {
      level: 3,
      title: "completed trades",
      number: metricsData?.completed_trades,
    },

    {
      level: 15,
      title: "completed trades",
      number: metricsData?.completed_trades,
    },

    {
      level: 4,
      title: "disputed trades",
      number: metricsData?.disputed_trades,
    },
    {
      level: 5,
      title: "ongoing disputes",
      number: metricsData?.ongoing_disputes,
    },
    {
      level: 6,
      title: "resolved disputes",
      number: metricsData?.resolved_disputes,
    },
    {
      level: 7,
      title: "total assets in bank (BTC)",
      number: metricsData?.total_assets_in_bank_btc,
    },
    {
      level: 13,
      title: "total assets in bank (LTC)",
      number: metricsData?.total_assets_in_bank_ltc,
    },
    {
      level: 8,
      title: "total BTC assets in bank (USD)",
      number: metricsData?.total_assets_in_bank_btc_usd,
    },
    {
      level: 14,
      title: "total LTC assets in bank (USD)",
      number: metricsData?.total_assets_in_bank_ltc_usd,
    },
    {
      level: 9,
      title: "total earned commissions (BTC)",
      number: metricsData?.total_earned_in_commissions_btc,
    },
    {
      level: 10,
      title: "total BTC earned commissions (USD)",
      number: metricsData?.total_earned_in_commissions_btc_usd,
    },

    {
      level: 11,
      title: "total earned commissions (LTC)",
      number: metricsData?.total_earned_in_commissions_ltc,
    },
    {
      level: 12,
      title: "total LTC earned commissions (USD)",
      number: metricsData?.total_earned_in_commissions_ltc_usd,
    },
  ];

  useEffect(() => {
    const handleGetMetrics = async () => {
      setLoading(true);
      try {
        let response = await bearerInstanceWithToken(user.token).get(
          `/dashboard`
        );

        steMetricsData(response?.data?.dashboard_data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    handleGetMetrics();
  }, []);

  return (
    <div className="home">
      <div className="home-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
          <Link to="/admin-dashboard" >admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>metrics</Breadcrumb.Item>
        </Breadcrumb>

        <div className="title-box">
          <p className="title-bar">metrics</p>
          <p className="icon-text">admin level 2</p>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="home-card-box">
            {/* {sortArray.map((item) => {
            return (
              <div className="home-card" onClick={item.action}>
                {item.title}
              </div>
            );
          })} */}

            {/* <div className="home-card-box"> */}
            {data.map((item) => {
              return (
                <div className="home-card" key={item?.level}>
                  {item.title}

                  <p>{item?.number || "0"}</p>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Metrics;
