import React from "react";
import { Link } from "react-router-dom";
import { TwitterOutlined } from "@ant-design/icons";
import logo from "../../assets/logo.png";

import "./Footer.scss";

export default function Footer() {
  const handleOpenLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="footer-container">
      <div className="footer-wrapper">
        <div className="row-two">
          <div className="top">
            <Link to="/" className="nav-link">
              <img src={logo} className="logo" alt="lili logo" />
              lily.africa
            </Link>
          </div>
          <div className="bottom">convert money effortlessly</div>
        </div>
        <div className="row-three">
          <div className="top">email: info@lily.africa</div>
          <div className="bottom">
            <TwitterOutlined
              onClick={() => handleOpenLink("https://x.com/lilydotafrica")}
            />{" "}
            @lilydotafrica
            <div className="right">
              <Link to="/privacy-policy">privacy</Link> |{" "}
              <Link to="/terms">terms & conditions</Link> |{" "}
              <Link to="/contact">contact us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
