import React from "react";
import {
  LikeOutlined,
  ArrowRightOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { Tooltip } from "antd";
import "./profilereviewitem.scss";
import moment from "moment";

export default function ProfileReviewItem({ data }) {
  const location = useLocation().pathname;
  const history = useHistory();

  return (
    <div className="profile-review-item-container">
      <div className="row-one">
        <div className="box" >
          <div className="left">
            <div className="username-reviewitem-text">@{data?.user_name}</div>
            <div className="date">{moment(data?.created_at).format("LL")}</div>
            <div className="">
              {data?.rating === 0 ? (
                <span className="like-text" style={{ color: "#ed1450" }}>
                  <DislikeOutlined /> negative
                </span>
              ) : (
                <span className="like-text">
                  <LikeOutlined /> positive
                </span>
              )}
            </div>
          </div>

          {/* Correct the online status logic later */}

          {/* <span
            className="status"
            style={{ color: 3 === 1 ? "#14a014" : "#dedede" }}
          >
            {3 === 1 ? "online" : "away"}
          </span> */}
        </div>

        <div className="row-two box-two" >
          <Tooltip>
            <div className="source-badge">
              {data?.deal_data ? (
                <>
                  {data?.deal_data && data?.deal_data[0]?.source === "bank fund"
                    ? "bank account"
                    : data?.deal_data[0]?.source}
                </>
              ) : data?.discussion_data &&
                data?.discussion_data[0]?.source === "bank fund" ? (
                "bank account"
              ) : (
                data?.discussion_data[0]?.source
              )}

              <ArrowRightOutlined
                style={{
                  strokeWidth: "50",
                  stroke: "white",
                  padding: "0px 4px",
                }}
              />
              {data?.deal_data ? (
                <>
                  {data?.deal_data &&
                  data?.deal_data[0]?.destination === "bank fund"
                    ? "bank account"
                    : data?.deal_data[0]?.destination}
                </>
              ) : data?.discussion_data &&
                data?.discussion_data[0]?.destination === "bank fund" ? (
                "bank account"
              ) : (
                data?.discussion_data[0]?.destination
              )}
            </div>
          </Tooltip>
          <div className={data?.rating === 0 ? "text-red" : "text-green"}>
            {data?.review_body}
          </div>
        </div>

        <div className="box-three">
          {location.includes("deal") ? null : (
            <div
              className="profile-white-background"
              onClick={() => history.push(`/deal/${data?.deal_id}`)}
            >
              view deal
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
