import React, { useEffect, useState } from "react";
import "./academy.css";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import SEO from "../../components/SEO/seo";

const Academy = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SEO
        title="Academy - Learn To Trade | Lily Africa"
        description="Recognizing the initial learning curve, we have curated a collection of instructional videos to streamline your onboarding experience as a new user. simply follow the step-by-step instructions in these videos, and you'll be trading with confidence in no time."
      />
      <div className="academy-container">
        <div className="academy-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>academy</Breadcrumb.Item>
          </Breadcrumb>

          <div className="msg-bar-left">academy</div>

          <p className="academy-text tto">
            welcome to the lily.africa, where you can seamlessly exchange a
            variety of payment methods and financial instruments through peer to
            peer infrastructure. our platform is meticulously crafted to
            optimize benefits for all parties involved in transactions.{" "}
          </p>

          <p className="academy-text">
            recognizing the initial learning curve, we have curated a collection
            of instructional videos to streamline your onboarding experience as
            a new user. simply follow the step-by-step instructions in these
            videos, and you'll be trading with confidence in no time.
          </p>

          <h2 className="academy-title">
            lesson 1 - how to register on lily.africa
          </h2>

          <p className="academy-text">
            in the video, we describe how to signup for a new lily account. a
            lily profile comes with a free BTC and LTC wallet which is to be
            used to fund trade escrows, also, an account gives you privileges to
            act as either a dealer or a merchant/vendor. we also describe the
            login process
          </p>

          <iframe
            width="100%"
            height={screenWidth > 550 ? "400" : "300"}
            src="https://www.youtube.com/embed/7kZhDJZ4W7c?si=AsRPbkxARFKZjbcj"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ marginTop: "15px" }}
          ></iframe>

          <h2 className="academy-title">
            lesson 2 - how to login to your lily account
          </h2>

          <p className="academy-text">
            in the video, we describe how to login to your lily account. login
            to your account and experience the community driven exchange
          </p>

          <iframe
            width="100%"
            height={screenWidth > 550 ? "400" : "300"}
            src="https://www.youtube.com/embed/ypBwHjWflbs?si=x-ZrEnAJXf2cqtlz"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ marginTop: "15px" }}
          ></iframe>

          <h2 className="academy-title">
            lesson 3 - creating deals and fund your wallet
          </h2>

          <p className="academy-text">
            in this video, we describe how to create a deal on lily.africa and
            fund your wallet. a "deal" is essentially a listing, just like you
            have listings on large p2p marketplaces like Binance, ByBit and OKX.
            with a lily profile, you can act as both a dealer and a
            merchant/vendor. as a merchant or vendor, you can post new "deals"
            or listings, advertising what instruments you are available to pick,
            e.g. cashapp - paypal, venmo - skrill, apple pay - bank account,
            USDT - wechat pay, etc. we also describe how to fund your BTC or LTC
            wallet by sending coins through the BTC or LTC network from an
            external exchange/wallet
          </p>

          <p className="academy-text">
            when you post a deal on lily.africa, it becomes publicly available
            for new dealers to initiate new trades with you. note that as a
            merchant/vendor, you must have an equivalent of the trade value in
            your default wallet before you will be allowed to accept new trade
            requests.
          </p>

          <iframe
            width="100%"
            height={screenWidth > 550 ? "400" : "300"}
            src="https://www.youtube.com/embed/V0GnphFnkuE?si=ZXZEjT64ucGPqQXI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ marginTop: "15px" }}
          ></iframe>

          <h2 className="academy-title">
            lesson 4 - how to set your default wallet
          </h2>

          <p className="academy-text">
            we describe and explain what a default wallet means and how to set
            your default wallet as a vendor/merchant.
          </p>

          <iframe
            width="100%"
            height={screenWidth > 550 ? "400" : "300"}
            src="https://www.youtube.com/embed/qrBg3QhIpEM?si=U0xiX6HzTEbmLjL7"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ marginTop: "15px" }}
          ></iframe>

          <h2 className="academy-title">
            lesson 5 - how to trade - as a dealer and a vendor
          </h2>

          <p className="academy-text">
            in this video, we describe how to initiate a new trade chat with a
            vendor on lily. we also walk you through the entire trading flow
            process from stage 1 to stage 2, explaining concepts like the trade
            chat timer, escrow and trade stages.
          </p>

          <iframe
            width="100%"
            height={screenWidth > 550 ? "400" : "300"}
            src="https://www.youtube.com/embed/4Q4LpG09x6E?si=maQCGOCcJIRzfyeM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ marginTop: "15px" }}
          ></iframe>

          {/* <h2 className="academy-title">lesson 5 - reviews and reputation</h2>

        <p className="academy-text">
          in this video, we describe how to drop reviews after every trade chat,
          we also explain how the reputation system works on lily (by deriving a
          reputation score for each user) and explains how reviews and trading
          activity can influence your reputation score.
        </p>

        <iframe
          width="100%"
          height={screenWidth > 550 ? "400" : "300"}
          src="https://www.youtube.com/embed/hf1mVQOOzgo?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{ marginTop: "15px" }}
        ></iframe> */}

          {/* <h2 className="academy-title">lesson 6 - dispute and resolution</h2>

        <p className="academy-text">
          in this video, we describe what happens in the event when there is a
          dispute or on issue during the trade. various issues can occur in
          peer-to-peer trading of money which may include network issues during
          transfer, one party claiming to have or have not received said funds,
          fraud attempts, etc. we walk you through how to raise disputes when
          timers elapses, how a moderator joins the discussion, what a mod will
          require from you, and how the dispute resolution process is typically
          handled from start to finish.
        </p>

        <iframe
          width="100%"
          height={screenWidth > 550 ? "400" : "300"}
          src="https://www.youtube.com/embed/hf1mVQOOzgo?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{ marginTop: "15px" }}
        ></iframe> */}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Academy;
