import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Form, Input, Button, message, Checkbox, Select, Space } from "antd";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import randomWords from "random-words";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { setJoinCommunity } from "../../redux/joinCommunity/joinCommunity.actions";
import "./style-Auth.scss";
import {
  bearerInstanceWithToken,
  instance /* bearerInstance */,
} from "./../../utils/API";
import { setUserData } from "./../../redux/user/user.actions";
import SEO from "../../components/SEO/seo";

export default function Register() {
  const [hasReferral, setHasReferral] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const [instrumentOptions, setInstrumentOptions] = useState([]);
  const [response, setResponse] = useState(null);
  const [locationInput, setLocationInput] = useState("");
  const [isVendor, setIsVendor] = useState(false);
  const [instrumentData, setInstrumentData] = useState([]);
  const [pairs, setPairs] = useState(["usdt to bank account"]);
  const [token, setToken] = useState("");
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { TextArea } = Input;
  const userState = useSelector((state) => state.user);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const source = query.get("source");

  useEffect(() => {
    const handleCheckJoinCommunity = () => {
      if (source && source.toLowerCase() === "x") {
        dispatch(setJoinCommunity(true));
      } else {
        dispatch(setJoinCommunity(false));
      }
    };

    handleCheckJoinCommunity();
  }, []);

  useEffect(() => {
    if (userState?.userData) {
      // shouldRedirect === true && history.replace("/");
    }
  }, [history, userState?.userData]);

  // useEffect(() => {
  //   bearerInstance
  //     .get('/check_token')
  //     .then(res => {
  //       console.log(res.data);
  //       if (res.data.message === 'token valid') history.replace('/');
  //     })
  //     .catch(err => {
  //       console.log('not authenticated');
  //     });
  // }, [history]);

  const handleSelectInstruments = (arr) => {
    if (!instrumentData) {
      return false;
    } else {
      arr.forEach((item) => {
        let instrument = `${item[0]} to ${item[1]}`;
        setInstrumentOptions((prev) => {
          return [...prev, { label: instrument, value: instrument }];
        });
      });
    }
  };

  const fetchInstrumentPairs = () => {
    instance
      .get(`/seo?fetch_instrument_pairs=1`)
      .then(function (response) {
        setInstrumentData(response.data);
        // handleSelectInstruments(response.data);
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    fetchInstrumentPairs();
  }, []);

  useEffect(() => {
    handleSelectInstruments(instrumentData);
  }, [instrumentData]);

  const handleChange = (value) => {
    return value;
  };

  const handleInputChange = (value) => {
    setInput(value);
  };

  const onFinish = (values) => {
    dispatch(setJoinCommunity(true));
    if (locationInput === "") {
      return message.warning("enter location to continue");
    }

    let userData = {};
    setButtonLoading(true);

    const { username, email, password, referrer, fullname, website, bio } =
      values;
    const randomUsername = randomWords({ exactly: 2, join: "" });

    const data = new FormData();
    data.append("user_email", email);
    data.append("user_password", password);
    data.append("user_name", username.toLowerCase());
    data.append("full_name", fullname);
    data.append("user_name_front", randomUsername);
    data.append("user_location", locationInput);
    data.append("website", website);
    data.append("bio", bio);
    data.append("pairs", pairs);
    data.append("user_whatsapp_no", input);
    data.append("referrer", referrer ? referrer : "");

    instance
      .post("/register", data)
      .then(function (response) {
        if (response?.data?.status) {
          userData = { ...response?.data?.data, token: response?.data?.token };
          setToken(response?.data?.token);
          dispatch(setUserData(userData));

          requestVerificationCode(
            response?.data?.data?.email,
            response?.data?.data?.user_name,
            response?.data?.token
          );
        } else {
          message.error(response?.data?.message);
          setButtonLoading(false);
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        }
        setButtonLoading(false);
      });
  };

  const requestVerificationCode = (email, username, token) => {
    const data = new FormData();
    data.append("send_verification", 1);
    data.append("verify_email", email);
    data.append("verify_username", username);

    setButtonLoading(true);

    bearerInstanceWithToken(userState?.userData?.token || token)
      .post("/register", data)
      .then(function (response) {
        if (response?.data?.status) {
          message.success(response?.data?.message);
          setButtonLoading(false);
          history.push("/verify-email");
        } else {
          message.error(response?.data?.message);
          setButtonLoading(false);
        }
      })
      .catch(function (error) {
        setButtonLoading(false);
      });
  };

  const handleLocationChange = (data) => {
    setLocationInput(data?.label);
  };

  // useEffect(() => {
  //   if (response !== null) {
  //     requestVerificationCode(
  //       response?.data?.data?.email,
  //       response?.data?.data?.user_name
  //     );
  //   }
  // }, [response, userState]);

  const validateURL = (_, value) => {
    if (!value) {
      return Promise.resolve(); // Empty value is valid
    }

    // Regular expression to validate URL with or without "www" prefix
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[^ "]+$/;

    if (urlPattern.test(value)) {
      return Promise.resolve(); // Valid URL
    }

    return Promise.reject("Please enter a valid URL"); // Invalid URL
  };

  const onChangeCheckbox = (e) => {
    setIsVendor(e.target.checked);
  };

  return (
    <>
      <SEO
        title="Create An Account | Lily Africa"
        description="Gain access to a worldwide network of reliable merchants with a Lily account. Sign up today and discover the essence of seamless, borderless finance at scale."
      />

      <div className="form-container">
        <div className="form-wrapper">
          <div className="form">
            <div className="app-name">
              <Link to="/">lily.africa</Link>
              <div className="bottom">convert money effortlessly</div>
            </div>
            <div className="title">create an account</div>
            <div className="desc">
              <p className="desc-text">
                on lily, you can convert money from one form to another with
                ease.
              </p>

              <p className="desc-text">
                whether it's transforming CashApp funds into giftcards or
                swapping PayPal balances for money in your bank account, it's
                all possible on our seamless, community-driven exchange.
              </p>

              <p className="desc-text-2">join our community for more info.</p>
              <a
                className="info-link"
                href="https://chat.whatsapp.com/Jwe5yYUfJ4hB9wxxtQbdp6"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://chat.whatsapp.com/Jwe5yYUfJ4hB9wxxtQbdp6
              </a>

              {/* <span className="desc-link">over 100 methods.</span> */}
            </div>

            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              {/* <Form.Item
                style={{ marginBottom: "20px" }}
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "fullname or brandname required!",
                  },

                  {
                    pattern: /^[a-zA-Z0-9 ]+$/,
                    message: 'Username cannot contain special characters!',
                  },
                ]}
              >
                <Input placeholder="full name, nickname or brand name" />
              </Form.Item> */}

              <Form.Item
                style={{ marginBottom: "20px" }}
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "full name or brand name required!",
                  },
                  {
                    pattern: /^[a-zA-ZÀ-ÿ0-9' -]+$/,
                    message:
                      "full name can only contain letters, numbers, spaces, hyphens, and apostrophes!",
                  },
                  {
                    pattern: /^.{1,50}$/,
                    message: "full name must be between 1 and 50 characters!",
                  },
                  {
                    pattern: /^(?!.*\s{2,}).*$/,
                    message: "full name cannot contain consecutive spaces!",
                  },
                ]}
              >
                <Input placeholder="full name, nickname or brand name" />
              </Form.Item>

              {/* <Form.Item
                style={{ marginBottom: "20px" }}
                name="username"
                rules={[{ required: true, message: "username required!" }]}
              >
                <Input placeholder="username" type="text" />
              </Form.Item> */}

              <Form.Item
                style={{ marginBottom: "20px" }}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "username required!",
                  },
                  {
                    pattern: /^[a-zA-Z0-9._-]{3,20}$/,
                    message:
                      "username must be between 3 and 20 characters and can contain letters, numbers, underscores, periods, and hyphens.",
                  },
                  {
                    pattern: /^(?!.*[._-]$).*$/,
                    message:
                      "username cannot start or end with a period, underscore, or hyphen.",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        ["admin", "support", "root"].includes(
                          value?.toLowerCase()
                        )
                      ) {
                        return Promise.reject(
                          "username cannot be 'admin', 'support', or 'root'."
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="username" type="text" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "20px" }}
                name="email"
                rules={[{ required: true, message: "email required!" }]}
              >
                <Input placeholder="email address" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "20px" }}
                name="password"
                hasFeedback
                rules={[
                  { required: true, message: "password required!" },
                  { min: 6, message: "minimum: 6 characters." },
                ]}
              >
                <Input.Password placeholder="create a password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("passwords do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="confirm password" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "20px" }}
                rules={[{ required: true, message: "location required!" }]}
              >
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyA3geMmLIiRA_J0zUzFerPTKwkT5-9ocPM"
                  apiOptions={{ language: "en", region: "us" }}
                  autocompletionRequest={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "ng" },
                  }}
                  selectProps={{
                    placeholder: "location e.g lagos, nigeria",
                    locationInput,
                    onChange: handleLocationChange,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    },
                  }}
                />
              </Form.Item>

              <div className="confirm-vendor">
                <Checkbox value={isVendor} onChange={onChangeCheckbox}>
                  <p className="vendor-text">are you a vendor?</p>
                </Checkbox>
              </div>

              {isVendor && (
                <>
                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    name="website"
                    rules={[
                      { required: false, message: "Please input your URL!" },
                      { validator: validateURL },
                    ]}
                  >
                    <Input placeholder="website (optional)" />
                  </Form.Item>

                  <PhoneInput
                    country={"ng"}
                    containerStyle={{ marginBottom: "20px", height: "37px" }}
                    inputStyle={{ height: "37px" }}
                    onChange={(phone) => {
                      handleInputChange(phone);
                    }}
                    placeholder="WhatsApp number"
                    disableCountryCode
                    // disabled={confirmOtp}
                  />

                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    name="bio"
                    hasFeedback
                    rules={[
                      { required: false, message: "bio required!" },
                      { min: 10, message: "Minimum 10 characters!" },
                      { max: 300, message: "Maximum 300 characters!" },
                    ]}
                  >
                    <TextArea
                      showCount
                      // rows={1}
                      name="bio"
                      maxLength={300}
                      // onChange={onChange}
                      placeholder="describe your services briefly, e.g. actively buying paypal, usdt and payonner funds."
                      style={{
                        height: 120,
                        resize: "none",
                      }}
                    />
                  </Form.Item>

                  {/* <div className="form-select" >
              
                  </div> */}

                  <span style={{ color: "#cacaca", fontSize: "15px" }}>
                    type to select deal pairs you offer
                  </span>
                  <Space
                    style={{
                      width: "100%",
                      marginTop: "5px",
                      marginBottom: "15px",
                    }}
                    direction="vertical"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="type to select deal pairs you offer"
                      defaultValue={["usdt to bank account"]}
                      onDropdownVisibleChange={(open) =>
                        setDropdownVisible(open)
                      }
                      open={dropdownVisible}
                      onChange={(e) => {
                        setPairs([...e]);
                        setDropdownVisible(false);
                      }}
                      options={instrumentOptions}
                    />
                  </Space>
                </>
              )}
              <div
                className="referral"
                onClick={() => {
                  setHasReferral(!hasReferral);
                }}
              >
                {!hasReferral ? "i have a referrer" : "no referrer"}
              </div>
              {hasReferral && (
                <Form.Item
                  style={{ marginBottom: "25px" }}
                  name="referrer"
                  rules={[{ required: true, message: "referrer required!" }]}
                >
                  <Input placeholder="enter referrer username" />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={buttonLoading}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>

            <div className="already">
              already on lily?
              <span>
                <Link to="/login"> login</Link>
              </span>
            </div>

            <div className="already">
              by continuing, you agree to lily’s
              <span>
                <Link to="/terms"> Terms of Service</Link>
              </span>{" "}
              and
              <span>
                <Link to="/privacy-policy"> Privacy Policy</Link>
              </span>{" "}
              {/* and
            <span>
              <Link to="/"> Legal Notice</Link>
            </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
