// export const formatDecimalNumber = (num) => {
//   let convert = Number(num);
//   return parseFloat(convert).toLocaleString("en-US");
// };


export const formatDecimalNumber = (num) => {
  let convert = Number(num);
  
  // Convert the number to string without fixed decimal places
  let [integerPart, decimalPart] = convert.toString().split(".");

  // Format the integer part with commas
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted number
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};
