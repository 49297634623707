import React, { useState, useEffect } from "react";
import "./Review.scss";
import { Breadcrumb, Button, message, Modal } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  HomeOutlined,
  DislikeOutlined,
  LikeOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { bearerInstanceWithToken, instance } from "../../utils/API";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

const Review = () => {
  const [getReviewData, setReviewData] = useState(null);
  const [rating, setRating] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  const [isReviewed, setIsReviewed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [reviewText, setReviewText] = useState("");

  const user = useSelector((state) => state?.user?.userData);
  const { revData } = useSelector((state) => state.data);

  const history = useHistory();

  const handlePostReview = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("discussion_id", revData.discussion_id);
    formData.append("deal_id", revData.deal_id);
    formData.append("rating", rating);
    formData.append("review_body", reviewText);
    formData.append("post_review", 1);
    bearerInstanceWithToken(user.token)
      .post(`/reviews`, formData)
      .then((res) => {
        setReviewModal(true);
        setIsReviewed(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleEditReview = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("discussion_id", revData.discussion_id);
    formData.append("edit_value", 0);
    formData.append("edit_type", "rating");
    formData.append("edit_review", 1);
    bearerInstanceWithToken(user.token)
      .post(`/reviews`, formData)
      .then((res) => {
        setReviewModal(true);
        setIsReviewed(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err?.response?.data?.raw_message)
      });
  };

  const handleSetRating = (rate) => {
    setRating(rate);
  };

  const handleSubmitReview = () => {
    if (rating !== 0 && rating !== 1) {
      message.error("please rate the discussion");
    } else if (!reviewText) {
      message.error("please leave a review note");
    } else {
      if (isEdit === true) {
        handleEditReview();
      } else {
        handlePostReview();
      }
    }
  };


  useEffect(() => {
    const fetchReview = async () => {
      try {
        const { data } = await bearerInstanceWithToken(user.token).get(
          `/reviews?deal_id=${revData.deal_id}&get_reviews_for_deal=1`
        );

        const res = data?.reviews_for_deal?.filter((item) => {
          return item?.user_name === user?.user_name;
        });

  

        setReviewData(res);
        setReviewText(res[0]?.review_body);
        setRating(res[0]?.rating);
        if (res?.length > 0) {
          setIsEdit(true);
        }else{
          setIsEdit(false)
        }
      } catch (err) {}
    };

    fetchReview();
  }, [revData]);

  return (
    <div className="msg">
      <div className="msg-wrapper parent">
        <Breadcrumb style={{ display: "flex" }}>
          <Breadcrumb.Item>
            <Link to="/reviews">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>reviews</Breadcrumb.Item>
        </Breadcrumb>

        <Modal
          visible={reviewModal}
          title={`review successful`}
          cancelText={<p className="cancel-text">.</p>}
          onOk={() => {
            setReviewModal(false);
            history.push("/chat");
          }}
        >
          <p>you have successfully dropped a review.</p>
        </Modal>

        <div className="rev-content">
          <p className="review-title">
            {isEdit ? "update review" : "review trade"}
          </p>

          <div className="cont-box">
            <div className="review-btn-box">
              <Button
                icon={<LikeOutlined />}
                // color="success"
                style={{
                  fontSize: "16px",
                  backgroundColor: `${rating === 1 ? "#14a014" : "white"}`,
                  color: `${rating === 1 ? "white" : "#14a014"}`,
                  padding: "3px 5px",
                  width: "48%",
                  height: "40px",
                  textAlign: "center",
                  border: "1px solid #14a014",
                }}
                onClick={() => handleSetRating(1)}
              >
                positive
              </Button>

              <Button
                icon={<DislikeOutlined />}
                // color="success"
                style={{
                  fontSize: "16px",
                  backgroundColor: `${rating === 0 ? "#fb4570" : "white"}`,
                  color: `${rating === 0 ? "white" : "#fb4570"}`,
                  padding: "3px 5px",
                  width: "48%",
                  height: "40px",
                  textAlign: "center",
                  border: "1px solid #fb4570",
                }}
                onClick={() => handleSetRating(0)}
              >
                negative
              </Button>
            </div>

            <textarea
              rows={8}
              className="input"
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              placeholder="how did the trade go? a honest feedback will help other dealers on this marketplace to make informed decisions..."
            />

            <div>
              <Button
                icon={<LikeOutlined />}
                // color="success"
                style={{
                  fontSize: "16px",
                  backgroundColor: "#fb4570",
                  color: "white",
                  padding: "3px 5px",
                  width: "100%",
                  height: "40px",
                  textAlign: "center",
                  border: "1px solid #fb4570",
                }}
                onClick={handleSubmitReview}
                loading={loading}
              >
                {isEdit ? "update review" : "post review"}
              </Button>

              {/* <Button
                // color="success"
                style={{
                  fontSize: "16px",
                  backgroundColor: "white",
                  color: "#fb4570",
                  padding: "3px 5px",
                  width: "100%",
                  height: "40px",
                  textAlign: "center",
                  border: "1px solid #fb4570",
                  marginTop: 10,
                }}
                disabled={isReviewed}
                onClick={() => history.push("/chat")}
              >
                trade list <RightOutlined />
              </Button> */}

              <Button
                // color="success"
                style={{
                  fontSize: "16px",
                  backgroundColor: "white",
                  color: "#fb4570",
                  padding: "3px 5px",
                  width: "100%",
                  height: "40px",
                  textAlign: "center",
                  border: "1px solid #fb4570",
                  marginTop: 10,
                }}
                disabled={isReviewed}
                onClick={() => history.push(`/chat/${revData.discussion_ids}`)}
              >
                view this trade <RightOutlined />
              </Button>
            </div>
          </div>
          <div className="review-wrap" style={{ marginBottom: "15px" }}>
            <ExclamationCircleOutlined
              style={{ color: "#14a014", marginTop: "13px" }}
            />

            <div className="completed-action">
              <p className="list-text">
                reviews are important. reviews help other dealers on our
                marketplace to make informed decisions when trying to initiate
                new trade. our marketplace is built on trust. although a few bad
                eggs exist, the majority of our merchants are great and trusted
                traders.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
