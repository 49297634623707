import "./WalletModal.scss";
import { Modal, Alert, Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { bearerInstance } from "../../utils/API";
import { LoadingOutlined } from "@ant-design/icons";
// import { useSelector } from 'react-redux';

const WalletModal = ({
  send,
  close,
  open,
  sent,
  btcPrice,
  curBal,
  activeAddress,
  handleReload,
  coin,
  fetchData,
}) => {
  const [proceed, setProceed] = useState(false);
  const [addLoad, setAddLoad] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [rcAdd, setRcAdd] = useState("");
  const [copy, setCopy] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [initLoad, setInitLoad] = useState(false);
  const [initErr, setInitErr] = useState(false);
  const [getNewAddLoader, setGetNewAddLoader] = useState(false);

  const [otpResend, setOtpResend] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [sendLoad, setSendLoad] = useState(false);

  const [amountValidate, setAmountValidate] = useState({
    validateStatus: "success",
    errorMsg: null,
  });

  const Network = "MAINNET";

  useEffect(() => {
    if (proceed)
      setTimeout(() => {
        setOtpView(true);
      }, 10000);
  }, [proceed]);

  // const { wallet_name } = useSelector(state => state.user.userData);

  const handleGetNewAddres = () => {
    setGetNewAddLoader(true);

    setTimeout(() => {
      setGetNewAddLoader(false);
    }, 3000);
  };

  const getAddress = () => {
    if (!send) {
      setAddLoad(true);
      // console.log(wallet_name);

      var data = new FormData();
      data.append("create_address", "1");
      data.append("network", `${Network}`);
      data.append("chain", `${coin}`);

      bearerInstance
        .post(`/wallet_block`, data)
        .then((res) => {
          setAddress(res?.data?.message);
          fetchData();
          setAddLoad(false);
        })
        .catch((err) => {
          message.error(err.response?.data?.message);
          setAddLoad(false);
          // console.log('something went wrong');
        });
    }
  };

  // useEffect(() => {
  //   getAddress();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const sendBtc = (values) => {
    // console.log(JSON.stringify(transactionData));
    setSendLoad(true);

    const txData = {
      tx_type: transactionData?.message?.type,
      tx_sender_wallet_id: transactionData?.message?.sender_wallet_id,
      tx_receiver_wallet_id: transactionData?.message?.receiver_wallet_id,
      tx_amount: transactionData?.message?.amount,
      tx_amount_usd: transactionData?.message?.amount_usd,
      tx_receiver: transactionData?.message?.receiver,
      tx_estimated_fee: transactionData?.message?.estimated_fee,
      tx_estimated_fee_with_commission:
        transactionData?.message.estimated_fee_with_commissio,
      tx_estimated_fee_usd: transactionData?.message?.estimated_fee_usd,
      tx_final_amount: transactionData?.message.final_amount,
      tx_final_amount_with_commission_usd:
        transactionData?.message.final_amount_with_commission_usd,
      tx_final_amount_with_commission:
        transactionData?.message.final_amount_with_commission,
      tx_commission: transactionData?.message.commission,
    };

    var data = new FormData();
    data.append("send", "1");
    data.append("otp_to_verify", values.otp);
    data.append("network", `${Network}`);
    data.append("chain", `${coin}`);
    data.append("tx", `${JSON.stringify(txData)}`);

    bearerInstance
      .post("/wallet_block", data)
      .then((res) => {
        handleReload();
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1500);

        sent({
          type: "success",
          message: `${
            coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"
          } sent!`,
          description: `${res.data?.message}`,
        });
        close();
      })
      .catch((err) => {
        // console.log(err);
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setSendLoad(false);
      });
  };

  const sendOtp = () => {
    setOtpResend(true);
    const data = new FormData();
    data.append("send_otp", "1");
    data.append("network", `${Network}`);
    data.append("chain", `${coin}`);

    bearerInstance
      .post("/wallet_block", data)
      .then((res) => {
        message.success("opt has been resent");
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setOtpResend(false);
      });
  };

  const initializeTransaction = (values) => {
    if (values.btc_amount === undefined)
      return setAmountValidate({
        validateStatus: "error",
        errorMsg: `input a valid amount in ${
          coin.toLowerCase() === "btc" ? "BTC" : "LTC"
        }!`,
      });

    if (+values.btc_amount <= 0) return setInitErr(true);

    setInitLoad(true);
    setInitErr(false);

    const data = new FormData();
    data.append("build", "1");
    data.append("receiver", values.btc_address);
    data.append("amount", values.btc_amount);
    data.append("network", `${Network}`);
    data.append("chain", `${coin}`);

    bearerInstance
      .post("/wallet_block", data)
      .then((res) => {
        setTransactionData(res.data);
        // console.log(res.data.tx);
        setProceed(true);
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.message?.data?.error_message ||
            err?.response?.data?.message
        );
      })
      .finally(() => {
        setInitLoad(false);
      });
  };

  const validateAmount = (number) => {
    if (+number < curBal && +number > 0) {
      return {
        validateStatus: "success",
        errorMsg: null,
      };
    }

    if (+number <= 0 || number === "")
      return {
        validateStatus: "error",
        errorMsg: `Input a valid amount in ${
          coin.toLowerCase() === "btc" ? "BTC" : "LTC"
        }!`,
      };

    return {
      validateStatus: "error",
      errorMsg: "you don’t have enough coins",
    };
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text).then((res) => {
        message.success("address copied");
      });
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  return (
    <Modal onCancel={close} visible={open} className="walletModal">
      <div className="walletModal-box">
        {send ? (
          <div className="walletModal-send walletModal-box-left">
            <h3>
              send {coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"}
            </h3>

            {proceed ? (
              <>
                <Alert
                  message="info"
                  description={
                    transactionData?.message?.type === "internal"
                      ? "you are about to send coins to another lily user. zero fees apply"
                      : "you are sending coins to an address that exists outside the lily network. fees apply"
                  }
                  type="info"
                  style={{ marginBottom: "20px" }}
                  closable
                  showIcon
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    fontWeight: "600",
                    marginTop: "5px",
                  }}
                >
                  <h4 style={{ margin: "0", flex: "0 0 40%" }}>send amount</h4>

                  <h4 style={{ margin: "0" }}>
                    {Number(transactionData?.message?.final_amount).toFixed(6)}{" "}
                    {coin.toLowerCase() === "btc" ? "BTC" : "LTC"}
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      (≈{" "}
                      {Number(transactionData?.message?.amount_usd).toFixed(3)}{" "}
                      USD)
                    </span>
                  </h4>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    fontWeight: "600",
                  }}
                >
                  <h4 style={{ margin: "0", flex: "0 0 40%" }}>network fee</h4>

                  <h4 style={{ margin: "0" }}>
                    {Number(transactionData?.message?.estimated_fee).toFixed(6)}{" "}
                    {coin.toLowerCase() === "btc" ? "BTC" : "LTC"}
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      (≈{" "}
                      {Number(
                        transactionData?.message?.estimated_fee_usd
                      ).toFixed(3)}{" "}
                      USD)
                    </span>
                  </h4>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    fontWeight: "600",
                  }}
                >
                  <h4 style={{ margin: "0", flex: "0 0 40%" }}>
                    withdrawal fee
                  </h4>

                  <h4 style={{ margin: "0" }}>
                    {Number(transactionData?.message?.commission).toFixed(6)}{" "}
                    {coin.toLowerCase() === "btc" ? "BTC" : "LTC"}
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      (≈{" "}
                      {Number(transactionData?.message?.commission_usd).toFixed(
                        3
                      )}{" "}
                      USD)
                    </span>
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    fontWeight: "600",
                  }}
                >
                  {/* <h4 style={{ margin: "0", flex: "0 0 40%" }}>description</h4> */}

                  <h4
                    style={{
                      // fontSize: '12px',
                      // fontWeight: '400',
                      margin: "0",
                    }}
                  >
                    {/* {transactionData.description} */}
                  </h4>
                </div>

                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "13px",
                    marginTop: "20px",
                  }}
                >
                  {Number(transactionData?.message?.amount).toFixed(6)}{" "}
                  {coin.toLowerCase() === "btc" ? "BTC" : "LTC"} (
                  {Number(transactionData?.message?.amount_usd).toFixed(3)} USD)
                  will be sent to:
                </p>
                <h4
                  className="walletModal-p"
                  style={{
                    fontWeight: 700,
                    marginBottom: "20px",
                  }}
                >
                  {rcAdd}
                </h4>

                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  total deduction will be:
                </p>
                <p
                  style={{
                    color: "#14a014",
                    fontWeight: 700,
                    fontSize: "20px",
                    marginBottom: "0",
                  }}
                >
                  {Number(
                    parseFloat(
                      transactionData?.message?.final_amount_with_commission
                    ).toFixed(6)
                  )}{" "}
                  {coin}
                </p>
                <span
                  style={{
                    marginBottom: "20px",
                    marginTop: "-2px",
                    display: "block",
                    fontSize: "12px",
                  }}
                >
                  approx{" "}
                  {Number(
                    parseFloat(
                      transactionData?.message?.final_amount_with_commission_usd
                    )
                  ).toFixed(3)}{" "}
                  USD
                </span>

                <Alert
                  message="warning"
                  description={`${
                    coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"
                  } transactions are final! please cross check your inputs before you proceed!`}
                  type="warning"
                  style={{ marginBottom: "20px" }}
                  closable
                  showIcon
                />

                <Form onFinish={sendBtc}>
                  <Form.Item
                    name="otp"
                    label="enter OTP"
                    style={{
                      display: "inline-block",
                      width: "calc(100%)",
                      marginBottom: "0",
                      fontSize: "13px",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "please enter your otp!",
                      },
                      {
                        message: "otp must be 6 numbers",
                        len: 6,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      style={{
                        width: "100%",
                        paddingTop: "0",
                        paddingBottom: "0",
                      }}
                      placeholder="click send first..."
                    />
                  </Form.Item>

                  {otpView && (
                    <Button
                      onClick={sendOtp}
                      type="text"
                      loading={otpResend}
                      style={{
                        color: "#ed1450",
                        padding: "0",
                        height: "unset",
                        display: "block",
                        marginLeft: "auto",
                        fontSize: "13px",
                      }}
                    >
                      resend OTP by SMS
                    </Button>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "22px",
                    }}
                  >
                    <Button
                      onClick={() => setProceed(false)}
                      type="text"
                      style={{ color: "#ed1450", fontSize: "16px" }}
                    >
                      back
                    </Button>

                    <Button
                      type="primary"
                      loading={sendLoad}
                      style={{
                        marginLeft: "auto",
                        display: "block",
                        fontSize: "16px",
                        padding: "2px 15px 10px 15px",
                      }}
                      htmlType="submit"
                    >
                      continue
                    </Button>
                  </div>
                </Form>
              </>
            ) : (
              <>
                <h4>
                  available{" "}
                  <span style={{ color: "#ed1450", fontWeight: 600 }}>
                    {curBal}
                    {curBal === 0 && ".00"}
                  </span>{" "}
                  {coin.toLowerCase() === "btc" ? "BTC" : "LTC"}
                </h4>

                <Form onFinish={initializeTransaction}>
                  {initErr && (
                    <Alert
                      type="error"
                      showIcon
                      message="error"
                      description="enter a value greater than zero"
                      closable
                      style={{ marginBottom: "10px" }}
                    />
                  )}

                  <Form.Item
                    name="btc_amount"
                    label={`${
                      coin.toLowerCase() === "btc" ? "BTC" : "LTC"
                    } amount`}
                    style={{
                      display: "inline-block",
                      width: "calc(100%)",
                    }}
                    // validateStatus={amountValidate.validateStatus}
                    // help={amountValidate.errorMsg}
                    rules={[
                      {
                        required: true,
                        // message:
                        //   "Please enter a valid amount greater than zero",
                        validator: (_, value) => {
                          if (+value <= 0 || value === "") {
                            return Promise.reject(
                              `Input a valid amount in ${
                                coin.toLowerCase() === "btc" ? "BTC" : "LTC"
                              }!`
                            );
                          } else if (+value > curBal) {
                            return Promise.reject(
                              "You don't have enough coins"
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      style={{
                        width: "100%",
                        paddingTop: "0",
                        paddingBottom: "0",
                      }}
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setAmountValidate(validateAmount(e.target.value));
                      }}
                      placeholder="0.00000"
                      suffix={
                        <span style={{ fontSize: "12px", color: "#999" }}>
                          ~ {(btcPrice * amount).toFixed(6)} USD
                        </span>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="btc_address"
                    label="to address"
                    style={{
                      display: "inline-block",
                      width: "calc(100%)",
                      marginBottom: "10px",
                    }}
                    rules={[
                      {
                        required: true,
                        message: `enter a valid ${coin} address`,
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      style={{
                        width: "100%",
                        paddingTop: "0",
                        paddingBottom: "0",
                      }}
                      value={rcAdd}
                      onChange={(e) => setRcAdd(e.target.value)}
                      autoComplete="off"
                      placeholder={`enter ${
                        coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"
                      } address`}
                    />
                  </Form.Item>
                  <p style={{ marginBottom: "0px", fontSize: "13px" }}>
                    a {coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"}{" "}
                    address looks like this:
                  </p>
                  <p
                    className="walletModal-p"
                    style={{
                      marginBottom: "0px",
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    {coin.toLowerCase() === "btc"
                      ? "  3MBWkUkFSiWRNrpCxcTjnhT4rt23qk1wWz"
                      : "MGxNPPB7eBoWPUaprtX9v9CXJZoD2465zN"}{" "}
                    <span style={{ fontWeight: 400 }}>(sample only)</span>
                  </p>

                  <Button
                    type="primary"
                    style={{
                      marginLeft: "auto",
                      display: "block",
                      marginTop: "30px",
                      fontSize: "16px",
                      padding: "2px 15px 10px 15px",
                    }}
                    htmlType="submit"
                    loading={initLoad}
                  >
                    continue
                  </Button>
                </Form>
              </>
            )}
          </div>
        ) : (
          <div className="walletModal-recieve walletModal-box-left">
            <h3>
              receive {coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"}
            </h3>
            <div>
              you can deposit {coin.toLowerCase() === "btc" ? "BTC" : "LTC"}{" "}
              tokens into any of these addresses :{" "}
              {/* <span className="">
                {" "}
                {addLoad || getNewAddLoader === true ? (
                  <LoadingOutlined spin style={{ color: "#fb4570" }} />
                ) : (
                  ""
                )}
              </span> */}
              {addLoad ||
                (getNewAddLoader === false && (
                  <>
                    {activeAddress &&
                      activeAddress?.map((item) => {
                        return (
                          <div className="address-box">
                            <p className="address-textt">{item}</p>

                            <div
                              className="copy-text"
                              onClick={() => {
                                copyTextToClipboard(item);
                              }}
                            >
                              copy
                            </div>
                          </div>
                        );
                      })}
                  </>
                ))}
            </div>

            {/* <p className="walletModal-address">
              {addLoad || getNewAddLoader === true ? (
                <LoadingOutlined spin style={{ color: "#fb4570" }} />
              ) : (
                activeAddress
              )}
            </p> */}

            <div style={{ marginBottom: "20px" }}>
              {/* <Button
                type="primary"
                disabled={addLoad || getNewAddLoader}
                onClick={() => {
                  copyTextToClipboard(address)
                    .then(() => {
                      // If successful, update the isCopied state value
                      setCopy(true);
                    })
                    .catch((err) => {
                      // console.log(err);
                    });
                  // navigator?.clipboard?.writeText(activeAddress).then(() => {
                  //   setCopy(true);

                  //   setTimeout(() => {
                  //     setCopy(false);
                  //   }, 3000);
                  // });
                }}
              >
                {addLoad || getNewAddLoader ? (
                  <LoadingOutlined spin style={{ color: "#fff" }} />
                ) : (
                copy address
                 )} 
              </Button> */}

              <Button
                type="primary"
                style={{ color: "#fff", marginTop: "10px" }}
                onClick={getAddress}
                loading={addLoad}
                disabled={addLoad}
              >
                get new address
              </Button>
            </div>

            {copy && (
              <Alert
                message="success"
                description="copied!"
                type="success"
                style={{ marginBottom: "20px" }}
                closable
                showIcon
              />
            )}

            <Alert
              message="warning"
              description={
                <div>
                  <p className="alert-textt">
                    make sure you only send{" "}
                    {coin.toLowerCase() === "btc" ? "BTC" : "LTC"} tokens on the{" "}
                    {coin.toLowerCase() === "btc" ? "Bitcoin" : "Litecoin"}{" "}
                    network to avoid fund loss!
                  </p>
                  <p className="alert-textt">
                    if you have sent{" "}
                    {coin.toLowerCase() === "btc" ? "BTC" : "LTC"} to this
                    address,<span className="alert-textt-bold" > you may need to wait for a few minutes</span>{" "}
                    for it to reflect in your balance.
                  </p>
                </div>
              }
              type="warning"
              style={{ marginBottom: "20px" }}
              closable
              showIcon
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WalletModal;
