import React from "react";
import "./discussionitem.scss";
import {
  ArrowRightOutlined,
  DatabaseFilled,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { curType } from "../../utils/datasource";
import { Tag, Button, message } from "antd";
import { bearerInstance } from "../../utils/API";
import { formatDecimalNumber } from "../../utils/formatDecimalNumber";
import { formatTradeSource } from "../../utils/formatSource";

const ProfileDiscussionItem = ({ data }) => {
  const history = useHistory();
  const user = useSelector((state) => state?.user?.userData);

  return (
    <div
      className="discussion-item-container"
      onClick={() =>
        history.push({ pathname: `/chat/${data?.id}`, state: data })
      }
    >
      <div className="top">
        <div className="top-item">
          <div className="username-box">
            <span className="username-green">
              {/* <span className="with-indicator">with </span> */}
              {user.id === data.dealer
                ? data.merchant_data[0]?.user_name
                : data.dealer_data[0]?.user_name}
            </span>
            {data?.deal_info[0]?.source ? (
              <div className="source-destination">
                {formatTradeSource(data?.deal_info[0]?.source)}
                <ArrowRightOutlined
                  style={{
                    strokeWidth: "50",
                    stroke: "#14a014",
                  }}
                />{" "}
                {formatTradeSource(data.deal_info[0]?.destination)}
              </div>
            ) : (
              <div />
            )}
          </div>

          {/* disable online status on /chats page */}
          {!window.location.pathname === "/chat" && (
            <span
              className="status"
              style={{ color: 1 === 1 ? "#14a014" : "#dedede" }}
            >
              {1 === 1 ? "online" : "away"}
            </span>
          )}
        </div>

        {data?.status === "progress" && (
          <Tag className="chat-tag" color="blue" style={{ height: "26px" }}>
            in progress
          </Tag>
        )}
        {data?.status === "canceled" && (
          <Tag className="chat-tag" color="red">
            canceled
          </Tag>
        )}
        {data?.status === "completed" && (
          <Tag color="green" className="chat-tag">
            completed
          </Tag>
        )}
        {data?.status === "dispute" && (
          <Tag color="orange" className="chat-tag">
            {`dispute `}{" "}
            {data.dispute_data[0]?.status === "resolved" && (
              <span>{` - ${"  "} resolved`}</span>
            )}
          </Tag>
        )}
      </div>

      <div className="bottom">
        <span className="info">
          min{" "}
          <span className="bold">
            {formatDecimalNumber(data.deal_info[0]?.min)}
          </span>
          <EllipsisOutlined /> max
          <span className="bold">
            {" "}
            {formatDecimalNumber(data.deal_info[0]?.max)}
          </span>
          <EllipsisOutlined /> rate
          <span className="rate-text">
            {data.deal_info[0]?.rate_structure === "percentage"
              ? ""
              : curType(data.deal_info[0]?.destination_currency)}
            {formatDecimalNumber(data.deal_info[0]?.rate)}
            {data.deal_info[0]?.rate_structure === "percentage"
              ? "%"
              : `/${curType(data.deal_info[0]?.source_currency)}`}
          </span>
          <EllipsisOutlined />
          <span>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/deal/${data?.deal_id}`);
              }}
              type="text"
              style={{
                color: "#ed1450",
                padding: "0px 5px",
                fontSize: "13px",
              }}
              className="view-btn"
            >
              view deal
            </Button>
          </span>
        </span>
        <ArrowRightOutlined className="arrow-icon" color="#ed1450" />
      </div>
    </div>
  );
};

export const DisputeCardItem = ({ data, fullDetails }) => {

  const history = useHistory();
  const user = useSelector((state) => state?.user?.userData);

  const handleNewAssignment = async () => {
    history.push({
      pathname: `/admin-chat/${data?.discussion_id}`,
      state: { data: data, fullDetails: fullDetails },
    });
  };

  async function newAssignment() {
    if (fullDetails?.status === "resolved") {
      message.error("this dispute has been resolved and completed");
    } else if (
      user?.id === fullDetails?.dealer ||
      user?.id === fullDetails?.merchant
    ) {
      message.error("you cannot moderate a trade you are part of");
    } else if (!fullDetails?.assigned_to) {
      handleNewAssignment();
    } else if (fullDetails?.assigned_to === user?.id) {
      history.push({
        pathname: `/admin-chat/${data?.discussion_id}`,
        state: { data: data, fullDetails: fullDetails },
      });
    } else {
      message.error("dispute has been assigned to a moderator");
    }
  }

  return (
    <div className="discussion-item-container item-" onClick={newAssignment}>
      <div className="item-wrapper">
        <div>
          <div className="top">
            <div className="dispute-top-item">
              <span className="username-text">
                @{fullDetails?.merchant_user_name}{" "}
                <span className="with-text">with </span>@
                {fullDetails?.dealer_user_name}
              </span>{" "}
            </div>
          </div>

          <div className="bottom">
            <span className="info">
              min{" "}
              <span className="bold">
                {formatDecimalNumber(fullDetails?.deal_data[0]?.min)}
              </span>
              <EllipsisOutlined /> max{" "}
              <span className="bold">
                {formatDecimalNumber(fullDetails?.deal_data[0]?.max)}
              </span>
              <EllipsisOutlined /> rate
              <span className="rate-text">
                {/* {data.deal_info[0]?.rate_structure === "percentage"
              ? ""
              : curType(data.deal_info[0]?.destination_currency)} */}
                {/* {data.deal_info[0]?.rate}
            {data.deal_info[0]?.rate_structure === "percentage"
              ? "%"
              : `/${curType(data.deal_info[0]?.source_currency)}`} */}
                N{formatDecimalNumber(fullDetails?.deal_data[0]?.rate)} / $
              </span>
              <EllipsisOutlined />
              <span>
                <Button
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   history.push(`/deal/${data?.deal_id}`);
                  // }}
                  type="text"
                  style={{
                    color: "#ed1450",
                    padding: "0px 5px",
                    fontSize: "13px",
                  }}
                  className="view-btn"
                >
                  view deal
                </Button>
              </span>
            </span>
            <ArrowRightOutlined className="arrow-icon" color="#ed1450" />
          </div>
        </div>

        <div>
          {/* if discussion has been completed render the completed badge else render other badges */}
          <>
            {fullDetails?.status === "resolved" ? (
              <Tag color="green" className="chat-tag">
                resolved
              </Tag>
            ) : (
              <>
                {fullDetails?.assigned_to === user?.id ? (
                  <Tag color="green" className="chat-tag">
                    assigned to me
                  </Tag>
                ) : (
                  <>
                    {fullDetails?.status === "unassigned" ? (
                      <Tag className="dispute-tag" color="red">
                        <p className="tag-text">#{fullDetails?.status}</p>
                      </Tag>
                    ) : (
                      <Tag color="green" className="chat-tag">
                        #{fullDetails?.status}
                      </Tag>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ProfileDiscussionItem;
