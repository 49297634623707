import React, { useEffect, useState } from "react";
import { Tabs, message, Divider, Breadcrumb, Tag, Tooltip, Alert } from "antd";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  LikeOutlined,
  DislikeOutlined,
  EllipsisOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import Loader from "./../../components/Loader/Loader";

import ProfileDealItem from "./../../components/ProfileDealsItem/ProfileDealItem";
import ProfileReviewsItem from "../../components/ProfileReviewsItem/ProfileReviewItem";
import "./profile.scss";
import { instance } from "../../utils/API";
import { BsCheckCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { bearerInstance } from "../../utils/API";
import { ReactComponent as EmptyImage } from "./../../assets/empty.svg";
import SEO from "../../components/SEO/seo";
import { handleOpenUrl } from "../../utils/openUrl";

const { TabPane } = Tabs;

export default function OtherProfile({ match, location }) {
  const userState = useSelector((state) => state.user);
  const [dealsCount, setDealsCount] = useState(3);
  const [otherProfile, setOtherProfile] = useState(null);
  const [dealerReviews, setDealerReviews] = useState(null);
  const [merchantReviews, setMerchantReviews] = useState(null);
  const [reviewsCount, setReviewsCount] = useState(10);
  const [loadingViews, setLoadingViews] = useState(false);
  const { id } = useParams();
  // const { id } = useLocation().state;

  const handlePosteview = () => {
    setLoadingViews(true);

    const data = new FormData();
    data.append("user_name", otherProfile?.profile_data[0]?.user_name);

    instance
      .post(
        `/add_view?user_name=${otherProfile?.profile_data[0]?.user_name}`,
        data
      )
      .then((res) => {
        setLoadingViews(false);
      })
      .catch((err) => {
        setLoadingViews(false);
      });
  };

  useEffect(() => {
    const postReview = () => {
      if (otherProfile?.profile_data[0]?.user_name) {
        if (loadingViews) {
          return false;
        } else {
          handlePosteview();
        }
      }
    };

    postReview();
  }, [otherProfile]);

  const handleProfileReview = () => {
    bearerInstance
      .get(
        `/reviews?user_id=${
          location?.state?.id || otherProfile?.profile_data[0]?.id || id
        }&get_reviews_for_profile=${reviewsCount}`
      )
      .then((res) => {
        setDealerReviews(res.data.from_dealers);
        setMerchantReviews(res.data.from_merchants);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOtherProfile();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleProfileReview();
  }, [reviewsCount, otherProfile]);

  const getOtherProfile = async () => {
    instance
      .get(`/profile?user_name=${id}`)
      .then(function (response) {
        setOtherProfile(response?.data);
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        }
      });
  };

  let sortName =
    otherProfile?.profile_data[0]?.admin_level === 1
      ? "moderator"
      : otherProfile?.profile_data[0]?.admin_level === 2
      ? "super user"
      : "";

  const keyWordList = [];

  let keyword = !otherProfile?.keyword_word_summary
    ? []
    : otherProfile?.keyword_word_summary?.forEach((item) => {
        const capitalizedItem = item.charAt(0).toUpperCase() + item.slice(1);
        keyWordList.push("sell " + capitalizedItem);
        keyWordList.push("buy " + capitalizedItem);
        keyWordList.push("swap " + capitalizedItem);
      });

  function removeDuplicates(arr = []) {
    const uniqueStrings = new Set(); // Set to store unique strings
    const result = []; // Array to store unique strings

    // Iterate through the array
    for (let string of arr) {
      // If the string is not in the set, add it to the set and result array
      if (!uniqueStrings.has(string)) {
        result.push(string);
        uniqueStrings.add(string);
      }
    }

    return result;
  }

  function removeProtocolAndWWW(url) {
    const regex = /^(https?:\/\/)?(www\.)?/i;

    return url.replace(regex, "");
  }

  function extractBeforeAndAfterTo(input) {
    // Use regex to match the pattern
    const regex = /^(.*?)\s+to\s+(.*?)$/;
    const match = input.match(regex);

    if (match) {
      const beforeTo = match[1].trim();
      const afterTo = match[2].trim();
      return {
        beforeTo,
        afterTo,
      };
    } else {
      return null; // Return null if no match is found
    }
  }

  function replaceBankFund(str) {
    return str.replace(/bank fund/g, "bank account");
  }

  return (
    <div className="profile-container">
      <SEO
        title={`${otherProfile?.profile_data[0]?.full_name} | ${
          otherProfile?.deal_instruments_summary
            ? removeDuplicates(otherProfile?.deal_instruments_summary)?.map(
                (item) => {
                  return " " + item.charAt(0).toUpperCase() + item.slice(1);
                }
              )
            : "PayPal, cashapp, bank account, gift card"
        } trader on Lily Africa.`}
        description={`${otherProfile?.profile_data[0]?.full_name} is a ${
          otherProfile?.deal_instruments_summary
            ? removeDuplicates(otherProfile?.deal_instruments_summary)?.map(
                (item) => {
                  return " " + item.charAt(0).toUpperCase() + item.slice(1);
                }
              )
            : "PayPal, cashapp, bank account, gift card"
        }${" "} trader in ${
          otherProfile?.profile_data[0]?.user_location
        }. Buy, Sell & Swap ${
          otherProfile?.deal_instruments_summary
            ? removeDuplicates(otherProfile?.deal_instruments_summary)?.map(
                (item) => {
                  return " " + item.charAt(0).toUpperCase() + item.slice(1);
                }
              )
            : "PayPal, cashapp, bank account, gift card"
        }${" "} from them & other traders in Africa via over 100 different methods. Buy, Sell & Swap ${
          otherProfile?.deal_instruments_summary
            ? removeDuplicates(otherProfile?.deal_instruments_summary)?.map(
                (item) => {
                  return " " + item.charAt(0).toUpperCase() + item.slice(1);
                }
              )
            : "PayPal, cashapp, bank account, gift card"
        }.`}
        type=""
        name="Lily Africa"
        keyword={keyWordList?.map((item) => item)}
      />

      {!otherProfile && <Loader />}

      {otherProfile && (
        <div className="profile-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {otherProfile?.profile_data[0]?.user_name}
            </Breadcrumb.Item>
          </Breadcrumb>

          {otherProfile?.profile_data[0]?.account_status === 0 && (
            <Alert
              description={
                <>
                  <p className="notice-text">
                    this is a regular listing and this profile is currently not
                    active on Lily Africa. If you are the owner of this profile
                    or brand contact us to activate your account and start
                    trading. you can also{" "}
                    <Link to="/contact">
                      {" "}
                      <span
                        style={{
                          color: "#ed1450",
                          fontWeight: "600",
                          textDecoration: "underline",
                        }}
                      >
                        reach out
                      </span>
                    </Link>{" "}
                    to us if want your profile delisted.
                  </p>
                </>
              }
              type="info"
              style={{ marginBottom: "20px" }}
              closable
              showIcon
            />
          )}

          <div className="user-info">
            <div className="username">
              @{otherProfile?.profile_data[0]?.user_name}
              {otherProfile.profile_data[0]?.admin_level !== 0 && (
                <Tooltip placement="topRight" title={sortName}>
                  <BsCheckCircleFill
                    style={{
                      marginLeft: "5px",
                      fontSize: "15px",
                    }}
                    color={
                      otherProfile?.profile_data[0]?.admin_level === 1
                        ? "#78ea78"
                        : "orange"
                    }
                  />
                </Tooltip>
              )}
              {/* <Tag style={{ marginLeft: "5px", height: "18px" }}>
                <span className="user-id">
                  {otherProfile?.profile_data[0]?.user_name_front}
                </span>
              </Tag> */}
            </div>

            <div className="name-box">
              <span className="full_name">
                {otherProfile?.profile_data[0]?.full_name}
              </span>

              <span className="views-text">
                {otherProfile?.profile_data[0]?.views}{" "}
                {otherProfile?.profile_data[0]?.views > 1 ? "views" : "view"}
              </span>
            </div>

            <div className="tag-box">
              {otherProfile?.pairs &&
                otherProfile?.pairs?.map((item) => {
                  return (
                    <a
                      href={`http://lily.africa/results?search=${item} in ${
                        otherProfile?.profile_data[0]?.user_location
                          ? otherProfile?.profile_data[0]?.user_location
                          : "lagos"
                      }`}
                      className="tag-itself"
                    >
                      <Tooltip
                        placement={"topLeft"}
                        title={`search for ${item} deals in ${
                          otherProfile?.profile_data[0]?.user_location
                            ? otherProfile?.profile_data[0]?.user_location
                            : "lagos"
                        }`}
                      >
                        # {replaceBankFund(item)}
                      </Tooltip>
                    </a>
                  );
                })}
            </div>

            {otherProfile?.profile_data[0]?.website &&
              otherProfile?.profile_data[0]?.website !== "undefined" && (
                <div
                  className="web_site"
                  onClick={() => {
                    if (
                      !otherProfile?.profile_data[0]?.website ||
                      otherProfile?.profile_data[0]?.website === "undefined"
                    ) {
                      return false;
                    } else {
                      handleOpenUrl(otherProfile?.profile_data[0]?.website);
                    }
                  }}
                >
                  {removeProtocolAndWWW(otherProfile?.profile_data[0]?.website)}
                </div>
              )}

            {otherProfile?.profile_data[0]?.bio &&
              otherProfile?.profile_data[0]?.bio !== "undefined" && (
                <Divider
                  style={{ fontSize: "14px", color: "#999", marginTop: "10px" }}
                ></Divider>
              )}

            {otherProfile?.profile_data[0]?.bio &&
              otherProfile?.profile_data[0]?.bio !== "undefined" && (
                <p className="summary_text">summary</p>
              )}

            {otherProfile?.profile_data[0]?.bio &&
              otherProfile?.profile_data[0]?.bio !== "undefined" && (
                <span className="bio_text">
                  {otherProfile?.profile_data[0]?.bio}
                </span>
              )}

            {otherProfile?.profile_data[0]?.bio &&
              otherProfile?.profile_data[0]?.bio !== "undefined" && (
                <Divider
                  style={{ fontSize: "14px", color: "#999", marginTop: "10px" }}
                ></Divider>
              )}

            <div className="rate">
              profile score <span>{otherProfile?.reputation?.trust_score}</span>{" "}
              <EllipsisOutlined />
              trades completed{" "}
              <span>
                {otherProfile?.reputation?.trades_completed}{" "}
                {otherProfile?.reputation?.trades_completed > 1
                  ? "trades"
                  : "trade"}
              </span>{" "}
              <EllipsisOutlined /> disputed{" "}
              <span>{otherProfile?.reputation?.trades_disputed} </span>{" "}
              <EllipsisOutlined /> from{" "}
              <span>{otherProfile?.profile_data[0]?.user_location}</span>
            </div>

            <div className="like-dislike">
              <span className="like">
                <LikeOutlined />{" "}
                {otherProfile?.reputation?.positive_reviews_count}
              </span>
              <span className="dislike">
                <DislikeOutlined />{" "}
                {otherProfile?.reputation?.negative_reviews_count}
              </span>
            </div>
          </div>

          <Divider
            style={{ fontSize: "14px", color: "#999", marginTop: "30px" }}
          >
            {`${otherProfile?.profile_data[0]?.user_name}'s deals (${otherProfile?.total_deals_count})`}
          </Divider>

          {otherProfile?.deals_data.length > 0 ? (
            <div className="deals">
              {otherProfile?.deals_data.slice(0, dealsCount).map((item) => (
                <ProfileDealItem
                  item={item}
                  key={item?.d_id}
                  showDelete={false}
                />
              ))}
            </div>
          ) : (
            <div className="no-item">no deal yet</div>
          )}

          {!(dealsCount >= otherProfile?.deals_data.length) && (
            <div
              className="more-items"
              onClick={() => {
                setDealsCount(dealsCount + 5);
              }}
            >
              view more
            </div>
          )}

          <Divider
            style={{ fontSize: "14px", color: "#999", marginTop: "30px" }}
          >
            {`reviews for ${otherProfile?.profile_data[0]?.user_name} (${
              Number(merchantReviews && merchantReviews.length) +
              Number(dealerReviews && dealerReviews.length)
            })`}
          </Divider>

          <div className="reviews">
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={`from merchants (${
                  merchantReviews && merchantReviews.length
                })`}
                key="1"
              >
                {merchantReviews?.length === 0 ? (
                  <div className="no-result">
                    {/* <div className="svg-container">
                      <EmptyImage />
                    </div> */}

                    <div
                      className="no-result-text"
                      style={{ fontSize: "16px" }}
                    >
                      no reviews from merchants yet
                    </div>
                  </div>
                ) : (
                  <>
                    {merchantReviews?.map((item) => {
                      return <ProfileReviewsItem key="1" data={item} />;
                    })}
                  </>
                )}
              </TabPane>
              <TabPane
                tab={`from dealers (${dealerReviews && dealerReviews.length})`}
                key="2"
              >
                {dealerReviews?.length === 0 ? (
                  <div className="no-result">
                    {/* <div className="svg-container">
                      <EmptyImage />
                    </div> */}

                    <div
                      className="no-result-text"
                      style={{ fontSize: "16px" }}
                    >
                      no reviews from dealers yet
                    </div>
                  </div>
                ) : (
                  <>
                    {dealerReviews?.map((item) => {
                      return <ProfileReviewsItem key="1" data={item} />;
                    })}
                  </>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
}
