import React, { useState } from "react";
import { message, Tooltip, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "timeago.js";
import {
  LikeOutlined,
  DislikeOutlined,
  ArrowRightOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import "./DealItem.scss";
import { curType } from "../../utils/datasource";
import DealModal from "../DealModal/DealModal";
import { BsCheckCircleFill, BsCheckLg } from "react-icons/bs";
import { AiOutlineWarning } from "react-icons/ai";
import { formatTradeSource } from "../../utils/formatSource";
import { formatDecimalNumber } from "../../utils/formatDecimalNumber";
import { currencyFormat } from "../../utils/formatSourceText";

export default function DealItem({ item }) {
  const history = useHistory();
  const userIdState = useSelector((state) => state?.user?.userData?.id);
  const [modal, setModal] = useState(false);

  let sortName =
    item?.admin_level === 1
      ? "moderator"
      : item?.admin_level === 2
      ? "super user"
      : "";


  return (
    <>
      {/* deal modal */}
      <DealModal
        modal={modal}
        close={() => setModal(false)}
        deal={item}
        dealerData={item}
      />

      {/* deal item */}
      <div className="deal-item-container">
        <Tooltip
          placement="top"
          title={`i am picking ${formatTradeSource(
            item?.source
          )} and will remit to ${formatTradeSource(item?.destination)}`}
        >
          <div className="source-destination">
            {formatTradeSource(item?.source)}{" "}
            <ArrowRightOutlined
              style={{
                strokeWidth: "50",
                stroke: "white",
              }}
            />{" "}
            {formatTradeSource(item?.destination)}{" "}
          </div>
        </Tooltip>

        {/* deal item header start */}
        <div
          className="left"
          onClick={() => {
            if (
              userIdState &&
              item?.dealer_id.toString() === userIdState.toString()
            ) {
              history.push(`/profile`);
            } else {
              history.push({
                pathname: `/profile/${item?.user_name}`,
                state: { id: item?.dealer_id },
              });
            }
          }}
        >
          <div>
            <div className="username-wrap">
              <div className="username-green-text">
                <div className="username-wrapper">
                  {item?.user_name}

                  {item?.admin_level !== 0 && (
                    <Tooltip placement="topRight" title={sortName}>
                      <BsCheckCircleFill
                        style={{
                          marginLeft: "5px",
                          marginBottom: "1px",
                          fontSize: "15.5px",
                        }}
                        color={item?.admin_level === 1 ? "#78ea78" : "orange"}
                      />
                    </Tooltip>
                  )}

                  {item?.dealer_reputation_data?.trust_score >= 80 ? (
                    <Tag
                      color="green"
                      className="chat-tag"
                      style={{
                        marginLeft: "8px",
                        fontSize: "12px",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        gridColumnGap: "5px",
                      }}
                    >
                      <BsCheckLg /> trusted{" "}
                    </Tag>
                  ) : item?.dealer_reputation_data?.trust_score < 0 ? (
                    <Tag
                      color="red"
                      className="chat-tag"
                      style={{
                        marginLeft: "8px",
                        fontSize: "12px",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        gridColumnGap: "5px",
                      }}
                    >
                      {" "}
                      <AiOutlineWarning size={15} /> be careful{" "}
                    </Tag>
                  ) : (
                    ""
                  )}
                </div>
                {/* <span className="full_name" >{item?.full_name}</span> */}
              </div>
              <span
                className="status"
                style={{
                  color: item?.user_status === 1 ? "#14a014" : "#dedede",
                }}
              >
                {item?.user_status === 1 ? "online" : "away"}
              </span>
            </div>
            <div>
              <div className="score-green">
                trust score{" "}
                <span style={{ fontWeight: 600 }}>
                  {item?.dealer_reputation_data?.trust_score}
                </span>{" "}
                <EllipsisOutlined /> trades completed{" "}
                <span className="bold">
                  {item?.dealer_reputation_data?.trades_completed}
                </span>{" "}
                <EllipsisOutlined /> disputed{" "}
                <span className="bold">
                  {item?.dealer_reputation_data?.trades_disputed}
                </span>{" "}
                <EllipsisOutlined /> reviews{" "}
                <span className="bold">
                  {item?.dealer_reputation_data?.total_reviews_count}
                </span>{" "}
                <EllipsisOutlined />{" "}
              </div>
            </div>
          </div>
        </div>
        {/* deal item header end */}

        {/* deal item wrapper  start*/}
        <div className="deal-item-wrapper">
          {/* deal item body start */}
          <div className="deal-item-row-one">
            {/* {item?.s_account_age &&
              item?.s_account_age !== "Any Age" &&
              item?.s_bank_name &&
              `${item?.s_account_age} ${
                item?.s_account_age !== "Any Age" ? "year" : ""
              }${item?.s_account_age !== 1 ? "s" : ""} old `} */}
            {/* {item?.s_wallet_age &&
              item?.s_wallet_age !== "Any Age" &&
              item?.s_wallet_type &&
              `${item?.s_wallet_age} ${
                item?.s_wallet_age !== "Any Age" ? "year" : ""
              }${item?.s_wallet_age !== 1 ? "s" : ""} old `} */}
            {/* {item?.s_bank_name &&
              `${item?.s_bank_name} ${item?.s_account_type} account available in `} */}
            {item?.s_card_brand}
            {item?.s_state && `${item?.s_state},`}{" "}
            {item?.source !== "bank fund" &&
            item?.source !== "bitcoin" &&
            item?.source !== "ethereum" &&
            item?.source !== "litecoin" &&
            item?.source !== "dogecoin"
              ? `${formatTradeSource(item?.source)} (${curType(
                  item.source_currency.toLowerCase()
                )})`
              : `${formatTradeSource(item?.source)} (${curType(
                  item.source_currency.toLowerCase()
                )})`}{" "}
            {/* {item?.s_country && `${item?.s_country},`}  */}
            to{" "}
            {item?.destination === "bank fund"
              ? "bank account"
              : formatTradeSource(item?.destination)}{" "}
            ({curType(item.destination_currency.toLowerCase())}) at{" "}
            {formatDecimalNumber(item?.rate)}{" "}
            {curType(item.destination_currency.toLowerCase())}
            {item?.rate_structure === "percentage"
              ? "%"
              : "/" + curType(item.source_currency.toLowerCase())}
            .{" "}
            <span className="receipt-tag">
              {item?.receipt_required ? "#ReceiptRequired " : ""}
            </span>{" "}
            <span className="receipt-tag">
              {item?.no_third_party ? "#NoThirdParty " : ""}
            </span>{" "}
          </div>

          {/* deal item body end */}

          {/* deal item body2 start */}
          <div className="deal-item-row-two">
            {item?.s_bank_name && (
              <>
                {" "}
                bank name <span className="bold">{item?.s_bank_name}</span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.s_account_type && (
              <>
                {" "}
                account type{" "}
                <span className="bold">{item?.s_account_type}</span>{" "}
                <EllipsisOutlined /> +
              </>
            )}
            {item?.s_account_age && item?.s_account_age !== 0 ? (
              <>
                {" "}
                account age{" "}
                <span className="bold">
                  {item?.s_account_age} years old
                </span>{" "}
                <EllipsisOutlined />{" "}
              </>
            ) : null}
            {item?.s_card_brand && (
              <>
                {" "}
                card brand <span className="bold">
                  {item?.s_card_brand}
                </span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.s_card_type && (
              <>
                {" "}
                card type <span className="bold">{item?.s_card_type}</span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.s_exchange && (
              <>
                {" "}
                exchange <span className="bold">{item?.s_exchange}</span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.s_wallet_type && (
              <>
                {" "}
                wallet type <span className="bold">
                  {item?.s_wallet_type}
                </span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.min && (
              <>
                {" "}
                min{" "}
                <span className="bold">{`${formatDecimalNumber(
                  item?.min.toLocaleString()
                )} ${item?.source_currency?.toUpperCase()}`}</span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.max && (
              <>
                {" "}
                max{" "}
                <span className="bold">{`${formatDecimalNumber(
                  item?.max?.toLocaleString()
                )} ${item?.source_currency?.toUpperCase()}`}</span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.rate && (
              <>
                {" "}
                rate{" "}
                <span className="bold">
                  {formatDecimalNumber(item?.rate)}
                  {curType(item.destination_currency.toLowerCase())}
                  {item?.rate_structure === "percentage"
                    ? "%"
                    : "/" + curType(item.source_currency.toLowerCase())}
                </span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.s_state && (
              <>
                {" "}
                bank state <span className="bold">{item?.s_state}</span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}
            {item?.s_country && (
              <>
                {" "}
                bank country <span className="bold">
                  {item?.s_country}
                </span>{" "}
                <EllipsisOutlined />{" "}
              </>
            )}

            {/* {item?.discussion && (
              <>
                {" "}
                discussion{" "}
                <Tooltip placement="top" title={item?.discussion_details}>
                  <span className="discussion">{item?.discussion}</span>{" "}
                </Tooltip>
                <EllipsisOutlined />{" "}
              </>
            )} */}

            {item?.deal_summary && (
              <>
                {" "}
                <Tooltip placement="top" title={item?.deal_summary}>
                  <span className="discussion">trade instructions</span>
                </Tooltip>
              </>
            )}

            <br />
            <br />
            <span style={{ fontSize: "11px", color: "#999" }}>
              {format(item?.d_created_at)}{" "}
              <Tooltip
                placement="top"
                title={
                  "user posted this deal from this location and will probably arrange a meetup there if necessary."
                }
              >
                {" "}
                · <span className="location">{item?.user_location}</span>
              </Tooltip>{" "}
            </span>
          </div>

          {/* deal item body2 end */}

          {/* deal item footer start */}
          {/* replace the like and dislike with the positive and negative reviews score */}
          <div className="deal-item-row-four">
            <div className="like-dislike">
              <span className="like">
                <LikeOutlined />{" "}
                {item?.dealer_reputation_data?.positive_reviews_count}
              </span>
              <span className="dislike">
                <DislikeOutlined />{" "}
                {item?.dealer_reputation_data?.negative_reviews_count}
              </span>
            </div>
            <div className="right">
              <div
                className="grey-button-nobg"
                onClick={() => {
                  history.push(`/deal/${item?.d_id}`);
                }}
              >
                view
              </div>

              {userIdState &&
              item?.dealer_id.toString() !== userIdState.toString() ? (
                <button
                  className="green-button"
                  onClick={() => {
                    if (userIdState) {
                      setModal(true);
                    } else {
                      message.error("you must login to continue");
                      history.push("/login");
                    }
                  }}
                >
                  trade
                </button>
              ) : (
                userIdState && (
                  <button
                    className="green-button disabled"
                    onClick={() => {
                      message.warn("you posted this deal!");
                    }}
                  >
                    trade
                  </button>
                )
              )}

              {!userIdState && (
                <button
                  className="green-button"
                  onClick={() => {
                    message.error("you must login to continue");
                    history.push("/login");
                  }}
                >
                  trade
                </button>
              )}
            </div>
          </div>
          {/* deal item footer end */}
        </div>
        {/* deal item wrapper  end*/}
      </div>
    </>
  );
}
