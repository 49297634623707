import React, { useState, useEffect } from "react";
import { Tooltip, message, Divider, Breadcrumb, Tag } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  LikeOutlined,
  DislikeOutlined,
  EllipsisOutlined,
  ArrowRightOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import Loader from "./../../components/Loader/Loader";
import { instance } from "./../../utils/API";
import "./deal-page.scss";
import ProfileReviewsItem from "../../components/ProfileReviewsItem/ProfileReviewItem";
import { setHasError } from "../../redux/data/data.actions";
import { format } from "timeago.js";
import { curType } from "../../utils/datasource";
import { BsCheckCircleFill, BsCheckLg } from "react-icons/bs";
import DealModal from "../../components/DealModal/DealModal";
import { bearerInstance } from "./../../utils/API";
import { AiOutlineWarning } from "react-icons/ai";
import { formatDecimalNumber } from "../../utils/formatDecimalNumber";
import { formatTradeSource } from "../../utils/formatSource";
import SEO from "../../components/SEO/seo";

//const { confirm } = Modal;

export default function DealPage({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [deal, setDeal] = useState(null);
  const [dealerData, setDealerData] = useState(null);
  const [dealerReputation, setDealerReputation] = useState(null);
  const userId = useSelector((state) => state?.user?.userData?.id);
  const [reviews, setReviews] = useState(null);
  const [reviewsCount, setReviewsCount] = useState(10);
  const [dealsCount, setDealsCount] = useState(1);
  const [dealerReviews, setDealerReviews] = useState([]);

  const [modal, setModal] = useState(false);

  const handleFetchReview = () => {
    bearerInstance
      .get(
        `/reviews?user_id=${
          deal?.d_id || id
        }&get_reviews_for_profile=${reviewsCount}`
      )
      .then((res) => {
        setReviews(res?.data?.reviews_for_deal);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    handleFetchReview();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDealInfo();

    //eslint-disable-next-line
  }, []);

  let sortName =
    dealerData?.admin_level === 1
      ? "moderator"
      : dealerData?.admin_level === 2
      ? "super user"
      : "";
  let sortColor = dealerData?.admin_level === 1 ? "#78ea78" : "orange";

  const getDealInfo = () => {
    instance
      .get(`/return_this_deal?deal_id=${match.params.id}`)
      .then(function (response) {
        setDeal(response?.data?.deal_data[0]);
        setDealerData(response?.data?.dealer_profile_data[0]);
        setDealerReputation(response?.data?.dealer_reputation_data);
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          dispatch(setHasError(true));
        }
      });
  };

  const handleProfileReview = () => {
    instance
      .get(`/reviews?deal_id=${id}&get_reviews_for_deal=1&user_id=${userId}`)
      .then((res) => {
        setDealerReviews(res?.data?.reviews_for_deal);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    handleProfileReview();
  }, [reviewsCount]);

  return (
    <>
      <SEO
        title={` ${deal?.source} to ${deal?.destination} deal`}
        description="get cashapp to paypal deals, paypal to cashapp deals, cashapp to bankfunds deals, paypal to bankfunds deals, bankfunds to paypal deals"
        type=""
        name="lily.africa"
      />
      <DealModal
        modal={modal}
        close={() => setModal(false)}
        deal={deal}
        dealerData={dealerData}
      />

      <div className="deal-page-container">
        {(!deal || !dealerData) && <Loader />}

        {deal && dealerData && (
          <div className="deal-page-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">
                  <HomeOutlined />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={{
                    pathname:
                      userId && deal?.dealer_id === userId
                        ? "/profile"
                        : `/profile/${dealerData?.user_name}`,

                    state: { id: deal?.dealer_id },
                  }}
                >
                  {dealerData?.user_name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>deal</Breadcrumb.Item>
            </Breadcrumb>

            <div className="user-info">
              <div
                className="left"
                onClick={() => {
                  if (userId && deal?.dealer_id === userId) {
                    history.push(`/profile`);
                  } else {
                    history.push({
                      pathname: `/profile/${dealerData?.user_name}`,
                      state: { id: deal?.dealer_id },
                    });
                  }
                }}
              >
                {/*<div className="avatar">
                <Avatar
                  style={{
                    color: "#14a014",
                    backgroundColor: "#a9fca9",
                    fontWeight: "500",
                  }}
                >
                  {dealerData?.user_name_front.charAt(0).toUpperCase()}
                </Avatar>
              </div>*/}
                <div className="tag-parent-x">
                  <div className="tag-box">
                    <Tooltip
                      placement="top"
                      title={`i am picking ${deal?.source} and will remit to ${deal?.destination}`}
                    >
                      <div className="source-destination">
                        {formatTradeSource(deal?.source)}{" "}
                        <ArrowRightOutlined
                          style={{
                            strokeWidth: "50",
                            stroke: "white",
                          }}
                        />{" "}
                        {formatTradeSource(deal?.destination)}{" "}
                      </div>
                    </Tooltip>
                  </div>

                  <div className="username-green">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {dealerData?.user_name}

                      {dealerData?.admin_level !== 0 && (
                        <Tooltip placement="topRight" title={sortName}>
                          <BsCheckCircleFill
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            color={sortColor}
                          />
                        </Tooltip>
                      )}

                      {dealerReputation?.trust_score >= 80 ? (
                        <Tag
                          color="green"
                          className="chat-tag"
                          style={{
                            marginLeft: "8px",
                            fontSize: "12px",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            gridColumnGap: "5px",
                          }}
                        >
                          <BsCheckLg /> trusted{" "}
                        </Tag>
                      ) : dealerReputation?.trust_score < 0 ? (
                        <Tag
                          color="red"
                          className="chat-tag"
                          style={{
                            marginLeft: "8px",
                            fontSize: "12px",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            gridColumnGap: "5px",
                          }}
                        >
                          {" "}
                          <AiOutlineWarning size={15} /> be careful{" "}
                        </Tag>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* <span className="full_name">{dealerData?.full_name}</span> */}

                    <span
                      style={{ color: 1 === 1 ? "#14a014" : "#dedede" }}
                      className="status-user"
                    >
                      {1 === 1 ? "online" : "away"}
                    </span>
                  </div>
                  <div>
                    {/* user rating and score */}
                    {/* <div className="score-green">
                      score{" "}
                      <span style={{ fontWeight: 600 }}>
                        {dealerData?.a_score}
                      </span>
                      {dealerData?.receipt_required && (
                        <>
                          {" "}
                          <EllipsisOutlined />{" "}
                          <span style={{ fontWeight: 600 }}>
                            receipt required
                          </span>
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="deal-info">
              <div className="deal-item-wrapper">
                <div className="score-green">
                  trust score{" "}
                  <span style={{ fontWeight: 600 }}>
                    {dealerReputation?.trust_score}
                  </span>{" "}
                  <EllipsisOutlined /> trades completed{" "}
                  <span className="bold">
                    {dealerReputation?.trades_completed}
                  </span>{" "}
                  <EllipsisOutlined /> disputed{" "}
                  <span className="bold">
                    {dealerReputation?.trades_disputed}
                  </span>{" "}
                  <EllipsisOutlined /> reviews{" "}
                  <span className="bold">
                    {dealerReputation?.total_reviews_count}
                  </span>{" "}
                  <EllipsisOutlined />{" "}
                </div>

                <div className="deal-item-row-one">
                  {/* {deal?.s_account_age &&
                    deal?.s_account_age !== "Any Age" &&
                    deal?.s_bank_name &&
                    `${deal?.s_account_age} ${
                      deal?.s_account_age !== "Any Age" ? "year" : ""
                    }${deal?.s_account_age !== 1 ? "s" : ""} old `}
                  {deal?.s_wallet_age &&
                    deal?.s_wallet_age !== "Any Age" &&
                    deal?.s_wallet_type &&
                    `${deal?.s_wallet_age} ${
                      deal?.s_wallet_age !== "Any Age" ? "year" : ""
                    }${deal?.s_wallet_age !== 1 ? "s" : ""} old `}
                  {deal?.s_bank_name &&
                    `${deal?.s_bank_name} ${deal?.s_account_type} account available in `} */}
                  {/* {deal?.s_wallet_type && `${deal?.source} wallet available`} */}
                  {deal?.s_card_brand}
                  {deal?.s_state && `${deal?.s_state},`}{" "}
                  {deal?.s_state && `${deal?.s_state},`}
                  {deal?.source !== "bank fund" &&
                  deal?.source !== "bitcoin" &&
                  deal?.source !== "ethereum" &&
                  deal?.source !== "litecoin" &&
                  deal?.source !== "dogecoin"
                    ? `${formatTradeSource(deal?.source)} (${curType(
                        deal.source_currency.toLowerCase()
                      )})`
                    : formatTradeSource(deal?.source)}{" "}
                  {/* {deal?.s_country && `${deal?.s_country},`} */}
                  to{" "}
                  {deal?.destination === "bank fund"
                    ? "bank account"
                    : formatTradeSource(deal?.destination)}{" "}
                  ({curType(deal?.destination_currency.toLowerCase())}) at{" "}
                  {formatDecimalNumber(deal?.rate)}{" "}
                  {curType(deal?.destination_currency.toLowerCase())}
                  {deal?.rate_structure === "percentage"
                    ? "%"
                    : "/" + curType(deal.source_currency.toLowerCase())}{" "}
                  <span className="receipt-tag">
                    {deal?.receipt_required ? "#ReceiptRequired " : ""}
                  </span>{" "}
                  <span className="receipt-tag">
                    {deal?.no_third_party ? "#NoThirdParty " : ""}
                  </span>{" "}
                </div>

                <div className="deal-item-row-two">
                  {deal?.s_bank_name && (
                    <>
                      {" "}
                      bank name{" "}
                      <span className="bold">{deal?.s_bank_name}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_account_type && (
                    <>
                      {" "}
                      account type{" "}
                      <span className="bold">{deal?.s_account_type}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_account_age && deal?.s_account_age !== 0 ? (
                    <>
                      {" "}
                      account age{" "}
                      <span className="bold">
                        {deal?.s_account_age} years old
                      </span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  ) : null}
                  {deal?.s_card_brand && (
                    <>
                      {" "}
                      card brand{" "}
                      <span className="bold">{deal?.s_card_brand}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_card_type && (
                    <>
                      {" "}
                      card type{" "}
                      <span className="bold">{deal?.s_card_type}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_exchange && (
                    <>
                      {" "}
                      exchange <span className="bold">
                        {deal?.s_exchange}
                      </span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_wallet_type && (
                    <>
                      {" "}
                      wallet type{" "}
                      <span className="bold">{deal?.s_wallet_type}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.min && (
                    <>
                      {" "}
                      min{" "}
                      <span className="bold">{`${formatDecimalNumber(
                        deal?.min.toLocaleString()
                      )} ${deal?.source_currency?.toUpperCase()}`}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.max && (
                    <>
                      {" "}
                      max{" "}
                      <span className="bold">{`${formatDecimalNumber(
                        deal?.max.toLocaleString()
                      )} ${deal?.source_currency?.toUpperCase()}`}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.rate && (
                    <>
                      {" "}
                      rate{" "}
                      <span className="bold">
                        {formatDecimalNumber(deal?.rate)}
                        {curType(deal?.destination_currency.toLowerCase())}
                        {deal?.rate_structure === "percentage"
                          ? "%"
                          : "/" + curType(deal.source_currency.toLowerCase())}
                      </span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_state && (
                    <>
                      {" "}
                      bank state <span className="bold">
                        {deal?.s_state}
                      </span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {deal?.s_country && (
                    <>
                      {" "}
                      bank country{" "}
                      <span className="bold">{deal?.s_country}</span>{" "}
                      <EllipsisOutlined />{" "}
                    </>
                  )}
                  {/* {deal?.discussion && (
                    <>
                      {" "}
                      discussion{" "}
                      <Tooltip placement="top" title={deal?.discussion_details}>
                        <span className="discussion">{deal?.discussion}</span>{" "}
                      </Tooltip>
                      <EllipsisOutlined />{" "}
                    </>
                  )} */}
                  {deal?.deal_summary && (
                    <>
                      {" "}
                      <Tooltip placement="top" title={deal?.deal_summary}>
                        <span className="discussion">trade instructions</span>
                      </Tooltip>
                    </>
                  )}
                </div>

                <div className="deal-item-row-three">
                  <span>{deal?.d_last_updated_at ? "updated" : ""}</span>{" "}
                  {format(
                    deal?.d_last_updated_at
                      ? deal?.d_last_updated_at
                      : deal?.d_created_at
                  )}{" "}
                  <Tooltip
                    placement="top"
                    title={
                      "user posted this deal from this location and will probably arrange a meetup there if necessary."
                    }
                  >
                    {" "}
                    ·{" "}
                    <span className="location">
                      {dealerData?.user_location}
                    </span>
                  </Tooltip>{" "}
                </div>

                <div className="deal-item-row-four">
                  <div className="like-dislike no-margin-top">
                    <span className="like">
                      <LikeOutlined />{" "}
                      {dealerReputation?.positive_reviews_count}
                    </span>
                    <span className="dislike add-margin-right">
                      <DislikeOutlined />{" "}
                      {dealerReputation?.negative_reviews_count}
                    </span>
                  </div>

                  <div
                    className="grey-button-nobg"
                    onClick={() => {
                      if (deal?.dealer_id === userId) {
                        history.push(`/edit-deal/${deal?.d_id}`);
                      }
                    }}
                  >
                    {deal?.dealer_id === userId ? "edit" : "report"}
                  </div>
                  <div
                    className={`grey-button-nobg ${
                      deal?.dealer_id === userId ? "no-margin-right" : ""
                    }`}
                  >
                    share
                  </div>
                  {deal?.dealer_id !== userId && (
                    <button
                      className="green-button"
                      onClick={() => {
                        if (!userId) {
                          message.error("you must login to continue");
                          history.push("/login");
                        }
                        setModal(true);
                      }}
                    >
                      trade
                    </button>
                  )}
                </div>
              </div>
            </div>

            <Divider
              style={{ fontSize: "14px", color: "#999", marginTop: "60px" }}
            >
              reviews for @{dealerData?.user_name} ({dealerReviews.length})
            </Divider>

            <div className="deal-reviews">
              {dealerReviews?.map((item) => {
                return <ProfileReviewsItem data={item} />;
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
