import React from "react";
import "./banner.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";

const Banner = ({ bannerRef }) => {
  const history = useHistory();
  const user = useSelector((state) => state?.user?.userData);

  const handleButtonClick = () => {
    if (bannerRef.current) {
      bannerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="banner-bg">
      <h3 className="banner-title">convert money from one form to another.</h3>
      {/* <p className="banner-text">where borders fades and funds flow freely:</p> */}
      <p className="banner-text">the future of money exchange.</p>
      <p className="banner-text">powered by you.</p>

      {!user ? (
        <button
          className="banner-btn"
          onClick={() => history.push("/register")}
        >
          get started <IoIosArrowForward />
        </button>
      ) : (
        <button className="banner-btn" onClick={handleButtonClick}>
          start trading <IoIosArrowForward />
        </button>
      )}
    </div>
  );
};

export default Banner;
