import React, { useEffect, useState } from "react";
import { Tabs, Button, message, Divider, Breadcrumb, Tag, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  LikeOutlined,
  DislikeOutlined,
  EllipsisOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Loader from "./../../components/Loader/Loader";

import ProfileDiscussionItem from "../../components/ProfileDiscussionItem/ProfileDiscussionItem";
import ProfileDealItem from "./../../components/ProfileDealsItem/ProfileDealItem";
import ProfileReviewsItem from "../../components/ProfileReviewsItem/ProfileReviewItem";
import "./profile.scss";
import useProfile from "../../hooks/useProfile";
import { BsCheckCircleFill } from "react-icons/bs";
import { bearerInstance, bearerInstanceWithToken } from "../../utils/API";
import { ReactComponent as EmptyImage } from "./../../assets/empty.svg";

const { TabPane } = Tabs;

export default function Profile() {
  const { getProfileInfo } = useProfile();

  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const profileData = useSelector((state) => state.data.profile);
  const [dealsCount, setDealsCount] = useState(3);
  const [loading, setLoading] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [dealerReviews, setDealerReviews] = useState(null);
  const [merchantReviews, setMerchantReviews] = useState(null);
  const [reviewsCount, setReviewsCount] = useState(10);

  const handleProfileReview = () => {
    bearerInstance
      .get(
        `/reviews?user_id=${userState?.userData?.id}&get_reviews_for_profile=${reviewsCount}`
      )
      .then((res) => {
        setDealerReviews(res.data.from_dealers);
        setMerchantReviews(res.data.from_merchants);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  let sortName =
    profileData?.profile_data[0]?.admin_level === 1
      ? "moderator"
      : profileData?.profile_data[0]?.admin_level === 2
      ? "super user"
      : "";

  const getAllChats = () => {
    setLoading(true);

    bearerInstance
      .get("/fetch_all_discussions")
      .then((res) => {
        setDiscussions(res.data.discussion_data);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!userState?.userData) {
      history.push("/login");
      message.warning("please login to continue");
    } else {
      getProfileInfo();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllChats();
  }, []);

  const handleClick = () => {
    const url = `${
      window.location.origin
    }/admin-dashboard?data=${encodeURIComponent(
      JSON.stringify(profileData?.profile_data[0]?.admin_level)
    )}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    handleProfileReview();
  }, [reviewsCount]);


  return (
    <div className="profile-container">
      {!profileData && loading && <Loader />}

      {profileData && (
        <div className="profile-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>profile</Breadcrumb.Item>
          </Breadcrumb>

          <div className="quick-actions">
            <Button type="primary" size="normal">
              <Link to="/new-deal">new deal</Link>
            </Button>

            {profileData?.profile_data[0]?.admin_level !== 0 && (
              <Button type="primary" size="normal" className="dashboard-btn">
                <p onClick={handleClick}>admin dashboard</p>
              </Button>
            )}
          </div>

          <div className="user-info">
            <div className="username">
              @{profileData?.profile_data[0]?.user_name}

              {profileData.profile_data[0]?.admin_level !== 0 && (
                <Tooltip placement="topRight" title={sortName}>
                  <BsCheckCircleFill
                    style={{
                      marginLeft: "5px",
                      fontSize: "15px",
                    }}
                    color={
                      profileData?.profile_data[0]?.admin_level === 1
                        ? "#78ea78"
                        : "orange"
                    }
                  />
                </Tooltip>
              )}
            </div>
            <div className="rate">
              profile score <span>{profileData?.reputation?.trust_score}</span>{" "}
              <EllipsisOutlined />
              trades completed{" "}
              <span>
                {profileData?.reputation?.trades_completed}{" "}
                {profileData?.reputation?.trades_completed > 1
                  ? "trades"
                  : "trade"}
              </span>{" "}
              <EllipsisOutlined /> disputed{" "}
              <span>{profileData?.reputation?.trades_disputed} </span>{" "}
              <EllipsisOutlined /> from{" "}
              <span>{profileData?.profile_data[0]?.user_location}</span>
            </div>

            <div className="like-dislike">
              <span className="like">
                <LikeOutlined />{" "}
                {profileData?.reputation?.positive_reviews_count}
              </span>
              <span className="dislike">
                <DislikeOutlined />{" "}
                {profileData?.reputation?.negative_reviews_count}
              </span>
            </div>
          </div>

          <Divider
            style={{ fontSize: "14px", color: "#999", marginTop: "30px" }}
          >
            trades ({discussions.length})
          </Divider>

          <div className="discussions">
            {discussions.slice(0, 3).map((cur, i) => (
              <ProfileDiscussionItem key={i} data={cur} />
            ))}
          </div>

          <Button
            onClick={() => {
              history.push("/chat");
            }}
            type="text"
            style={{
              color: "#ed1450",
              display: "block",
              margin: "0 auto",
              marginTop: "8px",
            }}
          >
            view all
          </Button>

          <Divider
            style={{ fontSize: "14px", color: "#999", marginTop: "30px" }}
          >
            your deals ({profileData?.total_deals_count})
          </Divider>

          {profileData?.deals_data.length > 0 ? (
            <div className="deals">
              {profileData?.deals_data.slice(0, dealsCount).map((item) => (
                <ProfileDealItem
                  item={item}
                  key={item?.d_id}
                  showDelete={true}
                />
              ))}
            </div>
          ) : (
            <div className="no-item">no deal yet</div>
          )}

          {!(dealsCount >= profileData?.deals_data.length) && (
            <div
              className="more-items"
              onClick={() => {
                setDealsCount(dealsCount + 5);
              }}
            >
              view more
            </div>
          )}

          <Divider
            style={{ fontSize: "14px", color: "#999", marginTop: "30px" }}
          >
            reviews{" "}
            {` (${
              Number(merchantReviews && merchantReviews.length) +
              Number(dealerReviews && dealerReviews.length)
            })`}
          </Divider>

          <div className="reviews">
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={`from merchants (${
                  merchantReviews ? merchantReviews.length : 0
                })`}
                key="1"
              >
                {merchantReviews?.length === 0 ? (
                  <div className="no-result">
                    {/* <div className="svg-container">
                      <EmptyImage />
                    </div> */}

                    <div
                      className="no-result-text"
                      style={{ fontSize: "14px" }}
                    >
                      no reviews for merchant
                    </div>
                  </div>
                ) : (
                  <>
                    {merchantReviews?.map((item, i) => {
                      return <ProfileReviewsItem key={i} data={item} />;
                    })}
                  </>
                )}
              </TabPane>
              <TabPane
                tab={`from dealers (${
                  dealerReviews ? dealerReviews.length : 0
                })`}
                key="2"
              >
                {dealerReviews?.length === 0 ? (
                  <div className="no-result">
                    {/* <div className="svg-container">
                      <EmptyImage />
                    </div> */}

                    <div
                      className="no-result-text"
                      style={{ fontSize: "14px" }}
                    >
                      no reviews for dealer
                    </div>
                  </div>
                ) : (
                  <>
                    {dealerReviews?.map((item, i) => {
                      return <ProfileReviewsItem key={i} data={item} />;
                    })}
                  </>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
}
