import React, { useEffect } from "react";
import "../Privacy/privacy.css";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import SEO from "../../components/SEO/seo";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title="Terms & Conditions | Lily Africa"
        description="Please take a moment to review our Terms and Conditions for important information."
      />
      <div className="privacy-container">
        <div className="privacy-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>terms</Breadcrumb.Item>
          </Breadcrumb>

          <h2 class="privacy-title">TERMS OF SERVICE</h2>
          <p class="privacy-text">Effective Date: March 3, 2023</p>
          <p class="privacy-text">
            THIS AGREEMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR
            RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS AND
            EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.
          </p>
          <p class="privacy-text">
            These Terms of Service and any amendments and restatements hereof
            (the “Agreement”) form a legal agreement covering the provision of
            services from Lily.africa to you, including offering a marketplace
            to enable Dealers and Vendors of “Digital Assets” (such term to be
            broadly understood to include digital currencies such as Bitcoin,
            Tether, and others, supported by a Lily.africa wallet) to engage in
            transactions with each other (the “Marketplace”), offering hosted
            digital wallet services, holding and releasing Digital Assets as
            instructed upon completion of a purchase of Digital Assets and any
            other services described in this Agreement (collectively the
            “Services” and individually, a “Service”) provided by Lily Finance,
            Inc. and all of its affiliates, including but not limited to
            Lily.africa, (collectively, “Lily.africa” or “we” or “us” or the
            “company”) to you as an individual (also referred to as a “user” or
            “you”). Lily.africa and its related Services are owned and operated
            by Lily Finance Limited. Your use of the Services will be governed
            by this Agreement, along with our Privacy Policy and Cookie Policy.
            THESE TERMS REQUIRE THE USE OF ARBITRATION TO RESOLVE DISPUTES
            RATHER THAN JURY TRIALS OR CLASS ACTIONS.
          </p>
          <p class="privacy-text">
            By signing up to use an account through Lily.africa, or any
            associated websites, APIs, or mobile applications, including any
            URLs operated by Lily.africa (collectively the “Lily.africa Website”
            or the “Website”), you agree that you have carefully and thoroughly
            read, understand, and accept all of the terms and conditions
            contained in this Agreement including our Privacy Policy, Cookie
            Policy, and E-Sign Consent.
          </p>
          <p class="privacy-text">
            THE VALUE OF DIGITAL ASSETS CAN GO UP OR DOWN AND THERE CAN BE A
            SUBSTANTIAL RISK THAT YOU LOSE MONEY BUYING, SELLING, HOLDING, OR
            INVESTING IN DIGITAL ASSETS. YOU SHOULD CAREFULLY CONSIDER WHETHER
            TRADING OR HOLDING DIGITAL ASSETS IS SUITABLE FOR YOU IN LIGHT OF
            YOUR FINANCIAL CONDITION.
          </p>
          <h2 class="privacy-title">About Lily.africa and its Services</h2>
          <p class="privacy-text">
            Lily.africa is a pioneering peer-to-peer marketplace to facilitate
            the purchase and sale of Digital Assets as well as the swapping
            (buying and selling) of various financial instruments, with Vendors
            accepting more than 300 payment methods in exchange for their
            Digital Assets. Payment methods are negotiated and exchanged on a
            peer-to-peer basis between the Dealers in the Marketplace
            (“Dealers”) and Vendors in the Marketplace (“Vendors”). Our users
            agree upon which payment methods to use to complete a transaction
            and are fully responsible and liable for using such payment methods
            in a lawful manner.
          </p>
          <p class="privacy-text">
            Lily.africa also offers hosted digital wallet services through a
            leading global digital asset wallet provider. Our worldwide userbase
            is able to post offers to either buy or sell Digital Assets in a
            variety of convenient methods. The creator of the offer is
            responsible for listing terms of the transaction, including the
            payment methods the VENDOR will accept. Once an offer is selected by
            another Lily.africa user, the Vendor’s Digital Assets are locked as
            part of our transaction procedures (which we refer to as
            “Lily.africa Escrow”) until all conditions necessary to consummate
            the transaction have occurred. The sale is complete and Digital
            Assets are unlocked and released to the Vendor by the Dealer once
            the Vendor has completed the terms of the transaction and payment
            has been confirmed valid and received by the Dealer. This use case
            can also be reversed. LILY.AFRICA DOES NOT ACT AS A PAYMENT
            PROCESSOR. ALL LIABILITY FOR SENDING AND RECEIVING PAYMENT AND
            CONFIRMING THE VALIDITY OF THE TRANSACTIONS LIE BETWEEN THE DEALER
            AND VENDOR. The Digital Assets we lock are released back to the
            VENDOR if the DEALER chooses to cancel the transaction. The VENDOR
            may not cancel the transaction at any point. The VENDOR only has the
            option to unlock the Digital Assets and release it to the DEALER.
            This is for the security protection for the DEALER. Should a VENDOR
            need to cancel the transaction due to a DEALER not following the
            terms of the transaction, they must start a dispute and provide a
            reason for doing so as further described in Section 8 of this
            Agreement. Transactions on our Website are conducted between the
            Dealers and Vendors. Accordingly, Lily.africa is not a party to any
            transaction.
          </p>
          <p class="privacy-text">
            The hosted digital currency wallet service provided by Lily.africa
            is a secure method of storing, sending, and receiving digital
            currency. Lily.africa does not store or custody any Digital Assets.
            Digital Assets are always stored on its respective networks or
            blockchains. All digital currency transactions occur within the
            digital currency network, not on Lily.africa. There are no
            guarantees that the transaction will process on the digital currency
            network. Lily.africa reserves the right to refuse to process any
            transaction if required by law or if we deem the transactions to be
            against our terms and conditions in this Agreement. You hereby
            accept and acknowledge that you take full responsibility for all
            activities that occur under your wallet and accept all risks of any
            authorized or unauthorized access to your wallet, to the maximum
            extent permitted by law. You are hereby instructed to refrain from
            retaining substantial amounts of funds, including digital and
            cryptocurrency, within your Lily.africa wallet. It is advisable to
            maintain only the requisite amount for trading purposes, with the
            surplus being securely stored in self-custody. Please be advised
            that any financial losses incurred within your Lily.africa wallet
            shall be deemed to be at your sole risk and responsibility.
          </p>
          <h2 class="privacy-title">1. GENERAL</h2>
          <ol>
            <li class="privacy-list">
              We reserve the right to amend, modify, change or revise this
              Agreement at any time, in our sole and absolute discretion and
              without prior notice. Any such changes in respect of your use of
              the Services will take effect when posted on the Lily.africa
              Website and not retroactively. If you have supplied us with an
              email address, we may also notify you by email that the Agreement
              has been revised. If you do not agree to the terms of the revised
              Agreement, your sole and exclusive remedy is to promptly terminate
              your use of the Services and close your account.
            </li>
            <li class="privacy-list">
              It is your responsibility to read the Agreement carefully and
              periodically review this Agreement as posted on the Lily.africa
              Website. Your continued use of the Services shall signify your
              acceptance to be bound by the then-current Agreement.
            </li>
            <li class="privacy-list">
              Failure or delay by Lily.africa in enforcing or partially
              enforcing any provision of the Agreement shall not be construed as
              a waiver of any of our rights or remedies.
            </li>
          </ol>

          <h2 class="privacy-title">2. ACCOUNT & REGISTRATION</h2>
          <ol>
            <li class="privacy-list">
              <p class="privacy-text">
                In order to use the Services, you will need to register an
                account through our Website. During the registration process, we
                will ask you for certain information, including but not limited
                to, your name, address and other personal information to verify
                your identity. We may, in our sole and absolute discretion,
                refuse to maintain an account for you. You hereby accept and
                acknowledge that you: (a) are of legal age in your respective
                jurisdiction to agree to this Agreement; and (b) have not
                previously been suspended or removed from using our Services.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                By using your account, you agree and represent that you will use
                our Services for yourself and you may not use your account to
                act as an intermediary or broker for any other third party,
                person or entity. Unless expressly authorized by Lily.africa,
                you are only allowed to have one account and are not allowed to
                sell, borrow, share or otherwise make available your account or
                any detail necessary to access your account to people or
                entities other than yourself. You are solely responsible and
                liable for maintaining adequate security and control of any and
                all usernames, email addresses, passwords, two-factor
                authentication codes or any other codes or credentials that you
                use to access the Services. Your account must not contain
                misleading or fraudulent information. Creating false information
                for your account, falsifying your country of origin or providing
                fraudulent identification documents is strictly prohibited.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                During the registration of your account, you agree to provide us
                with the information we request for the purposes of identity
                verification and the detection of money laundering, terrorist
                financing, fraud, or any other financial crime and permit us to
                keep a record of such information. You will need to complete
                certain verification procedures before you are permitted to use
                the Services, which procedures may be modified as a result of
                information collected about you on an ongoing basis. The
                information we request may include certain personal information,
                including, but not limited to, your name, address, telephone
                number, e-mail address, date of birth, social security number,
                taxpayer identification number, and a government identification.
                In providing us with this or any other information that may be
                required, you confirm that all of the information is true,
                accurate and not misleading. You agree to promptly keep us
                updated if any of the information you provide changes. YOU
                AUTHORIZE US TO MAKE INQUIRIES, WHETHER DIRECTLY OR THROUGH
                THIRD PARTIES, THAT WE CONSIDER NECESSARY TO VERIFY YOUR
                IDENTITY OR PROTECT YOU AND/OR US AGAINST FRAUD OR OTHER
                FINANCIAL CRIME, AND TO TAKE ACTION WE DEEM NECESSARY BASED ON
                THE RESULTS OF SUCH INQUIRIES. WHEN WE CARRY OUT THESE
                INQUIRIES, YOU ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL
                INFORMATION MAY BE DISCLOSED TO CREDIT REFERENCE AND FRAUD
                PREVENTION OR FINANCIAL CRIME AGENCIES AND THAT THESE AGENCIES
                MAY RESPOND TO OUR INQUIRIES IN FULL.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                If you are using the Services on behalf of a legal entity such
                as a corporate entity, you further represent and warrant that:
                (i) the legal entity is duly organized and validly existing
                under the applicable laws of the jurisdiction of its
                organization; and (ii) you are duly authorized by such legal
                entity to act on its behalf. A corporate-verified account is
                specific to that legal entity and can only be used by the person
                who registered it. Corporate accounts are not allowed to be
                shared with or used by other individuals or entities. Corporate
                accounts that are verified are allowed the following limited
                exemptions:
              </p>
              <ul>
                <li class="privacy-list">
                  An approved corporate account may have several active user
                  accounts at any time, provided they are all company-verified
                  and operated by designated employees of the company that have
                  been previously disclosed and approved by Lily.africa in its
                  sole and absolute discretion;
                </li>
                <li class="privacy-list">
                  Corporate accounts may only have one active offer for a
                  specific transaction at any one time and are not allowed to
                  have multiple offers for such specific transaction from their
                  other corporate accounts.
                </li>
              </ul>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                You are solely responsible for creating a strong password and
                maintaining adequate security and control of any and all IDs,
                passwords, hints, personal identification numbers (PINs), API
                keys or any other codes that you use to access our Services. Any
                loss or compromise of the foregoing information and/or your
                personal information may result in unauthorized access to your
                account by third parties and the loss or theft of any Digital
                Assets and/or funds associated with your account, including your
                linked payment methods. You are solely responsible for keeping
                your email address, telephone number and other contact details
                up to date in your account profile in order to receive any
                notices or alerts that we may send you. You should never allow
                remote access or share your computer screen with someone else
                when you are logged on to your account. We assume no
                responsibility for any loss that you may sustain due to
                compromise of account login credentials due to no fault of
                Lily.africa and/or your failure to follow or act on any notices
                or alerts that we may send to you.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                To use our Services you may need to fulfill certain legal
                obligations in your country and/or state of residence. By
                accepting these terms in this Agreement, you confirm that you
                have reviewed your local laws and regulations and that you are
                aware of, and fulfill, any and all such obligations. Due to
                legal or regulatory prohibitions, we do not offer the use of our
                Services in certain jurisdictions. By accepting the terms in
                this Agreement, you confirm that you are not a resident or
                governed by the laws and regulations of those jurisdictions.
              </p>
            </li>

            <li className="privacy-list">
              We may not make all of the Services available in all markets and
              jurisdictions and may restrict or prohibit use of all or a portion
              of the Services from certain jurisdictions (“Restricted
              Jurisdictions”). At this time, Restricted Jurisdictions include
              those that are identified on our “List of Banned Countries”, and
              the States of Washington and New York. Additionally, the Services
              are not available with respect to the digital currency, Tether
              (USDT), to users residing in the State of Texas. You must not
              attempt to use our Services if you are located in any of those
              Restricted Jurisdictions. You must not attempt to circumvent any
              restrictions imposed via the Services, such as by obscuring your
              IP address or submitting any inaccurate information regarding your
              location.
            </li>
          </ol>

          <h2 class="privacy-title">
            3. JURISDICTION, ARBITRATION & SEVERABILITY
          </h2>
          <ol>
            <li class="privacy-list">
              <p class="privacy-text">
                This Agreement and your use of the Website and Services shall be
                governed by and construed in accordance with the State of
                Delaware, without regard to principles of conflict of laws.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                Arbitration. You and Lily.africa agree that any dispute arising
                out of or relating to this Agreement or the Services, shall be
                finally settled in binding arbitration, on an individual basis,
                in accordance with the American Arbitration Association’s rules
                for arbitration of consumer-related disputes (
                <a
                  href="https://www.adr.org/rules"
                  class="privacy-text"
                  target="_blank"
                >
                  accessible at https://www.adr.org/rules
                </a>
                ). Subject to applicable jurisdictional requirements, consumer
                claimants (individuals whose transaction is intended for
                personal, family, or household use) may elect to pursue their
                claims in their local small-claims court rather than through
                arbitration so long as their matter remains in small claims
                court and proceeds only on an individual (non-class or
                non-representative) basis.
              </p>
              <p class="privacy-text">
                <strong>CLASS ACTION WAIVER:</strong> TO THE EXTENT PERMISSIBLE
                BY LAW, ALL CLAIMS WILL ONLY BE BROUGHT ON AN INDIVIDUAL BASIS,
                AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
                COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING (COLLECTIVELY
                “CLASS ACTION WAIVER”). THE ARBITRATOR MAY NOT CONSOLIDATE MORE
                THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY CLASS ARBITRATION. YOU
                ACKNOWLEDGE THAT, BY AGREEING TO THESE TERMS, YOU AND
                LILY.AFRICA ARE EACH WAIVING ANY RIGHT TO A TRIAL BY JURY AND
                YOU ARE WAIVING THE RIGHT TO PARTICIPATE IN A CLASS ACTION
                AGAINST LILY.AFRICA.
              </p>
              <p class="privacy-text">
                The Federal Arbitration Act, 9 U.S.C. §§ 1-16, fully applies to
                the arbitration. The arbitration will be conducted by a single,
                neutral arbitrator and shall take place in the State of
                Delaware, or another mutually agreeable location, in the English
                language. The arbitrator may award any relief that a court of
                competent jurisdiction could award, including attorneys’ fees
                when authorized by law, and the arbitral decision may be entered
                as a judgement and enforced in any court of law. At your
                request, hearings may be conducted in person or by telephone and
                the arbitrator may provide for submitting and determining
                motions on briefs, without oral hearings. The prevailing party
                in any action or proceeding to enforce this Agreement shall be
                entitled to costs and attorneys’ fees.
              </p>
              <p class="privacy-text">
                If the arbitrator(s) or arbitration administrator would impose
                filing fees or other administrative costs on you, we will
                reimburse you, upon request, to the extent such fees or costs
                would exceed those that you would otherwise have to pay if you
                were proceeding instead in a court. We will also pay additional
                fees or costs if required to do so by the arbitration
                administrator’s rules or applicable law. Apart from the
                foregoing, each party will be responsible for any other fees or
                costs, such as attorney fees that the party may incur.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                If any part of this Agreement is held by any arbitrator or court
                of United States to be invalid or unenforceable in whole or in
                part, the validity or enforceability of the other sections of
                these terms and conditions shall not be affected. Any headings
                contained in this Agreement are for informational purposes only
                and are not enforceable provisions of this Agreement.
              </p>
            </li>
          </ol>

          <h2 class="privacy-title">4. PRIVACY POLICY & SECURITY</h2>
          <ol>
            <li class="privacy-list">
              <p class="privacy-text">
                We endeavor to take all reasonable steps to protect your
                personal information. However, we cannot guarantee the security
                of any data you disclose online. You accept the inherent
                security risks of providing information and dealing online over
                the Internet and will not hold us responsible for any breach of
                security unless this is due to our negligence.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                Please view our official privacy statement:{" "}
                <a
                  href="https://Lily.africa/privacy"
                  class="privacy-text"
                  target="_blank"
                >
                  https://Lily.africa/privacy
                </a>
                .
              </p>
            </li>
          </ol>

          <h2 className="privacy-title">
            {" "}
            5. NO WARRANTY, LIMITATION OF LIABILITY & ASSUMPTION OF RISK
          </h2>
          <ol>
            <li class="privacy-list">
              <p class="privacy-text">
                THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
                WITHOUT ANY GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
                EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW, LILY.AFRICA SPECIFICALLY DISCLAIMS ANY
                IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. LILY.AFRICA DOES NOT
                MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE
                WEBSITE, ANY PART OF THE SERVICES, OR ANY OF THE MATERIALS
                CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR
                ERROR-FREE. LILY.AFRICA IS NOT LIABLE FOR ANY DISRUPTION OR LOSS
                A USER MAY SUFFER. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU
                HAVE NOT RELIED UPON ANY OTHER STATEMENT OR UNDERSTANDING,
                WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF
                THE SERVICES AND WEBSITE. WITHOUT LIMITING THE FOREGOING, YOU
                HEREBY ACKNOWLEDGE AND ACCEPT THE VARIOUS RISKS INHERENT TO
                USING DIGITAL CURRENCY INCLUDING BUT NOT LIMITED TO HARDWARE
                FAILURE, SOFTWARE ISSUES, INTERNET CONNECTION FAILURE, MALICIOUS
                SOFTWARE, THIRD PARTY INTERFERENCE LEADING TO LOSS OR LACK OF
                ACCESS TO YOUR ACCOUNT OR WALLET AND OTHER USER DATA, SERVER
                FAILURE OR DATA LOSS. YOU ACCEPT AND ACKNOWLEDGE THAT
                LILY.AFRICA WILL NOT BE RESPONSIBLE FOR ANY COMMUNICATION
                FAILURES, DISRUPTIONS, ERRORS, DISTORTIONS OR DELAYS YOU MAY
                EXPERIENCE WHEN USING THE SERVICES, HOWEVER CAUSED.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                IN NO EVENT SHALL LILY.AFRICA, ITS AFFILIATES AND SERVICE
                PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS,
                AGENTS, EMPLOYEES, ADVISORS, CONSULTANTS OR REPRESENTATIVES, BE
                LIABLE (A) FOR ANY AMOUNT GREATER THAN THE VALUE OF TOTAL FEES
                PAID BY YOU FOR THE SERVICE THAT IS SUBJECT OF THE CAUSE OF
                ACTION IN THE TWELVE (12) MONTHS PRIOR TO THE LOSS ARISING OR
                (B) FOR ANY LOST PROFITS, DIMINUTION IN VALUE OR BUSINESS
                OPPORTUNITY, ANY LOSS, DAMAGE, CORRUPTION OR BREACH OF DATA OR
                ANY OTHER INTANGIBLE PROPERTY OR ANY SPECIAL, INCIDENTAL,
                INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN
                CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE,
                ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED
                USE OF THE SITE OR THE SERVICES, OR THIS AGREEMENT, EVEN IF AN
                AUTHORIZED REPRESENTATIVE OF LILY.AFRICA HAS BEEN ADVISED OF OR
                KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES,
                AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF
                ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL
                DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF LILY.AFRICA’S
                GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL
                VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                ABOVE LIMITATION MAY NOT APPLY TO YOU.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                We do not own or control the underlying software protocols which
                govern the operation of Digital Assets. Generally, the
                underlying protocols are open source, and anyone can use, copy,
                modify, and distribute them. We assume no responsibility for the
                operation of the underlying protocols and we are not able to
                guarantee the functionality or security of network operations.
                In particular, the underlying protocols may be subject to sudden
                changes in operating rules (including “forks”). Any such
                material operating changes may materially affect the
                availability, value, functionality, and/or the name of such
                digital currency. Lily.africa does not control the timing and
                features of these material operating changes. It is your
                responsibility to make yourself aware of upcoming operating
                changes and you must carefully consider publicly available
                information and information that may be provided by Lily.africa
                in determining whether to continue to use the Services. In the
                event of any such operational change, Lily.africa reserves the
                right to take such steps as may be necessary to protect the
                security and safe operation of its platform, including
                temporarily suspending operations for the involved digital
                currency(ies), and other necessary steps. Lily.africa will use
                its reasonable efforts to provide you notice of its response to
                any material operating change; however, such changes are outside
                of our control and may occur without notice to Lily.africa. Our
                response to any material operating change is subject to our sole
                discretion and includes deciding not to support any new fork or
                other actions. You acknowledge and accept the risks of operating
                changes to Digital Assets’ protocols and agree that Lily.africa
                is not responsible for such operating changes and not liable for
                any loss of value you may experience as a result of such changes
                in operating rules. You acknowledge and accept that Lily.africa
                has sole discretion to determine its response to any operating
                change and that we have no responsibility to assist you with
                unsupported currencies or protocols.
              </p>
            </li>
            <li class="privacy-list">
              <p class="privacy-text">
                In using our Services, you may view content or utilize Services
                provided by third parties, including hyperlinks to third-party
                websites and services of such parties (“3rd party content”). We
                do not endorse, adopt or control any 3rd party content and will
                have no responsibility or liability for such 3rd party content
                whatsoever. In addition, your dealings or correspondence with
                such third parties are solely between you and the third parties.
                We are not responsible or liable for any loss or damage of any
                sort incurred as a result of any such dealings and you
                understand that your use of 3rd party content, and your
                interactions with third parties, is solely at your own risk.
              </p>
            </li>

            <li className="privacy-text">
              {" "}
              For the avoidance of doubt, Lily.africa does not provide
              investment, tax, or legal advice. Lily.africa is not registered
              with the U.S. Securities and Exchange Commission and does not
              offer securities services or investment advice. All transactions
              through our Marketplace are conducted on a peer-to-peer basis
              between the VENDOR and DEALER and you are solely responsible for
              determining whether any investment, investment strategy or related
              transaction is appropriate for you based on your personal
              investment objectives, financial circumstances and risk tolerance.
              You should consult your legal or tax professional regarding your
              specific situation. From time to time, we may provide educational
              information about our platform and products, in order to assist
              users in learning more about our Services. Information may
              include, but is not limited to, blog posts, articles, links to 3rd
              party content, news feeds, tutorials, and videos. The information
              provided on the Website or any third-party sites does not
              constitute investment advice, financial advice, trading advice, or
              any other sort of advice, and you should not treat any of the
              Website’s content as such. Before making the decision to buy, sell
              or hold any Digital Assets, you should conduct your own due
              diligence and consult your financial advisors before making any
              investment decision. Lily.africa will not be held responsible for
              the decisions you make to buy, sell, or hold Digital Assets based
              on the information provided by Lily.africa.
            </li>
            <li className="privacy-text">
              {" "}
              You agree that we are not liable for any price fluctuations in
              Digital Assets. In the event of market disruption or a Force
              Majeure event (as described in Section 17), we may do one or more
              of the following: (a) suspend access to the Services; or (b)
              prevent you from completing any actions via the Services. We will
              not be liable for any losses suffered by you resulting from such
              actions. Following any such event, when Services resume, you
              acknowledge that prevailing market rates may differ significantly
              from the rates available prior to such event.
            </li>
            <li className="privacy-text">
              {" "}
              We make no warranty that the Website, or the server that makes it
              available, are free of viruses or errors, that its content is
              accurate, that it will be uninterrupted, or that defects will be
              corrected. We will not be responsible or liable to you for any
              loss of any kind, from action taken, or taken in reliance on
              material, or information, contained on the Website.
            </li>
          </ol>

          <h2 class="privacy-title">6. RELEASE OF LILY.AFRICA & INDEMNITY</h2>
          <ol>
            <li class="privacy-list">
              <p class="privacy-text">
                If you have a dispute with one or more users of our Services,
                you release Lily.africa, its affiliates and service providers,
                and each of its or their respective officers, directors,
                employees, agents, moderators and representatives, from any and
                all claims, demands and damages (actual and consequential) of
                every kind and nature arising out of or in any way connected
                with such disputes. You agree to indemnify and hold Lily.africa,
                its affiliates and each of its or their respective officers,
                directors, employees, agents and representatives, harmless from
                any claim or demand (including attorneys’ fees and any fines,
                fees or penalties imposed by any regulatory authority) arising
                out of or related to your breach of this Agreement or your
                violation of any law, rule or regulation, or the rights of any
                third party.
              </p>
            </li>
          </ol>

          <h2 class="privacy-title">
            7. TRANSACTIONS ON LILY.AFRICA’S MARKETPLACE
          </h2>
          <p class="privacy-text">
            The Website allows users to solicit offers to buy or sell Digital
            Assets and swap financial instruments.
          </p>
          <p class="privacy-text">
            When a user initiates a transaction for the purchase or sale of
            Digital Assets, the transaction is consummated pursuant to this
            Agreement and to the additional terms, if any, detailed by the user
            or the user’s counterparty. A step-by-step guide on buying and
            selling Digital Assets and swapping financial instruments on the
            Lily.africa Marketplace can be found at{" "}
            <a
              href="https://support.Lily.africa"
              class="privacy-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.Lily.africa
            </a>
            .
          </p>
          <h3 class="privacy-title">General Terms</h3>
          <ol>
            <li class="privacy-list">
              <h4 class="privacy-title">
                Buying Digital Assets/Swapping Financial Instruments via
                soliciting an offer
              </h4>
              <ol>
                <li class="privacy-list">
                  <p class="privacy-text">
                    There are no fees for Lily.africa Escrow as part of a
                    transaction that are payable by Dealers on our Marketplace.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Offers from Lily.africa counterparties have their own terms
                    and conditions and each offer will vary in exchange rate,
                    speed of exchange, and other terms and conditions imposed by
                    a VENDOR. By accepting a Vendor’s offer you agree to be
                    bound by the terms and conditions of that offer. The terms
                    and conditions specified by the VENDOR are valid in all
                    cases except when they contradict or violate this Agreement,
                    are illegal, are unreasonable or otherwise difficult to
                    comply with (as determined in Lily.africa’s sole and
                    absolute discretion), or if both users of the transaction
                    consent to alter the terms and conditions of such offer. IT
                    IS YOUR RESPONSIBILITY TO CAREFULLY READ THE VENDOR’S OFFER
                    TERMS AND CONDITIONS AND FOLLOW THEM EXACTLY. IF YOU DO NOT
                    FOLLOW THE OFFER TERMS AND CONDITIONS, YOUR PAYMENT WILL NOT
                    BE ACCEPTED. NEVER SUBMIT PAYMENT UNLESS YOU HAVE FOLLOWED
                    ALL TERMS AND CONDITIONS LISTED IN THE OFFER. IF YOU SUBMIT
                    PAYMENT WITHOUT FOLLOWING THE TERMS AND CONDITIONS,
                    LILY.AFRICA CANNOT ASSIST YOU IN A DISPUTE PROCESS TO
                    RECOVER YOUR PAYMENT.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Payment verification and providing instruction to unlock
                    Digital Assets from Lily.africa Escrow are the sole
                    obligations of the VENDOR and not that of Lily.africa. If
                    the VENDOR does not release the Digital Assets to you upon
                    proper completion of the Vendor’s terms and conditions,
                    promptly report the issue via the designated dispute button
                    (“raise issue” button) within the particular transaction
                    chat. Lily.africa support will review and settle the
                    dispute. This dispute resolution process is further
                    described below in “Section 8 - Disputing Transactions Via
                    Lily.africa’s Dispute Resolution Process.” If you do not
                    follow this dispute resolution process, Lily.africa will be
                    unable to assist you with this matter.
                  </p>
                </li>
              </ol>
            </li>
            <li class="privacy-list">
              <h4 class="privacy-title">Selling Digital Assets</h4>
              <ol>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Vendors must verify and process the payment in a reasonable
                    amount of time, and within a certain amount of time as
                    specified in the offer terms. Once the DEALER has submitted
                    payment to you in accordance with the offer terms, it is
                    your sole duty and responsibility to promptly verify and
                    process the payment and then unlock the Digital Assets from
                    Lily.africa Escrow and release it to the DEALER. If you do
                    not follow the instructions on the offer, you may not be
                    entitled to a return of your locked Digital Assets.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    As a VENDOR you accept all risks and liabilities for any
                    violation of this Agreement incurred through the sale of
                    Digital Assets. All taxes to be paid are your
                    responsibility. Lily.africa charges a fee to you as the
                    VENDOR of Digital Assets for locking Digital Assets in
                    Lily.africa Escrow subject to a sale. Unless determined
                    otherwise in Lily.africa’s sole and absolute discretion,
                    Lily.africa shall not reimburse any losses to the VENDOR
                    whether due to a violation of this Agreement, fraud or
                    otherwise and our fee will not be refunded under any
                    circumstances.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Any payment received should be fully processed and confirmed
                    as received by you before unlocking the Digital Assets from
                    Lily.africa Escrow. Lily.africa is not responsible for your
                    loss if you prematurely unlock Digital Assets before payment
                    has been properly verified and received by you. You must be
                    attentive and responsive to your DEALER. You should
                    deactivate any inactive offers.
                  </p>
                </li>

                <li className="privacy-list">
                  4. Any advertisement of your own website in any section of
                  Lily.africa’s Marketplace (such as your bio, offer terms or
                  transaction chat) that would facilitate buying or selling
                  Digital Assets outside of Lily.africa’s Services is strictly
                  prohibited. In limited instances, it is permissible to share
                  your website that is created solely for the VENDOR to receive
                  payment in order to complete the transaction (i.e. trusted
                  third party debit/credit card processing) in the transaction
                  instructions; provided that the use of such external websites
                  are made clear in the offer terms and such websites may not
                  contain any other advertisements or your contact information.
                </li>
              </ol>

              <h2 class="privacy-title">Compliance</h2>

              <ol>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Lily.africa and the Services are not affiliated or
                    associated with, nor endorsed or sponsored by any third
                    party, including but not limited to any gift card issuer.
                    Designated trademarks, brands, and other identifiers are
                    solely the property of their respective owners. Lily.africa
                    and its Services are not endorsed, sponsored, associated or
                    affiliated in any way by or with such owners.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Lily.africa is not a licensed gift card vendor or authorized
                    dealer of any gift card issuer. Any gift cards you receive
                    directly from a user using the Lily.africa Marketplace are
                    subject to the terms and conditions of the third-party
                    merchant with whom it is redeemable (“Issuer”). Lily.africa
                    is not responsible for the acts or omissions of any
                    Issuer(s), or any fees, expiration dates, penalties or terms
                    and conditions associated with the Issuer’s gift card
                    received using the Lily.africa Marketplace. By receiving the
                    gift card from a user, you acknowledge that you have read
                    the gift card’s terms and conditions, and represent to
                    Lily.africa that you are eligible to use such gift cards
                    under the gift card Issuer’s terms and conditions, or under
                    applicable law.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    BROKERING OR RESELLING OF GIFT CARDS IS STRICTLY PROHIBITED
                    ON OUR WEBSITE AND MARKETPLACE. YOU MUST BE THE RIGHTFUL
                    OWNER OF THE GIFT CARD AND AT LILY.AFRICA’S REQUEST YOU
                    AGREE TO PROVIDE LILY.AFRICA WITH VALID PROOF OF OWNERSHIP
                    OF YOUR GIFT CARD (SUCH AS A RECEIPT). LILY.AFRICA MAKES NO
                    CLAIM, REPRESENTATION OR GUARANTEE THAT ANY THIRD-PARTY
                    PAYMENT METHODS ON THE WEBSITE PERMIT TRANSACTIONS VIA
                    LILY.AFRICA’S SERVICE, OR THAT ANY THIRD-PARTY PAYMENT
                    METHODS ON OUR WEBSITE SUPPORT OR ARE SUPPORTED BY OUR
                    SERVICES. YOU SHOULD NOT USE SUCH THIRD-PARTY PAYMENT
                    METHODS WITH LILY.AFRICA IF SUCH THIRD-PARTY DOES NOT PERMIT
                    IT.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    Lily.africa is not a licensed financial institution or
                    authorized intermediary of a financial institution, partner,
                    or registered with any financial institution. Lily.africa
                    does not issue currencies or financial instruments. Any
                    financial instruments, including but not limited to
                    currencies, you receive directly from a user using the
                    Lily.africa Marketplace are subject to the terms and
                    conditions of the third-party financial institution with
                    whom it is redeemable or can be received (“Issuer”).
                    Lily.africa is not responsible for the acts or omissions of
                    any Issuer(s), or any fees, expiration dates, penalties, or
                    terms and conditions associated with the Issuer’s financial
                    instrument received using the Lily.africa Marketplace. By
                    receiving the financial instrument from a user, you
                    acknowledge that you have read the financial instrument’s
                    terms and conditions and represent to Lily.africa that you
                    are eligible to use such financial instruments under the
                    Issuer’s terms and conditions or under applicable law.
                  </p>
                </li>
                <li class="privacy-list">
                  <p class="privacy-text">
                    BROKERING, RESELLING, OR SWAPPING OF CURRENCIES OR FINANCIAL
                    INSTRUMENTS ON OUR WEBSITE AND MARKETPLACE IS STRICTLY
                    PROHIBITED. YOU MUST BE THE RIGHTFUL OWNER OF THE ACCOUNT
                    FROM WHICH THE FINANCIAL INSTRUMENT IS TO BE SWAPPED OR
                    RECEIVED, AND YOU MUST BE THE RIGHTFUL OWNER/HOLDER OF THE
                    FINANCIAL INSTRUMENT. AT LILY.AFRICA’S REQUEST, YOU AGREE TO
                    PROVIDE LILY.AFRICA WITH VALID PROOF OF OWNERSHIP OF YOUR
                    ACCOUNT AND THE FINANCIAL INSTRUMENT (SUCH AS ACCOUNT
                    STATEMENTS). LILY.AFRICA MAKES NO CLAIM, REPRESENTATION, OR
                    GUARANTEE THAT ANY THIRD-PARTY PAYMENT METHODS ON THE
                    WEBSITE PERMIT TRANSACTIONS VIA LILY.AFRICA’S SERVICE, OR
                    THAT ANY THIRD-PARTY PAYMENT METHODS ON OUR WEBSITE SUPPORT
                    OR ARE SUPPORTED BY OUR SERVICES. YOU SHOULD NOT USE SUCH
                    THIRD-PARTY PAYMENT METHODS WITH LILY.AFRICA IF SUCH
                    THIRD-PARTY DOES NOT PERMIT IT.
                  </p>
                </li>

                <li className="privacy-list">
                  YOU ARE WHOLLY RESPONSIBLE TO COMPLY WITH ALL LAWS AND
                  REGULATIONS FOR THE JURISDICTION(S) IN WHICH YOUR TRANSACTION
                  TAKES PLACE.
                </li>

                <li className="privacy-list">
                  All transactions must take place within Lily.africa. Taking
                  transactions outside the Lily.africa platform or exchanging
                  external contact details are strictly prohibited.
                </li>
              </ol>
            </li>

            <li className="privacy-list">
              {" "}
              Transfer Limitations. We may, in our sole discretion, impose
              limitations or restrictions on the size, type, or manner of any
              proposed transfer transactions, such as a limit on the total
              amount of Digital Assets that may be posted for sale.
            </li>
            <li className="privacy-list">
              No Guarantee. Lily.africa does not guarantee that you will be able
              to sell Digital Assets on its Marketplace. The act of buying or
              selling Digital Assets via Lily.africa’s Marketplace does not
              guarantee that you will be able to buy or sell Digital Assets via
              the Marketplace at a later time.
            </li>
            <li className="privacy-list">
              Relationship. Nothing in this Agreement is intended to nor shall
              create any partnership, joint venture, agency, consultancy or
              trusteeship, you and Lily.africa being with respect to one another
              independent contractors.
            </li>
            <li className="privacy-list">
              Accuracy of Information. You represent and warrant that any
              information you provide via the Services is accurate and complete.
              You accept and acknowledge that Lily.africa is not responsible for
              any errors or omissions that you make in connection with any
              transaction initiated via the Services, for instance, if you
              mistype a Wallet address or otherwise provide incorrect
              information. We strongly encourage you to review your transaction
              details carefully before completing them via the Services
            </li>
            <li className="privacy-list">
              No Cancellations or Modifications; Wallet Activities. Once
              transaction details have been submitted to the digital currency
              network via the Services, Lily.africa cannot assist you with
              canceling or otherwise modifying your transaction. Lily.africa has
              no control over any digital currency network and does not have the
              ability to facilitate any cancellation or modification requests.
              Lily.africa does not store or custody any locked Digital Assets.
              Digital Assets are always recorded on their respective networks or
              blockchains. All digital currency transactions occur within the
              digital currency network, not on Lily.africa. There are no
              guarantees that the transaction will process on the digital
              currency network. Lily.africa reserves the right to refuse to
              process any transaction if required by law or if we deem the
              transactions to be against our terms and conditions in this
              Agreement. You hereby accept and acknowledge that you take full
              responsibility for all activities that occur under your Wallet and
              accept all risks of any authorized or unauthorized access to your
              Wallet, to the maximum extent permitted by law.
            </li>
            <li className="privacy-list">
              Taxes. It is your responsibility to determine what, if any, taxes
              apply to the transactions for which you have submitted transaction
              details via the Services, and it is your responsibility to report
              and remit the correct tax to the appropriate tax authority. You
              agree that Lily.africa is not responsible for determining whether
              taxes apply to your digital currency transactions or for
              collecting, reporting, withholding or remitting any taxes arising
              from any digital currency transactions.
            </li>
            <li className="privacy-list">
              User Reputation. When you engage in a Transaction, we allow other
              users to provide feedback on their interaction with you. We also
              permit users to file reports if the users believe you have
              violated this Agreement in any way. These reports are
              confidential, but we may use them in connection with a dispute as
              described in Section 8.
            </li>
            <li className="privacy-list">
              Transaction History. You may view your transaction history through
              your Account. You agree that the failure of the Services to
              provide such confirmation shall not prejudice or invalidate the
              terms of such transaction.
            </li>
            <li className="privacy-list">
              Merchant Goods. Lily.africa is not responsible for any goods or
              services that you may purchase from an Authorized Merchant using
              your account or the Lily.africa Pay product. If you have a dispute
              with any Authorized Merchant, you should resolve the dispute
              directly with that Authorized Merchant.
            </li>
            <li className="privacy-list">
              {" "}
              Returns, Refunds. When you purchase a good or service from a third
              party using your Account, it is final. We do not process refunds
              or returns. An Authorized Merchant may offer you a return, store
              credit or a gift card in its sole discretion and in accordance
              with its policies.
            </li>
            <li className="privacy-list">
              Lily.africa charges fees for Services, applicable fees will be
              displayed prior to you using any Service to which a fee applies.
              See “Lily.africa Fees” for further details. Our fees are subject
              to change and Lily.africa reserves the right to adjust its pricing
              and fees and at any time.
            </li>
          </ol>

          <h2 class="privacy-title">
            8. DISPUTING TRANSACTIONS VIA LILY.AFRICA’S DISPUTE RESOLUTION
            PROCESS
          </h2>

          <ol>
            <li className="privacy-list">
              <u> Disputing Transactions.</u> In most cases, the easiest way to
              settle a dispute is for Dealers and Vendors to communicate, work
              together to figure out what happened, and come to an agreeable
              solution. When a DEALER and a VENDOR cannot come to an agreeable
              solution, Lily.africa’s support team (“Lily.africa Support”) can
              help. Either party can initiate the dispute resolution process
              (“disputed transaction” or “dispute”) with respect to a
              transaction. Disputes can only be initiated on transactions that
              are marked as fully paid by the DEALER or the VENDOR. Transactions
              that are not marked as fully paid by the DEALER or the VENDOR,
              cancelled by the DEALER, automatically cancelled due to expiration
              of the timeframe set forth in the offer, already disputed and
              resolved or where the VENDOR has released the Digital Assets to
              the DEALER or the VENDOR generally cannot be disputed, reversed,
              or altered.
            </li>

            <li className="privacy-list">
              {" "}
              <p className="privacy-text">
                {" "}
                <u>Dispute Resolution Process.</u> Below are the steps
                Lily.africa Support takes in the event of a dispute.
              </p>
              <ol>
                <li className="privacy-list">
                  <i className="privacy-text">Initiation</i>

                  <ul>
                    <li className="privacy-list">
                      You can initiate a dispute by logging into your
                      Lily.africa Account, opening the transaction you would
                      like to dispute and selecting the “raise issue” button.
                      The “raise issue” button will only appear active if the
                      transaction has been marked as fully paid by either the
                      DEALER or the VENDOR depending on the stage of the
                      transaction. Once you initiate a dispute, you will select
                      the type of dispute from the options presented and
                      describe the issue giving rise to your dispute.
                    </li>

                    <li className="privacy-list">
                      <p className="privacy-text">
                        The options presented for describing your dispute if you
                        are a VENDOR are as follows:
                      </p>

                      <ul>
                        <li className="privacy-list">
                          {" "}
                          Unresponsive dealer - has marked the transaction as
                          fully paid, but is unresponsive and inactive.
                        </li>
                        <li className="privacy-list">
                          Payment issue – the DEALER is active and has attempted
                          to pay, but there are issues with the payment.
                        </li>
                        <li className="privacy-list">
                          Other – an open option where you can describe what
                          issue gave rise to the dispute. The DEALER will be
                          able to view your description.
                        </li>
                      </ul>
                    </li>

                    <li className="privacy-list">
                      <p className="privacy-text">
                        The options presented for describing your dispute if you
                        are a DEALER are as follows:
                      </p>

                      <ul>
                        <li className="privacy-list">
                          Unresponsive vendor – you have paid, but the VENDOR is
                          unresponsive and inactive.
                        </li>
                        <li className="privacy-list">
                          Payment issue – you have made the payment, but the
                          VENDOR claims there are issues with the payment and
                          refuses to release the Digital Assets.
                        </li>
                        <li className="privacy-list">
                          Other – an open option where you can describe what
                          issue gave rise to the dispute. The VENDOR will be
                          able to view your description.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li className="privacy-list">
                  <i className="privacy-text">Notification</i>

                  <ul>
                    <li className="privacy-text">
                      Once a dispute has been submitted, Lily.africa Support
                      will provide the other party with notification by email
                      and by sending a message through the transaction chat
                      feature available to Dealers and Vendors in the
                      Marketplace alerting such party that a dispute has been
                      initiated. If one of your transactions is being disputed,
                      Lily.africa Support will tell you which transaction is
                      being disputed and why the transaction is being disputed.
                    </li>
                  </ul>
                </li>

                <li className="privacy-list">
                  <i className="privacy-text">Response</i>

                  <ul>
                    <li className="privacy-list">
                      Review the dispute and provide Lily.africa Support with an
                      explanation of what happened. Include any evidence you
                      have to support your explanation, such as proof of
                      payment, proof of ownership or proof that you have or have
                      not received payment.
                    </li>
                  </ul>
                </li>

                <li className="privacy-list">
                  <i className="privacy-text">Lily.africa Review</i>

                  <ul>
                    <li className="privacy-list">
                      Disputed transactions will be investigated by Lily.africa
                      Support and a decision will be made based upon evidence
                      provided by both parties. Lily.africa Support resolves
                      disputes by evaluating various factors as described below
                      in Section 8.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>

            <li className="privacy-list">
              {" "}
              <u>Dispute Review.</u> During a dispute review, Lily.africa
              Support may give you instructions that you are required to follow.
              The instructions given to you may require you to provide
              additional evidence, such as additional ID verification, proof of
              payment, any photo, audio, or video evidence, or any other
              documents deemed relevant by Lily.africa and may require you to
              provide such evidence within a specified timeframe. Failure to
              follow the instructions may lead to the dispute being resolved
              against you. Lily.africa Support will typically provide notice of
              its decision via the transaction chat feature in the Marketplace
              within 30 days of receiving the dispute, but under some
              circumstances, it may take longer.
            </li>

            <li className="privacy-list">
              <u>Unresponsiveness.</u> When you are involved in a transaction,
              it is important that you remain active and available from the time
              the transaction is started to the time that the transaction is
              completed, canceled, or resolved. This means that you must be able
              to provide a response to a request by Lily.africa Support in a
              disputed transaction within the time specified by Lily.africa
              Support or you may be deemed as unresponsive and the dispute may
              be resolved against you.
            </li>

            <li className="privacy-list">
              {" "}
              <u>Chargebacks.</u> A party may face additional risks depending on
              the payment method used for the transaction even if Lily.africa’s
              dispute resolution process finds in favor of such party. The
              dispute resolution process set forth in this Agreement is separate
              from any remedies a DEALER or VENDOR may have through the payment
              method used in connection with a transaction. Lily.africa is not
              obligated to initiate or handle chargebacks and is not liable if a
              party reverses, charges back, or otherwise disputes a transaction
              via an avenue made available to the party through the payment
              method used in the transaction, including after a dispute is
              closed.
            </li>

            <li className="privacy-list">
              <p className="privacy-text">
                <u>Dispute Resolution.</u> A disputed transaction is most
                commonly resolved by Lily.africa Support moving the Digital
                Assets subject to the dispute to the DEALER or the VENDOR of the
                disputed transaction once the dispute resolution process is
                complete.
              </p>

              <p className="privacy-text">
                Below are selected situations to provide you with insight as to
                how Lily.africa may resolve a disputed transaction. This is not
                meant to be an exhaustive list. The resolution of any dispute
                will be impacted by the specific facts of the dispute and
                evidence provided by the users.
              </p>

              <p className="privacy-text">
                Lily.africa Support may resolve a dispute in the Dealer’s favor
                when at least one of the following criteria are met:
              </p>

              <ul>
                <li className="privacy-list">
                  The DEALER has made payment according to the initial
                  instructions provided by the VENDOR pursuant to the
                  transaction offer and the DEALER has provided sufficient proof
                  that the payment was made according to these instructions. It
                  is a violation of this Agreement for a VENDOR to refuse to
                  complete a transaction once the DEALER has satisfied all of
                  the Vendor’s terms and conditions as posted at the time the
                  DEALER accepted and paid for the transaction.
                </li>

                <li className="privacy-list">
                  The VENDOR has become unresponsive and has not provided a
                  sufficient response within the timeframe requested by
                  Lily.africa Support.
                </li>

                <li className="privacy-list">
                  The payment is made to a third party to the transaction or the
                  payment is made to a payment account not registered in the
                  name of the VENDOR.
                </li>
              </ul>

              <p className="privacy-text">
                Lily.africa Support may resolve a dispute in the Vendor’s favor
                when one of the following criteria are met:
              </p>

              <ul>
                <li className="privacy-text">
                  The DEALER has not provided payment, not provided payment in
                  full or not provided payment according to the initial
                  instructions provided by the VENDOR pursuant to the
                  transaction offer.
                </li>
                <li className="privacy-text">
                  The payment made by the DEALER has been held, suspended,
                  frozen or stopped by the payment provider or processor. This
                  includes situations in which the DEALER has charged back or
                  disputed a payment via its bank or payment card issuer.
                </li>
                <li className="privacy-text">
                  The DEALER has become unresponsive and has not provided a
                  sufficient response within the timeframe requested Lily.africa
                  Support.
                </li>
                <li className="privacy-text">
                  The payment is made by a third party to the transaction or the
                  payment is made from a payment account not registered in the
                  name of the DEALER.
                </li>

                <p className="privacy-text">
                  If the DEALER or the VENDOR of a disputed transaction provides
                  fraudulent information or fraudulent documents or makes false
                  claims or otherwise uses deceptive tactics, the dispute may be
                  immediately resolved against such user and such user’s account
                  may be immediately suspended or terminated at the sole
                  discretion of Lily.africa Support.
                </p>

                <p className="privacy-text">
                  In some situations where neither party fulfills the criteria,
                  or it is in other ways unclear or not possible to determine
                  which party has fulfilled the dispute resolution criteria in
                  Lily.africa’s sole and absolute discretion, Lily.africa may
                  decide to resolve the dispute by splitting the Digital Assets
                  subject to the dispute between the DEALER and the VENDOR
                  evenly or unevenly.
                </p>
              </ul>
            </li>

            <li className="privacy-list">
              <u>Appeal.</u> If you believe Lily.africa has resolved a dispute
              in a way which is not in accordance with this Agreement, you have
              a right to request an appeal. To request an appeal, you need to
              promptly notify us in writing by contacting Lily.africa customer
              support no later than 10 calendar days after notice of Lily.africa
              Support’s decision is delivered to you and provide us with
              sufficient details and evidence supporting your case for request.
              Your appeal should specifically identify how you believe
              Lily.africa incorrectly resolved the dispute as per the terms of
              this Agreement and provide evidence of such incorrect decision.
              <p className="privacy-text">
                Please be reminded that whether during the dispute process or
                generally any time when using our Services, you are obligated to
                keep a civil tone and be respectful to other users and
                Lily.africa Support. See generally, “Section 13 - Prohibited
                Use”.
              </p>
            </li>

            <li className="privacy-list">
              <u>Finality.</u> You acknowledge and agree that Lily.africa’s
              decision regarding a dispute is conclusive, final and binding as
              described in this Agreement. Lily.africa will have no liability to
              either a DEALER or a VENDOR in connection with its decisions.
            </li>
          </ol>

          <h2 class="privacy-title">9. FEES FOR USING LILY.AFRICA SERVICES</h2>

          <ol>
            <li className="privacy-list">
              Creating a Wallet is free. Lily.africa charges fees for Services,
              applicable fees will be displayed prior to you using any Service
              to which a fee applies. See “Lily.africa Fees” for further
              details. Our fees are subject to change and Lily.africa reserves
              the right to adjust its pricing and fees and at any time.
            </li>
          </ol>

          <h2 class="privacy-title">
            10. NO RIGHT TO CANCEL SERVICES OR MINERS FEES
          </h2>

          <ol>
            <li className="privacy-list">
              If you use a Service to which a charge applies, or you initiate a
              transaction with a miners fee via the Services, you will not be
              eligible for a refund or reimbursement once you have confirmed
              that you wish to proceed with the Service or transaction
            </li>
          </ol>

          <h2 class="privacy-title">11. DISCONTINUANCE OF SERVICES</h2>

          <ol>
            <li className="privacy-list">
              We may, in our sole discretion and without cost to you, with or
              without prior notice and at any time, modify or discontinue,
              temporarily or permanently, any portion of our Services.
            </li>
          </ol>

          <h2 class="privacy-title">
            12. SUSPENSION OR TERMINATION OF SERVICES & ACCOUNT; LIMITING ACCESS
            TO YOUR WALLET
          </h2>

          <ol>
            <li className="privacy-list">
              We may in our sole and absolute discretion, immediately and
              without prior notice: (a) suspend, restrict, or terminate your
              access to any or all of the Services (including limiting access to
              your Wallet), and/or (b) deactivate or cancel your account if: (i)
              we are so required by applicable law, a facially valid subpoena,
              court order, or binding order of a government authority; (ii) we
              reasonably suspect you have or may act in violation of this
              Agreement; (iii) use of your account is subject to any pending
              litigation, investigation, or government proceeding and/or we
              perceive a heightened risk of legal or regulatory non-compliance
              associated with your account activity; (iv) our service partners
              are unable to support your use; (v) you take any action that we
              deem as circumventing our controls and procedures or (vi) we think
              it is necessary to do so to protect us, our users, including you,
              or our employees from danger or loss. If we exercise our rights to
              limit or refuse your access to the Services, we will not be
              responsible for any consequences of our refusal to give you access
              to the Services, including any delay, damage or inconvenience you
              may suffer as a result.
            </li>
            <li className="privacy-list">
              If we suspend or close your account, terminate your use of the
              Services for any reason, or limit access to your Wallet, we will
              attempt to provide you with notice of our actions unless a court
              order or other legal process prohibits us from providing you with
              such notice. YOU ACKNOWLEDGE THAT OUR DECISION TO TAKE CERTAIN
              ACTIONS, INCLUDING LIMITING ACCESS TO, SUSPENDING, OR CLOSING YOUR
              ACCOUNT OR WALLET, MAY BE BASED ON CONFIDENTIAL CRITERIA THAT ARE
              ESSENTIAL TO OUR RISK MANAGEMENT AND SECURITY PROTOCOLS. YOU AGREE
              THAT LILY.AFRICA IS UNDER NO OBLIGATION TO DISCLOSE THE DETAILS OF
              ITS RISK MANAGEMENT AND SECURITY PROCEDURES TO YOU. In the event
              that we suspend your account or access to your Wallet, we will
              remove the suspension as soon as possible once the reasons for the
              suspension no longer exist, however we are under no obligation to
              notify you as to when (if ever) such suspension will be removed.
            </li>
            <li className="privacy-list">
              {" "}
              If you are holding Digital Assets in your Lily.africa Wallet and
              there has been no activity in your account for a period of time
              prescribed by applicable law, we may be required to report such
              remaining Digital Assets in your account as unclaimed property in
              accordance with abandoned property and escheat laws. If this
              occurs, we will use reasonable efforts to provide written notice
              to you. If you fail to respond to any such notice within seven (7)
              business days of receipt, or as otherwise required by law, we may
              be required to deliver any such Digital Assets to the applicable
              jurisdiction as unclaimed property. We reserve the right to deduct
              a dormancy fee or other administrative charges from such unclaimed
              Digital Assets as permitted by applicable law.
            </li>
            <li className="privacy-list">
              We reserve the right to reverse internally received Digital Assets
              to your Lily.africa Wallet back to the originating account if the
              owner of the originating account successfully demonstrates that
              the Digital Assets were transferred to your Lily.africa Wallet in
              error, and if there has been no activity in your account for a
              period of 24 months. If this occurs, we will use reasonable
              efforts to provide written notice to you. If you fail to respond
              to any such notice within 14 days, we may be required to reverse
              Digital Assets to the owner of the originating account.
            </li>
          </ol>

          <h2 class="privacy-title">13. PROHIBITED USE</h2>

          <ol>
            <li className="privacy-list">
              When accessing or using the Services, you agree that you will use
              the Services in accordance with the terms and conditions in this
              Agreement (including the Privacy Policy) and not commit any
              unlawful act, and that you are solely responsible for your conduct
              while using our Services. Without limiting the generality of the
              foregoing, you agree that you will not:
            </li>

            <ul>
              <li className="privacy-list">
                use our Services in any manner that could interfere with,
                disrupt, negatively affect or inhibit other users from fully
                enjoying our Services, or that could damage, disable, overburden
                or impair the functioning of our Services in any manner;
              </li>
              <li className="privacy-list">
                engage in any activity which could violate, or assist in
                violation of, any law, statute, ordinance, or regulation,
                sanctions programs administered in the countries where we
                conduct business or operations, or which would involve proceeds
                of any unlawful activity; publish, distribute or disseminate any
                unlawful material or information;
              </li>
              <li className="privacy-list">
                interfere with another user’s access to or use of any of our
                Services; defame, abuse, extort, harass, stalk, threaten or
                otherwise violate or infringe the legal rights (such as, but not
                limited to, rights of privacy, publicity and intellectual
                property) of other users; incite, threaten, facilitate, promote,
                or encourage hate, racial intolerance, or violent acts against
                others; harvest or otherwise collect information from our
                Website about other users;
              </li>
              <li className="privacy-list">
                engage in any activity which operates to defraud, defame or
                otherwise cause any damage to Lily.africa or our users; or
                provide any false, inaccurate, deceptive or misleading
                information to Lily.africa or to another user in connection with
                our Services or as otherwise provided or requested pursuant to
                this Agreement;
              </li>
              <li className="privacy-list">
                introduce to the Services any virus, Trojan, worms, logic bombs
                or other harmful material; use any robot, spider, crawler,
                scraper or other automated means or interface not provided by us
                to access our Services or to extract data; attempt to circumvent
                any content filtering techniques we employ, or attempt to access
                any service or area of our Services that you are not authorized
                to access; or placement anywhere within Lily.africa’s
                Marketplace of any advertisement or promotion that would
                facilitate buying or selling Digital Assets outside of
                Lily.africa’s Services;
              </li>
              <li className="privacy-list">
                engage in transactions involving items that infringe or violate
                any copyright, trademark, right of publicity or privacy or any
                other proprietary right under the law, or other licensed
                materials without the appropriate authorization from the rights
                holder; use of Lily.africa intellectual property, name, or logo,
                including use of Lily.africa’s trade or service marks, without
                prior written consent from us or in a manner that otherwise
                harms Lily.africa or the Lily.africa brand; any action that
                implies an untrue endorsement by or affiliation with
                Lily.africa; or develop any third-party applications that
                interact with our Services without our prior written consent; or
              </li>
              <li className="privacy-list">
                encourage or induce any third party to engage in any of the
                activities prohibited under this Section 13.
              </li>
            </ul>
          </ol>

          <h2 class="privacy-title">13. INTELLECTUAL PROPERTY RIGHTS</h2>

          <ol>
            <li className="privacy-list">
              We grant you a limited, nonexclusive, nontransferable license,
              subject to the terms and conditions in this Agreement, to access
              and use the Services, Website, and related content, materials,
              information (collectively, the “Content”) solely for purposes
              approved by Lily.africa from time to time. Any other use of the
              Website or Content is expressly prohibited and all other right,
              title, and interest in the Services, Website or Content is
              exclusively the property of Lily.africa. You agree you will not
              copy, transmit, distribute, sell, license, reverse engineer,
              modify, publish, or participate in the transfer or sale of, create
              derivative works from, or in any other way exploit any of the
              Content, in whole or in part without the prior written consent of
              Lily.africa. You may not copy, imitate or use any of Lily.africa’s
              trademarks, registered marks, logos or any of its intellectual
              property without Lily.africa’s prior written consent.
            </li>
            <li className="privacy-list">
              {" "}
              Although we intend to provide accurate and timely information on
              the Lily.africa Website, our Website (including, without
              limitation, the Content) may not always be entirely accurate,
              complete or current and may also include technical inaccuracies or
              typographical errors. In an effort to continue to provide you with
              as complete and accurate information as possible, information may
              be changed or updated from time to time without notice, including
              without limitation information regarding our policies, products
              and Services. Accordingly, you should verify all information
              before relying on it, and all decisions based on information
              contained on the Lily.africa Website are your sole responsibility
              and we shall have no liability for such decisions. Information
              provided by third parties is for informational purposes only and
              Lily.africa makes no representations or warranties to its
              accuracy. Links to third-party materials (including without
              limitation, websites) may be provided as a convenience but are not
              controlled by us. You acknowledge and agree that we are not
              responsible for any aspect of the information, content, or
              Services contained in any third-party materials or on any
              third-party sites accessible or linked to the Lily.africa Website.
            </li>
          </ol>

          <h2 class="privacy-title">14. FEEDBACK AND USER SUBMISSIONS</h2>

          <ol>
            <li className="privacy-list">
              Lily.africa is always seeking to improve its Services and the
              Website. If you have ideas or suggestions regarding improvements
              or additions to Lily.africa’s Services or the Website, Lily.africa
              would like to hear them; however, any submission will be subject
              to the terms and conditions in this Agreement.
            </li>
            <li className="privacy-list">
              {" "}
              Under no circumstances will any disclosure of any idea or
              feedback, or any related material to Lily.africa or any of its
              subsidiaries, parents or affiliated companies, or any of their
              officers, directors, managers, members, shareholders, employees
              and agents, or any of their heirs, successors, representatives and
              assigns (each a “Lily.africa Party” and collectively, the
              “Lily.africa Parties”) be subject to any obligation of
              confidentiality or expectation of compensation.
            </li>
            <li className="privacy-list">
              By submitting an idea or feedback or any related material that
              would be subject to intellectual property rights (the “Work”) to
              Lily.africa or any Lily.africa Party, you grant to Lily.africa, in
              respect of the Work submitted, a non-exclusive, perpetual,
              worldwide royalty free license to use all of the content of such
              ideas and feedback, for any purpose whatsoever. Further, you are
              waiving any moral rights to the fullest extent permitted under
              United States law that you may have in the Work and are
              representing and warranting to such Lily.africa Party that the
              Work are wholly original with you, that no one else has any rights
              in the Work and that all Lily.africa Parties are free of any
              royalty to implement the Work and to use the related material if
              so desired, as provided or as modified by any Lily.africa Party,
              without obtaining permission or license from any third party.
            </li>
            <li className="privacy-list">
              You further accept that Lily.africa may sub-license any of the
              Lily.africa Parties to use in any way any Work and material you
              have submitted.
            </li>
            <li className="privacy-list">
              We have the right to remove any posting you may make to the
              Website, in our absolute discretion, without warning or reasons.
            </li>
          </ol>

          <h2 class="privacy-title">15. HOW TO CONTACT US</h2>

          <p className="privacy-text">
            We recommend you visit our FAQ page before contacting us. In the
            event the FAQ page does not contain the information you are looking
            for, Lily.africa offers 24/7 support. You may contact us through our
            support widget located on the FAQ page.
          </p>

          <h2 class="privacy-title">17. FORCE MAJEURE</h2>

          <ol>
            <li className="privacy-list">
              We shall not be liable for delays, failure in performance or
              interruption of Service which result directly or indirectly from
              any cause or condition beyond our reasonable control, including
              but not limited to, significant market volatility, any delay or
              failure due to any act of God, act of civil or military
              authorities, act of terrorists, civil disturbance, war, strike or
              other labor dispute, fire, interruption in telecommunications or
              Internet services or network provider services, failure of
              equipment and/or software, other catastrophe or any other
              occurrence which is beyond our reasonable control and shall no
            </li>
          </ol>

          <h2 class="privacy-title">18. NATURE OF AGREEMENT</h2>

          <ol>
            <li className="privacy-list">
              This Agreement constitutes the entire agreement between you and
              Lily.africa with respect to the subject matter of the terms and
              conditions in this Agreement and this Agreement cancels and
              supersedes any prior understandings and agreements between you and
              Lily.africa as to that subject matter. You may not assign any of
              your rights or obligations under this Agreement without our prior
              written consent.
            </li>
          </ol>

          <p className="privacy-text">
            LILY.AFRICA” is a registered trademark of , Inc. Copyright © 2024
            Lily Finance Limited. All Rights Reserved. Lily Finance Limited. has
            no relation to MoneyGram, Western Union, Payoneer, WorldRemit,
            Paxum, PayPal, Amazon, OkPay, Payza, Walmart, Reloadit, Perfect
            Money, WebMoney, Google Wallet, BlueBird, Serve, Square Cash,
            NetSpend, Chase QuickPay, Skrill, Vanilla, MyVanilla, OneVanilla,
            Neteller, Venmo, Apple, ChimpChange or any other payment method. We
            make no claims about being supported by or supporting these
            services. Their respective wordmarks and trademarks belong to them
            alone. Official mailing address:
          </p>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Terms;
