import "./TxModal.scss";
import { Modal, Tag } from "antd";
import moment from "moment";
import { currencyFormat } from "../../utils/formatSourceText";

const TxModal = ({ close, open, data, coin }) => {
  return (
    <Modal onCancel={close} visible={open} className="txmodal">
      <div className="txmodal-body">
        <div className="tx-header">
          <h4>details</h4>

          {data.mode === "commission" || data.mode === "dispute" ? (
            <Tag
              className="clicker"
              style={{
                fontSize: "11px",
                marginBottom: 30,
                marginRight: 0,
                cursor: "pointer",
              }}
              color={"green"}
            >
              {data.mode === "commission"
                ? "commission"
                : data.mode === "dispute"
                ? "escrow"
                : ""}
            </Tag>
          ) : (
            ""
          )}

          {data.type === "send" && data.mode === "return" ? (
            <Tag
              className="clicker"
              style={{
                fontSize: "11px",
                marginBottom: 30,
                marginRight: 0,
                cursor: "pointer",
              }}
              color={"green"}
            >
              return
            </Tag>
          ) : data.type === "receive" && data.mode === "return" ? (
            <Tag
              className="clicker"
              style={{
                fontSize: "11px",
                marginBottom: 30,
                marginRight: 0,
                cursor: "pointer",
              }}
              color={"green"}
            >
              return
            </Tag>
          ) : (
            ""
          )}
        </div>

        <h3>
          {/* {data.type === "receive" ? "received" : "sent"} */}
          {/* {data.mode === "dispute" && data.type === "receive"
            ? "escrow award"
            : data.mode === "dispute" && data.type === "send"
            ? "escrow loss"
            : data.mode === "commission"
            ? "commission"
            : data.type === "receive"
            ? "received"
            : data.type === "send"
            ? "sent"
            : ""}
          {data.mode === "internal" ? "internal **" : ""} */}

          {data.type === "send" && data.mode === "return"
            ? "sent"
            : data.type === "receive" && data.mode === "return"
            ? "received"
            : data.type === "send" && data.mode === "internal"
            ? "sent (internal)"
            : data.type === "receive" && data.mode === "internal"
            ? "received (internal)"
            : data.type === "send" && data.mode === "external"
            ? "sent"
            : data.type === "receive" && data.mode === "external"
            ? "received"
            : data.type === "send" && data.mode === "dispute"
            ? "sent (escrow)"
            : data.type === "receive" && data.mode === "dispute"
            ? "received (escrow)"
            : data.type === "send" && data.mode === "commission"
            ? "sent (commission)"
            : data.type === "send" && data.mode === "return"
            ? "sent"
            : data.type === "receive" && data.mode === "return"
            ? "received"
            : data.type === "receive" && data.mode === "escrow"
            ? "received"
            : data.type === "send" && data.mode === "escrow"
            ? "sent"
            : "pending"}
        </h3>
        <p>{moment(data.tx_created_at).format("LLL")}</p>

        <h2>
          {Number(data.final_amount).toFixed(6)} {coin}
        </h2>

        <p style={{ fontWeight: "600" }}>
          ~ {currencyFormat(data.final_amount_usd)} USD
        </p>

        {data.type === "send" && (
          <p style={{ textAlign: "center" }}>
            fee:{` ${Number(data.fee_usd).toFixed(6)} USD`}
          </p>
        )}

        {data.network_hash && (
          <>
            <p className="txmodal-hash">hash :</p>
            <p>{data.network_hash}</p>
          </>
        )}

        {/* {data.transaction_url && ( */}
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          {data.mode === "external" && (
            <>
              {data?.txid && (
                <a
                  className="txmodal-link"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://blockexplorer.one/${
                    coin.toLowerCase() === "btc" ? "bitcoin" : "litecoin"
                  }/${data?.network?.toLowerCase() || "mainnet"}/tx/${
                    data?.txid
                  }/`}
                >
                  view on blockchain
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.004 9.414L7.39703 18.021L5.98303 16.607L14.589 8H7.00403V6H18.004V17H16.004V9.414Z"
                      fill="#ed1450"
                    />
                  </svg>
                </a>
              )}
            </>
          )}

          <Tag
            className="clicker"
            style={{
              fontSize: "13px",
              cursor: "pointer",
              // opacity: record.confirmations === 0 ? '0.5' : '1',
            }}
            color={
              data.confirmations === 0
                ? "default"
                : data.confirmations < 3 && data.confirmations > 0
                ? "yellow"
                : "green"
            }
          >
            {data.confirmations === 0
              ? "detected"
              : data.confirmations < 3
              ? "pending"
              : data.confirmations >= 3
              ? "successful"
              : "pending"}
          </Tag>
        </div>
        {/* )} */}
      </div>
    </Modal>
  );
};

export default TxModal;
